/* section 1 ------------------------------------------------------------------------- */
.main-div-newsroom {
    /* border: 1px solid black */
}

.news-section-1 {
    display: flex;
}

.newsroom-1 {
    position: relative;
}

.newsroom-1::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40%;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
    z-index: 1;
}

.img-newsroom {
    height: 380px;
    width: 100%;
    object-fit: cover;
    display: block;
}

.text-block {
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 2
}

.news-text1 {
    color: white;
    font-weight: 500;
    font-size: 16px;
    width: 60%;
    line-height: normal;
    cursor: pointer;
    transition: .5s ease;
}

.newsroom-1:hover .overlay {
    height: 20%;
}

.newsroom-1:hover .news-text1 {
    color: #a24bc7;
    transition: .5s ease;
    text-decoration: underline;
}

.news-card {
    position: relative;
}

.news-card::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40%;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
    z-index: 1;
}

.news-card-img {
    height: 181px;
    object-fit: cover;
    width: 100%;

}

.card-text-block {
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 2
}

.card-news-text1 {
    color: white;
    font-weight: 500;
    font-size: 15px;
    width: 100%;
    line-height: normal;
    cursor: pointer;
    transition: .5s ease;
}

.news-card:hover .card-news-text1 {
    color: #a24bc7;
    transition: .5s ease;
    text-decoration: underline;
}


/* section 2 ------------------------------------------------------------------------- */
.trending-div {
    height: auto;
}

.trd-img {
    width: 100%;
    height: 185px;
    object-fit: cover;
}

.trd-text-1 {
    display: flex;
    justify-content: space-between;
}

.trd-text-2 {
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    cursor: pointer;
}

.trending-div:hover .trd-text-2 {
    color: #a24bc7;
    transition: .5s ease;
    /* text-decoration: underline; */
}

/* section 3 ----------------------------------------------------------- */
.weekly-review {
    height: auto;
    width: 350px;
}

.weekly-reviews-img {
    width: 100%;
    height: 210px;
    object-fit: cover;
}

.review-text-main {
    background-color: rgb(238, 242, 242);
}

.review-text-1 {
    display: flex;
    justify-content: space-between
}

.review-text-2 {
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    cursor: pointer;

}

.weekly-review:hover .review-text-2 {
    color: #d1c6d6;
    transition: .5s ease;
    /* text-decoration: underline; */
}