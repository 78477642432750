.howitworks-maindiv {
    height: 80vh;
}


.my_a {
    display: inline-block;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    text-decoration: none;
    color: #0c1016
}

.hiw-sidebar {
    border: 1px solid gray;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.hiw-content {
    height: 800px;
    overflow-y: scroll;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    border-right: 1px solid gray;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.hiw-text-main {
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    /* text-align: center; */
    color: black;
}

.htw-ul {
    /* list-style-type: none; */

}

.htw-li {
    margin: 8px 0;
    padding: 5px;
    /* font-size: 16px; */
}

.htw-span {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: black
}


.problem-solution {
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.problem-solution-li {
    padding: 8px 0px;
    /* border-bottom: 1px solid #eee; */

}


.roadMap-div {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.roadMap-img {
    height: 450px
}

.imp-ul {
    list-style-type: none;
}

.imp-li {
    padding: 12px;
    border: 1px solid #ccc;
    margin: 6px;
}

.imp-ul> :first-child {
    padding: 12px;
    border: 1px solid #ccc;
    margin: 6px;
    font-weight: 500;
    color: white;
    background-color: #f43254;
}

.prb-sol {
    background-color: #f43254;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.prb-sol>h5 {
    color: white;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}