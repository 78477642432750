@media (min-width: 200px) and (max-width: 992px) {
  .login-area .login-form {
    width: 100%;
    height: auto;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.11);
    /* opacity: .77; */
    background: rgba(255, 255, 255);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 10px;
    margin: 0 auto;
    padding: 20px 25px 20px;
  }

  .login-area {
    height: 100vh;
    position: relative;
    z-index: 100000;
    background-image: url("../images/singinbackground.jpeg");
    /* background-position: center center; */
    background-size: cover;
    background-repeat: no-repeat;
  }

  .login-signup-header {
    padding-top: 20px;
    padding-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1600px) {
  .login-area .login-form {
    width: 40%;
    height: auto;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.11);
    /* opacity: .77; */
    background: rgba(255, 255, 255);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 10px;
    margin: 0 auto;
    padding: 40px 50px 70px;
  }

  .login-area {
    height: 100vh;
    position: relative;
    z-index: 100000;
    background-image: url("../images/singinbackground.jpeg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .login-signup-header {
    padding-top: 30px;
    padding-left: 40px;
  }
}

@media only screen and (min-width: 1600px) {
  .login-area .login-form {
    width: 32%;
    height: auto;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.11);
    /* opacity: .77; */
    background: rgba(255, 255, 255);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 10px;
    margin: 0 auto;
    padding: 40px 60px 70px;
  }

  .login-area {
    height: 100vh;
    position: relative;
    z-index: 100000;
    background-image: url("../images/singinbackground.jpeg");
    background-position: cover cover;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .login-signup-header {
    padding-top: 30px;
    padding-left: 40px;
  }
}

.center-line {
  width: 1px;
  height: 100%;
  background-color: #6d6d6d;
}

.center-line-1 {
  width: 1px;
  height: 100%;
  background-color: #6d6d6d;
}

.center-portion {
  position: relative;
}

.center-abs {
  position: absolute;
}



.or-div {
  position: absolute;
  width: 35px;
  padding: 7px 6px 7px 7px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  background-color: #6d6d6d;

}

.or-text {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #fff;
}

.login-area .login-form h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.login-p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left !important;
}

.pad-x-35 {
  padding: 0px 35px;
}

.Dont-have-an-accoun {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
}

.Dont-have-an-accoun-signup {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  padding-right: 60px;
  /* color: #fff; */
}


.text-style-1 {
  font-weight: 600;
  color: #f43254;
  font-size: 13px;
}

.login-with-facebook {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  width: 100%;
  text-wrap: nowrap;
  /* width: 270px; */
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  background-color: #0866ff;
  color: white;
  border: none;
}

.login-with-linkedin {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  width: 100%;
  text-wrap: nowrap;
  /* width: 270px; */
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  background-color: #2d6bb4;
  color: white;
  border: none;
}

.login-with-google {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  width: 100%;
  text-wrap: nowrap;
  /* width: 270px; */
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  background-color: #db4437;
  color: white;
  border: none;
}

.login-with-twitter {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  width: 100%;
  text-wrap: nowrap;
  /* width: 270px; */
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  background-color: #000000;
  color: white;
  border: none;
}

.login-with-instagram {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  width: 100%;
  text-wrap: nowrap;
  /* width: 270px; */
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  background-color: #933c6f;
  color: white;
  border: none;
}


.login-btn-icon {
  height: 30px;
}

.login-label {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

}

.forgot-text {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #f43254;
}

.login-btn {
  /* changes */
  font-weight: 500;
  padding: 13px 0px 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
  background-color: #f43254;
  border: none;
  color: white;
}

.login-btn:hover {
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
  background-color: #ca2543;
  border: none;
  color: white;
}

.login-area .login-form form {
  padding-top: 15px;
  width: 100%;
}

.login-area .login-form form label {
  font-weight: 500;
}

.login-area .login-form form .form-control {
  background: transparent;
  font-size: 13px;
}

.login-area .login-form .btn {
  display: block;
  width: 100%;

}

.login-area .login-form p {
  overflow: hidden;
  margin-bottom: 0;
  /* margin-top: 20px; */
}

.login-area .login-form p a {
  color: #0d1028;
  font-weight: 600;
}

.login-area .login-form p a:hover,
.login-area .login-form p a:focus {
  color: #FF2D55;
}

.login-area .login-form p a.pull-left {
  float: left;
}

.login-area .login-form p a.pull-right {
  float: right;
}

.spinner-border {
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.select-dialing-code {
  border-right: none;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.error-msg-text {
  font-size: 13px;
}



.react-tel-input .form-control {
  /* background-color: rgb(253, 214, 214) !important; */
  color: black;
  height: 50px !important;
  width: 100% !important;
}

.react-tel-input {
  /* margin-top: 10px !important; */
  /* margin-left: 10px !important; */
}

.flag-dropdown {
  background-color: white !important;
}


@media only screen and (max-width: 600px) {
  .login-signup-logo {
    height: 18px;
  }
}

@media only screen and (max-width: 1600px) {
  .login-signup-logo {
    height: 38px
  }
}

@media only screen and (min-width: 1600px) {
  .login-signup-logo {
    height: 40px
  }
}

.logout-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.623);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.logout-popup {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logout-popup p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
}

.ok-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background: #f43254;
  color: white;
  cursor: pointer;
  font-size: 14px;
}