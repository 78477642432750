.filter-btn {
  width: 120px;
  padding: 13px 21px 13px 20px;
  border-radius: 4px;
  border: solid 1px #e9e9e9;
  background-color: #fff;
  color: black;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

/* Add this CSS to your stylesheet */
/* input range ki css */
/* Change the color of the track */
.input-range__track {
  background: #ccc;
  /* Change to your desired color */
}

/* Change the color of the active track */
.input-range__track--active {
  background: rgb(247, 135, 135);
  ;
  /* Change to your desired color */
}

/* Change the color of the thumb */
.input-range__slider {
  background: rgb(247, 135, 135);
  ;
  /* Change to your desired color */
  border: 2px solid #fff;
  /* Optional: Change the border color */
}

/* Optionally, change the hover and focus styles of the thumb */
.input-range__slider:active,
.input-range__slider:focus {
  background: rgb(156, 99, 99);
  ;
  /* Change to your desired color */
  border: 2px solid #fff;
  /* Optional: Change the border color */
}

.newevent {
  padding-top: 30px;
}

@media only screen and (max-width:992px) {
  .create-main-tab {
    /* position: sticky; */
    /* top: 120px; */
    /* height: 830px; */
    margin-bottom: 30px;
    display: flex;
    border-radius: 8px;
    /* margin-top: 13px; */
  }
}

@media only screen and (min-width:992px) {
  .create-main-tab {
    position: sticky;
    top: 120px;
    height: 830px;
    display: flex;
    border-radius: 8px;
    /* margin-top: 13px; */
  }
}


.create-event-tab {
  padding: 18px 18px;
  border: solid 1px rgba(244, 50, 84, 0.12);
  background-color: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #f43254;
  border: solid 1px #fce5e5;
  border-radius: 8PX;
}

.create-event-tab:hover {
  background-color: #f8eeee;
}

.create-event-tab:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.create-event-tab:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.active-create-event-tab {
  background-color: #f43254;
  font-family: Poppins;
  padding: 18px 18px;
  font-size: 14px;
  border-radius: 8PX;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: none;
  /* border-bottom: solid 1px #e9e9e9;
  border-left: solid 1px #e9e9e9;
  border-top: solid 1px #e9e9e9;
  border-right: solid 4px rgba(230, 49, 82, 0.829); */
}

.main-tab {
  display: flex;
  /*justify-content: center; */
  border-radius: 8px;
  margin-top: 50px;
}

.comp-form-tab {
  padding: 18px 18px;
  border: solid 1px rgba(244, 50, 84, 0.12);
  background-color: #edfff7;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #09bf6a;
  border: solid 1px #09bf6a;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}



.tab-button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tab-button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.active-tab-button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

/* .active-tab-button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
} */


.submitbutton {
  padding: 13px 30px;
  border-radius: 8px;
  background-color: #f43254;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.submitbutton:hover {
  background-color: #f43254;
  color: white;
}

.preview-btn {
  color: #f43254;
}

.preview-btn:hover {
  color: #f43254;
}

/* ================================ */
/* Basic-Form */
/* =============================== */


.basicInfo {
  padding-bottom: 30px;
}

.basicinfo-head {
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: center; */
  color: #333;
}

.form-section-heading {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}

.basicinfo-p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: center; */
  color: #333;
}

.Bannerimg-head {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}

.event-logo-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.event-banner-img {
  width: 400px;
  height: 180px;
  object-fit: cover;
}

.img-close-btn {
  background-color: white;
  border: none;
}

@media only screen and (max-width: 720px) {
  .bannerimg-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 220px;
    margin: 24px 0 0 1px;
    /* padding: 80px 131px; */
    border-radius: 5px;
    border: solid 1px #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
  }
}

@media only screen and (min-width: 720px) {
  .bannerimg-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    margin: 24px 0 0 1px;
    /* padding: 80px 131px; */
    border-radius: 5px;
    border: solid 1px #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
  }
}



.upload-image-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addlink-btn {
  padding: 13px 13px;
  border-radius: 8px;
  background-color: #f43254;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.addlink-btn:hover {
  background-color: #f43254;
  color: white;
}


.uploadimg-font {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333;
}

.img-format-text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #888;
}

.form-label {
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #818181;
}

.delete-event-btn {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #f43254;
}

.saveandcontinue-btn {
  position: relative;
  padding: 13px 51px 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
  background-color: #f74061;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border: none;
  padding: 13px 35px;
  border-radius: 6px;
  transition: 0.5s;
}

.saveandcontinue-btn:hover {
  transform: scale(1.03);
  background-color: #ec2146;
}

.react-time-picker__wrapper {
  width: 300px;
  padding: 10px;
  font-weight: 500;
  border: 1px solid #eeeeee;
  border-radius: 0;
  transition: 0.5s;
}

/* Packages Form */
/* =================================*/
.add-new-packages {
  padding: 13px 16px 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
  background-color: #f43254;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .sidemenu {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 20px;
    height: 300px;
    padding: 24px 20px;
    border-radius: 5px;
    border: solid 1px #e1e6d5;
    background-color: #fff;
    overflow: auto;
  }
}

@media only screen and (min-width: 768px) {
  .sidemenu {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 20px;
    height: 710px;
    padding: 24px 20px;
    border-radius: 5px;
    border: solid 1px #e1e6d5;
    background-color: #fff;
    overflow: auto;
  }
}

.select-pack-div {
  padding: 9px 17px 8px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  background-color: #f9f9f9;
}

.select-pack-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #888;
}

.form-heading {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}

.plus-div {
  padding: 9px;
  border-radius: 8px;
  background-color: #fff5f7;
}

.package-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}

.package-btn {
  border: none;
  padding: 0px 15px;
  border-radius: 0;
  transition: 0.5s;
  background-color: white;
}

.donation-input {
  position: relative;
}

.package-title-text {
  display: inline-block;
  width: 140px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* height: 40%; */
}

.donation-package-input {
  height: 70px;
}

.donation-package-input:hover {
  background-color: #fff5f7;
  color: #f43254;
}

.edit-btn {
  position: absolute;
  background-color: #ffffff00;
  border: none;
  right: 60px;
  z-index: 10;
  color: black;
  border: none;
  margin-top: 3px;
  padding: 11px 5px;
  border-radius: 0;
  transition: 0.5s;
}

.delete-btn {
  position: absolute;
  background-color: #ffffff00;
  right: 4px;
  z-index: 10;
  color: black;
  border: none;
  margin-top: 3px;
  padding: 11px 15px;
  border-radius: 0;
  transition: 0.5s;
}

.currency_tag {
  height: 50px;
  width: 70px;
  border: 1px solid green;
  /* font-size: large; */
  position: absolute;
  align-items: center;
  text-align: center;
  top: 33px;
  right: 10px;
  font-size: 14px;
  /* background: url('../images/ic-rupee.png'), #edfff7; */
  background-repeat: no-repeat;
  background-position: center;
  background: #edfff7;
}

.currency_tag:focus {
  outline: none;
}

/* ==================================== */
/* pitch form */
/* ==================================== */

.Addlink-btn {
  /* width: 160px; */
  padding: 13px 13px;
  border-radius: 8px;
  background-color: #f43254;
}

.reactQuill {
  height: 160px;
  margin-bottom: 85px;
}

.reactQuill-pitch {
  height: 180px;
  margin-bottom: 50px;
}

.reactQuill-div2 {
  height: 300px;
}

.upload-icon {
  cursor: pointer;
  height: 150px
}


.reactQuill-div {
  height: 300px;
}


/* change champ modal  */
.select_champ_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px 0px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  justify-content: center;
  align-items: center;
  /* align-items: center; */
  z-index: 200;

}

.select_champ_popup {
  height: auto;
  background: white;
  padding: 20px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}


.champ_form_close {
  position: absolute;
  top: 2px;
  right: 10px;
  cursor: pointer;
  font-size: 25px;
  color: #333;
}

.champ-modal-head {
  margin-top: 16px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333;
}

.champ-head-text {
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.champ-name-text {
  font-size: 14px;
  color: #f43254;
  font-weight: 500;
}

.change-champ-btn {
  border: none;
  background-color: #f43254;
  color: white;
  border-radius: 4px;
  padding: 10px 20px;
  font-weight: 600;
}

.cancel-champ-btn {
  border: none;
  background-color: white;
  color: #f43254;
  border-radius: 4px;
  padding: 10px 20px;
  font-weight: 600;
  transition: 1s;
}

.cancel-champ-btn:hover {
  transition: 1s;
  color: #2e2123;
}

.tab-button-info-text {
  font-size: 11px;
  color: #f43254;
}

.warningModal {
  display: block;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.445);
  padding: 20px;
  color: '#fff';
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
}


.address-select-info {
  font-size: 13px;
  color: rgb(77, 77, 207);
}



.create-msg-date-time-bar {
  height: 50px;
  /* width: 85%; */
  padding: 4px 0px;
  /* border-radius: 4px; */
  /* box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5); */
  border-bottom: solid 1px rgb(208, 208, 208);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.create-Message {

  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f3d56;

}

.create-Date-Time {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f3d56;
  border: none;
  background-color: #f43254;
  color: white;
  border-radius: 4px;
  /* padding: 10px 10px; */
  padding: 4px 10px;
  font-weight: 600;
}

.Coupon-list-head {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: center; */
  color: #333;

}

.image-remove-icon {
  position: absolute;
  z-index: 20;
  right: 16px;
  top: 10px;
  background-color: transparent;
  border: none;
  background-color: #f71941;
  padding: 2px 10px;
  border-radius: 4px;
}

.image-remove-icon:hover {
  background-color: #d61438;
}

.create-event-btn {
  border: none;
  background-color: transparent;
  color: #f71941;
  padding: 0px;
  margin: 0px;
}

.shield-btn {
  position: absolute;
  top: 80px;
  right: 0px;
  padding: 8px;
  border: none;
  background-color: #f71941;
  border-radius: 20px;
}

.create-event-shield-btn {
  position: absolute;
  top: 45px;
  right: 15px;
  /* padding: 4px; */
  border: none;
  background-color: #f71941;
  border-radius: 50%;
}

.event-detail-host-section-shield-btn {
  position: absolute;
  top: 50px;
  right: 0px;
  /* padding: 4px; */
  border: none;
  background-color: #f71941;
  border-radius: 50%;
}

.event-partnerShip-form {
  background-color: rgb(245, 247, 255);
  padding: 20px 20px;
  border-radius: 10px;
}

.event-profile-img {
  border-radius: 50%;
  height: 140px;
  object-fit: cover;
}

.event-detail-profile {
  border-radius: 50%;
  height: 60px;
  object-fit: cover;
}

.event-detail-shield-btn {
  position: absolute;
  /* top: 10px; */
  bottom: 10px;
  right: -5px;
  padding: 1px;
  border: none;
  background-color: #f71941;
  border-radius: 20px;
}

.create-event-p {
  font-size: 14px;
  font-weight: 500;
  color: rgb(39, 39, 39);
  margin: 0px;
  padding: 0px;
}

.user-detail-div {
  background-color: rgb(247, 248, 255);
  padding: 20px 40px;
  border-radius: 10px;
}

.about-me-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.about-me-div p {
  margin: 0 4px;
  font-weight: 400;
  font-size: 13px;
  color: black;
}

.about-me-div input {
  border-radius: 4px;
  border: solid 1px rgb(160, 160, 160);
  padding: 7px 8px;
  font-weight: 500;
  font-size: 13px;
  margin-right: 3px;
  /* color: black; */
}

.about-me-div input:focus {
  outline: none;
  border: solid 1px rgb(68, 68, 68);
}

.about-me-div textarea {
  width: 260px;
  border-radius: 4px;
  border: solid 1px rgb(160, 160, 160);
  padding: 7px 8px;
  font-weight: 400;
  font-size: 13px;
  margin-right: 3px;
  font-weight: 500;
}

.about-me-div textarea:focus {
  outline: none;
  border: solid 1px rgb(68, 68, 68);
}

.my-journey-textarea {
  border-radius: 4px;
  border: solid 1px rgb(160, 160, 160);
  padding: 7px 8px;
  font-weight: 400;
  font-size: 13px;
  margin-right: 3px;
}

.my-journey-textarea:focus {
  outline: none;
  border: solid 1px rgb(68, 68, 68);
}

.address-input {
  width: auto;
}

.industry-input {
  width: 220px;
}

.head-label {
  font-size: 16px;
  font-weight: 500;
  color: black;
  margin-bottom: 6px;
}

.cancellation-p-text {
  font-size: 13px;
  font-weight: 500;
  color: black;
}

.cancellation-input {
  padding: 6px 4px;
  margin-left: 6px;
  margin-right: 6px;
  border: solid 1px rgb(168, 165, 165);
  border-radius: 4px;
  font-size: 13px;
}

.cancellation-input:focus {
  outline: rgb(97, 97, 97);
  border: solid 1px rgb(165, 165, 165);
}

/* .css-13cymwt-control {
  border-radius: 0px !important;
  border-style: none !important;
}

.css-b62m3t-container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 50px;
  padding: 5px 0px 15px 0px;
} */

.banner-main-div {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.banner-image {
  height: 150px;
  width: 100%;
  border-radius: 4px;
}

.sponsor-item {
  background-color: #f0f0ff;
  border-radius: 6px;
  padding: 16px 18px;
  /* width: 50%; */
}

.sponsor-details {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}


.sponsor-logo {
  height: 50px;
  border-radius: 4px;
}

.add-interests-btn {
  padding: 10px 15px;
  background-color: rgb(255, 228, 228);
  border: none;
  font-weight: 500;
  color: #f43254;
  font-size: 14px;
  border-radius: 4px;
  text-wrap: nowrap;
}

.active-add-interests-btn {
  padding: 10px 15px;
  background-color: #f43254;
  border: none;
  font-weight: 500;
  color: rgb(255, 255, 255);
  font-size: 14px;
  border-radius: 4px;
  text-wrap: nowrap;
}

.new-interests-btn {
  padding: 7px 10px;
  background-color: #f43254;
  border: none;
  font-weight: 500;
  color: white;
  font-size: 14px;
  border-radius: 4px;
}

.about-me-section {
  background-color: rgb(245, 245, 255);
  padding: 12px 20px;
  border-radius: 8px;
}

.add-schedule-btn {
  background-color: transparent;
  color: #f43254;
  font-weight: 500;
  font-size: 14px;
  border: none;
  margin: 0px;
  padding: 0px;
}

.date-title {
  color: #f43254;
  font-weight: 500;
  font-size: 14px;
}

.schedule-main-div {
  height: auto;
  max-height: 1000px;
  overflow-y: auto;
}

.schedule-div {
  /* border: 1px solid black; */
  border-radius: 6px;
  padding: 10px 20px;
  background-color: rgb(248, 248, 255);
  margin-bottom: 20px;
}

.schedule-remove-btn {
  position: absolute;
  right: 0px;
  top: -6px;
  background-color: transparent;
  border: none;
  color: #f43254;
}

.form-check-radio {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: .125rem;
}

.form-check-input-radio {
  padding-left: 1.5em;
  margin-right: 6px;
}

/* .form-check-input-radio:active {
    background-color: #f43254 !important;
    border-color: #0d6efd;
  }

  .form-check-input-radio:focus {
    background-color: #f43254 !important;
    border-color: #0d6efd;
  } */

.form-check-input-radio:checked {
  background-color: #fd0d0d;
  border-color: #0d6efd;
  margin-left: 0px;
}

/* .tab-radio-btn{
  background-color:transparent;
} */

.tab-radio-btn:focus {
  /* background-color: #fd0d0d;  */
  border-color: #a7a7a7;
  box-shadow: 0 0 0 .25rem rgba(253, 13, 13, 0.25);
}

.tab-radio-btn:checked {
  background-color: #fd0d0d;
  border-color: #a7a7a7;
}

@media only screen and (max-width: 768px) {
  .create-event-heading {
    font-size: 16px;
    font-weight: 700;
    color: rgb(61, 61, 61);
  }

  .map-search-input {
    position: absolute;
    top: 60px;
    /* left: 320px; */
    z-index: 90;
    padding: 10px 14px;
    width: 300px;
    border-radius: 20px;
    border: solid 1px rgba(103, 115, 182, 0.541);
    outline: none;
    font-size: 14px;
    /* font-weight: 500; */
  }
}

@media only screen and (min-width: 768px) {
  .create-event-heading {
    font-size: 20px;
    font-weight: 700;
    color: rgb(61, 61, 61);
  }

  .map-search-input {
    position: absolute;
    top: 8px;
    /* left: 320px; */
    z-index: 90;
    padding: 10px 14px;
    width: 300px;
    border-radius: 20px;
    border: solid 1px rgba(103, 115, 182, 0.541);
    outline: none;
    font-size: 14px;
    /* font-weight: 500; */
  }
}


.createevent-head-p {
  font-size: 14px;
  color: rgb(27, 27, 27);
  padding-bottom: 6px !important;
}

.go-back-btn {
  border: none;
  background-color: transparent;
  color: #f43254;
  font-weight: 600;
}

.reactquill-tooltip-p{
  font-size: 12px;
}

.css-13cymwt-control {
  font-size: 14px;
}

.transfer-event-text{
  font-size: 15px;
  color: #000;
  font-weight: 500;
}