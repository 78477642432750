/* testimonial css start------------------------------------------ */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 250;
  padding: 20px;
}

.popup {
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 90%;
  max-width: 750px;
}

.popup-content {
  /* text-align: center; */
}

.close {
  cursor: pointer;
  font-size: 35px;
  color: #333;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .popup {
    width: 95%;
    padding: 10px;
  }

  .close {
    font-size: 30px;
  }

  .popup-content {
    padding: 10px;
  }

  .reactQuill-div {
    width: 100%;
  }

  .reactQuill-div .ql-editor {
    height: auto;
    min-height: 100px;
  }

  .Character-0500 {
    font-size: 12px;
  }

  .form-group input,
  .form-group .form-control {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .popup {
    width: 100%;
    max-width: 400px;
  }

  .close {
    font-size: 30px;
  }
}


@media (min-width: 800px) {
  .responsive-element-richtextbox {
    width: 710px;
    height: 130px;
  }
}

/* testimonial css end------------------------------------------ */

/* ------------------------------------------------------------------------------------- */

/* event detail review css start------------------------------------------ */