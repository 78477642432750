@media only screen and (max-width: 720px) {
  .event-pro-head {
    font-family: Poppins;
    font-size: 23px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
    text-transform: capitalize;
    padding-top: 20px;
  }
}

@media (min-width: 720px) and (max-width: 1600px) {
  .event-pro-head {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
    text-transform: capitalize;
    padding-top: 20px;
  }

  .detail-p-text {
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
    /* text-wrap:nowrap; */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 1600px) {
  .event-pro-head {
    font-family: Poppins;
    font-size: 27px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
    text-transform: capitalize;
    padding-top: 20px;
  }

  .detail-p-text {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #494949;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width:576px) {
  .detail-banner {
    position: relative;
    padding-top: 10px;
    height: 300px;
    /* background-image: url("../images/detail-banner.png"); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .navigate-p {
    color: #f43254;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .Attendees-text {
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white;
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 576px) {
  .detail-banner {
    position: relative;
    padding-top: 10px;
    height: 500px;
    /* background-image: url("../images/detail-banner.png"); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .navigate-p {
    color: #f43254;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .Attendees-text {
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white;
    margin-bottom: 8px;
  }
}

.detailevent-card {
  position: relative;
}

.attendees-btn {
  padding: 16px 9px;
  /* opacity: 0.6; */
  border-radius: 8px;
  background-color: #000000d4;
}

.Attendees-1-100 .text-style-1 {
  font-weight: bold;
  color: #ffffff;
}

.intrest-detail-page {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.display-event-curve-div {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.display-event-curve-show {
  padding: 5px 10px;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  font-family: Arial, sans-serif;
  font-size: 13px;
  color: #747677;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
}


@media only screen and (max-width: 720px) {
  .detail-img-div {
    position: sticky;
    z-index: 80;
    margin-top: -70px;
    width: 100%;
    left: 80px;
    border-radius: 8px;
    border: solid 5px #fff;
    background-color: #fff;
  }

  .social-m-head {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-wrap: wrap;
    color: #333;
  }

  .social-child-heading {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-wrap: nowrap;
    color: #333;
  }

  .detail-p-text {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
    /* text-wrap:nowrap; */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cardimg1 {
    border-radius: 8px;
    width: 100%;
    height: 300px;
    object-fit: cover;
    padding: 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
}

@media (min-width:720px) and (max-width: 1400px) {
  .detail-img-div {
    position: sticky;
    z-index: 100;
    margin-top: -50px;
    width: 100%;
    left: 80px;
    border-radius: 8px;
    border: solid 5px #fff;
    background-color: #fff;
  }

  .cardimg1 {
    border-radius: 8px;
    width: 100%;
    height: 300px;
    object-fit: cover;
    padding: 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }

  .social-m-head {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-wrap: nowrap;
    color: #333;
  }

  .social-child-heading {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-wrap: nowrap;
    color: #333;
  }
}

@media only screen and (min-width: 1400px) {
  .detail-img-div {
    position: sticky;
    z-index: 100;
    margin-top: -70px;
    width: 100%;
    /* left: 300px; */
    border-radius: 8px;
    border: solid 5px #fff;
    background-color: #fff;
    box-shadow: rgba(48, 48, 48, 0.15) 0px 5px 15px 0px;
  }

  .cardimg1 {
    border-radius: 8px;
    width: 100%;
    height: 350px;
    object-fit: cover;
    padding: 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }

  .social-m-head {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-wrap: nowrap;
    color: #333;
  }

  .social-child-heading {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;

    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-wrap: nowrap;
    color: #1f1e1e;
  }
}

.detail-text-div {
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
  /* padding-top: 20px; */
  /* margin-left: 20px; */
}

.t-paragraph {
  margin-top: 30px;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #333;
}

.t-paragraph1 {
  font-family: 'Poppins';
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: rgb(106, 106, 106);

}

.t-paragraph1 ul {
  list-style-type: disc; 
  margin-left: 20px; 
}

.social-media-div {
  margin-top: 20px;
}

.vide0-div {
  margin-top: 30px;
}


.rounded-ringus-main {
  display: flex;
  justify-content: center;
  /* align-items: center;   */
}

@media only screen and (max-width: 720px) {
  .social-back-img {
    width: 80%;
    height: 160px;
    background-image: url('../images/group.png');
    background-size: 100% 160px;
    object-fit: contain;
    background-repeat: no-repeat;
    /* padding-right: 100px; */
  }

  .rounded-ringus-img {
    height: 130px;
    width: 130px;
    /* background-image: url("../images/ringus-rounded-background.svg"); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .rounded-ringus-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 100px;
    height: 100px;
    padding: 20px 20px 20px 20px;
    box-shadow: 0 0 40px 0 rgba(228, 57, 57, 0.55);
    ;
    background-color: #f43254;
    border-radius: 100%;
  }

  .linkedin-div {
    margin-left: -30px;
    height: 45px;
    width: 45px;
    padding: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }

  .twitter-div {
    margin-right: -30px;
    height: 42px;
    width: 42px;
    padding: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }

  .google-div {
    margin-top: 90px;
    height: 45px;
    width: 45px;
    padding: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }

}

@media only screen and (min-width: 720px) {
  .social-back-img {
    width: 85%;
    height: 270px;
    background-image: url('../images/group.png');
    background-size: 98% 260px;
    object-fit: contain;
    background-repeat: no-repeat;
    /* padding-right: 100px; */
  }

  .rounded-ringus-img {
    height: 200px;
    width: 200px;
    /*background-image: url("../images/ringus-rounded-background.svg"); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .rounded-ringus-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    width: 154px;
    height: 154px;
    padding: 61px 29px 61px 29px;
    /* box-shadow:  0 0 40px 0 rgb(227 43 43 / 55%); */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;
    border-radius: 100%;
  }

  .linkedin-div {
    margin-top: 100px;
    margin-left: 0px;
    height: 74px;
    width: 74px;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }

  .twitter-div {
    margin-right: -30px;
    height: 74px;
    width: 74px;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }

  .google-div {
    margin-top: 0px;
    height: 74px;
    width: 74px;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }
}

.event-video {
  border-radius: 8px;
}

.bottom-square-img {
  height: 185px;
  border-radius: 8px;
}

.bottom-verti-img {
  height: 395px;
  border-radius: 8px;
}

.packages-div {
  /* position: sticky; */
  /* top: 100px; */
  /* padding-top: 20px; */
  height: auto;
  /* max-height: 1200px;
  overflow: auto; */
}

.package-card {
  width: 100%;
  padding: 16px 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #ececec;
  background-color: #fff;
  margin-top: 10px;
  overflow: hidden;
  max-height: 60px;
  transition: max-height 0.5s ease-in-out;
  border-radius: 8px;
}

.package-card.open {
  max-height: 100%;
  overflow-y: auto;
  /* Set a value based on your content height */
}

.pack-card-heading {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}

.pack-content-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}

.get-it-btn {
  width: 100%;
  margin-top: 10px;
  padding: 15px 40px 14px 40px;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
  background-color: #f43254;
  border: none;
  color: white;
  font-weight: 600;
  transition: all 0.3s;
}

.get-it-btn:hover {
  background-color: #eb183f;
}

.partner-btn {
  width: 100%;
  margin-top: 10px;
  padding: 15px 40px 14px 40px;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.3);
  background-color: #f43254;
  border: none;
  color: white;
  font-weight: 600;
}

.partner-btn:hover {
  background-color: #eb183f;
}

.past-event-btn {
  width: 100%;
  margin-top: 10px;
  padding: 15px 90px 14px 97.5px;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(189, 189, 189, 0.3);
  background-color: rgb(231, 231, 231);
  border: none;
  color: rgb(26, 23, 23);
  font-weight: 600;
}

.pack-price-text {
  font-weight: bold;
  color: #1a221e;
  /* text-decoration: line-through; */
}

.pack-strike-price-text {
  font-weight: bold;
  color: #1a221e;
  text-decoration: line-through;
}

.pack-offerprice-text2 {
  font-weight: bold;
  color: #bf1509;
}

.pack-offerprice-text {
  font-weight: bold;
  color: #09bf6a;
}

.pack-bold-text {
  font-weight: bold;
  color: #333333;
}

.card-drop-btn {
  width: 100%;
  background-color: white;
  border: none;
}



@media only screen and (max-width: 1800px) {
  .pdf-div {
    width: 100%;
    height: 150px;
    padding: 36px 26px 27px 26px;
    object-fit: contain;
    border-radius: 8px;
    /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05); */
    border: solid 1px #e9e9e9;
    background-color: #fff;
  }

  .event-gellary-img {
    border-radius: 8px;
    height: 190px;
    width: 100%;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1800px) {
  .pdf-div {
    width: 150px;
    height: 170px;
    padding: 36px 47px 27px 43px;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e9e9e9;
    background-color: #fff;
  }

  .event-gellary-img {
    border-radius: 8px;
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
}

/* .pdf-main-div{
  gap: 15px 30px
} */

.pdf-title {
  margin: 16px 0 0;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.custom-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal.show {
  display: flex;
}

.modal-dialog {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 400px;
}

@media only screen and (max-width: 720px) {
  .modal-content {
    padding: 20px;
    width: auto;
    height: auto;
  }

  .modal-dis {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
    height: auto;
    max-height: 130px;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 720px) {
  .modal-content {
    padding: 20px;
    width: 521px;
    height: auto;
  }

  .modal-dis {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
    height: auto;
    max-height: 200px;
    overflow-y: auto;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 10px 10px;
}

.modal-title {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
  margin: 0;
}

.modal-body {
  /* margin-bottom: 20px; */
  padding: 10px;
}

.btn-close {
  background-color: transparent;
  border: none;
  cursor: pointer;
}



.Character-0500 {
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #818181;
}

.Character-0500 .text-style-1 {
  color: #333;
}



.send-Query-btn {
  padding: 10px 13px;
  border-radius: 6px;
  background-color: #f43254;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: none;
}

.img-select-button {
  border: none;
  background-color: white;
}

/* r--------------------------------- */

@media only screen and (max-width: 1800px) {
  .reviews-card {
    width: 350px;
    height: 378px;
    padding: 28px 28px 36px;
    border-radius: 6px;
    border: solid 1px #e1e6d5;
    background-color: #fff;
  }
}

@media only screen and (min-width: 1800px) {
  .reviews-card {
    width: 410px;
    height: 378px;
    padding: 28px 28px 36px;
    border-radius: 6px;
    border: solid 1px #e1e6d5;
    background-color: #fff;
  }
}



.reviews-img {
  height: 60px;
  border-radius: 50%;
  width: 64px
}

.reviews-name-text {
  margin: 0 0 4px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #333333;
}

.reviews-text {
  margin: 4px 56px 0 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #909090;
}

.reviews-paragraph {
  margin: 0px 0 13px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #333;
  text-wrap: wrap;
}

.reviewImges {
  height: 75px;
  width: 60px;
  object-fit: cover;
  border-radius: 5px;
}

/* ------------ */


/* Gallery Modal  */
.gallery-modal {
  position: relative;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.gallery-modal.show {
  display: flex;
}

.gallery-dialog {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 60%;
  height: auto;
}

.gallery-content {
  padding: 20px;
  width: 100%;
}

.gallery-close-btn {
  position: absolute;
  z-index: 20;
  right: 30px;
  top: 30px;
  background-color: rgb(87, 87, 87);
  border-radius: 20px;
  border: none;
}

.error-text {
  font-size: 13px;
  color: rgb(185, 0, 0);
}

.swiper-div {
  width: 60%;
}

.detail-page-info {
  padding: 10px 9px;
  background-color: #000000d4;
  border-radius: 6px;
}

@media only screen and (max-width: 576px) {
  .sample-head-text {
    font-size: 11px;
    color: #f43254;
    border-radius: 6px;
  }

  .sample-head-div {
    padding: 4px 9px;
    background-color: #ffefef;
    border-radius: 6px;
  }

}

@media only screen and (min-width: 576px) {
  .sample-head-text {
    font-size: 13px;
    color: #f43254;
    border-radius: 6px;
  }

  .sample-head-div {
    padding: 10px 9px;
    background-color: #ffefef;
    border-radius: 6px;
  }

}


.gellary-sel-img-div {
  height: 180px;
  overflow-y: auto;
}

.event-gellary-sel-img {
  /* overflow-x: auto; */
  border-radius: 8px;
  height: 160px;
  width: 100%;
  object-fit: cover;
}

.disable-link-img {
  filter: grayscale(80%);
}

/* reject css---------------------------------start */
.chat-box {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.message-section {
  height: 400px;
  overflow-y: auto;
}

.message-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

}

.message {
  /* margin-bottom: 10px; */
  padding: 0px 0px 8px 0px;
  border-radius: 5px;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  /* white-space: nowrap; */
}

.user {
  background-color: #f8c6cf;
  align-self: flex-end;
}

.bot {
  background-color: #f4f4f4;
  align-self: flex-start;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.input-container input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-container button {
  margin-left: 10px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  background-color: #dc3545;
  color: white;
  cursor: pointer;
}

.input-container button:hover {
  background-color: #e91e32;
  transition: 0.3s;
  /* Darker green */
}

.eUkqnB {
  height: 500px;
}

.chat-h5-head {
  font-size: 14px;
  margin-bottom: 0px;
  /* font-weight: 700; */
}

.chat-section-div {
  /* background-color: white; */
  padding: 10px;
  border-radius: 6px;
  border: solid 1px rgb(187, 187, 187);
  box-shadow: 2px 2px 5px #ededed;
  width: 60%;
  margin-top: 20px;
  border-radius: 5px;
}

/* reject csss---------------------------------end */

@media only screen and (max-width: 720px) {
  .detail-gender-p {
    color: black;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    margin-left: 2px;
  }

  .time-text {
    font-size: 10px;
    text-align: center;
  }
}

@media only screen and (min-width: 720px) {
  .detail-gender-p {
    color: black;
    font-weight: 500;
    font-size: 12px;
  }

  .time-text {
    font-size: 11px;
    text-align: center;
  }
}

.male-color-div {
  height: 15px;
  width: 15px;
  border-radius: 10px;
  background-color: rgb(120, 120, 172);
}

.female-color-div {
  height: 15px;
  width: 15px;
  border-radius: 10px;
  background-color: rgb(126, 94, 82);
}


.other-color-div {
  background-color: rgb(83, 136, 107);
  height: 15px;
  width: 15px;
  border-radius: 10px;
}

.male-per-div {
  background-color: rgb(120, 120, 172);
  height: 24px;
  display: inline-block;
}

.female-per-div {
  background-color: rgb(126, 94, 82);
  height: 24px;
  display: inline-block;
}

.other-per-div {
  background-color: rgb(83, 136, 107);
  height: 24px;
  display: inline-block;
}

.detail-event-status-div {
  position: absolute;
  z-index: 10;
  background-color: rgb(255, 84, 32);
  color: #fff;
  top: 12px;
  right: 10px;
  padding: 2px 8px;
  border-radius: 3px;
  font-family: Poppins;
  font-size: 10px;
}

.event-mode-div {
  position: absolute;
  z-index: 10;
  background-color: rgb(57, 184, 40);
  color: #fff;
  top: 12px;
  left: 10px;
  padding: 2px 8px;
  border-radius: 3px;
  font-family: Poppins;
  font-size: 10px;
}

.rating-content-div {
  height: 90px;
  overflow-y: auto;
}

.copy-link-btn {
  background-color: rgb(241, 40, 40);
  border-radius: 20px;
  border: none;
  height: 25px;
  width: 25px;
}

.copied-link-btn {
  background-color: rgb(197, 0, 0);
  border-radius: 20px;
  border: none;
  height: 25px;
  width: 25px;
}



/*.detail-page-banner-info :first-child {
  background-color: red;
}

.detail-page-banner-info :last-child {
  background-color: blue;
} */


/* .detail-page-banner-info {
  position: absolute;
  bottom: 10px;
  left: 600px;
  width: calc(100% - 620px);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  z-index: 100;
} */

.detail-page-banner-info> :first-child {
  /* background-color: white; */
}

.detail-page-banner-info> :last-child {
  /* background-color: white; */
}

/* Media queries for responsiveness */
@media (min-width: 1900px) {
  .detail-page-banner-info {
    position: absolute;
    bottom: 10px;
    right: 50px;
    width: calc(90% - 470px);
    z-index: 40;
    bottom: 10px;
    display: flex;
    justify-content: space-between
  }
}

@media (min-width: 1600px) and (max-width: 1900px) {
  .detail-page-banner-info {
    position: absolute;
    bottom: 10px;
    right: 50px;
    width: calc(90% - 450px);
    z-index: 40;
    bottom: 10px;
    display: flex;
    justify-content: space-between
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .detail-page-banner-info {
    position: absolute;
    bottom: 10px;
    right: 50px;
    width: calc(83% - 235px);
    z-index: 40;
    bottom: 10px;
    display: flex;
    justify-content: space-between
  }
}

@media (max-width: 1200px) {
  .detail-page-banner-info {
    position: absolute;
    bottom: 10px;
    right: 50px;
    width: calc(90% - 300px);
    z-index: 40;
    bottom: 10px;
    display: flex;
    justify-content: space-between
  }

  /* .detail-page-banner-info> :first-child,
  .detail-page-banner-info> :last-child {
    flex: unset;
    width: 100%;
    margin-bottom: 10px;
  } */
}

.set-center {
  flex: 2;
  /* You can adjust this value to control the space taken by the center div */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.partnerhost_form_popup {
  background: white;
  padding: 25px 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  height: fit-content;
  /* width: 950px; */
  margin-left: 20px;
  margin-right: 20px;
}

.banner-top-section {
  position: absolute;
  top: 0px;
  z-index: 100;
  width: 100%;
}

.close-btn {
  background-color: transparent;
  border: none;
}

.detail-social-div {
  margin-top: 100px;
}


.packagedetail-mdl {
  width: 35%;
  height: auto;
  max-height: 600px;
  padding: 20px;
  background-color: white !important;
  border-radius: 8px;
}

/* Tablet (768px and up) */
@media (max-width: 992px) {
  .packagedetail-mdl {
    width: 50%;
    max-height: 550px;
    padding: 15px;
  }
}

/* Mobile landscape (576px to 767px) */
@media (max-width: 768px) {
  .packagedetail-mdl {
    width: 70%;
    max-height: 500px;
    padding: 15px;
  }
}

/* Mobile portrait (up to 575px) */
@media (max-width: 576px) {
  .packagedetail-mdl {
    width: 90%;
    max-height: 450px;
    padding: 10px;
  }
}

/* Desktop (992px and up) */
@media (min-width: 993px) {
  .packagedetail-mdl {
    width: 35%;
  }
}


.packagedetail-content {
  height: auto;
  max-height: 500px;
  overflow-y: auto;
}

.partner-form-div {
  height: 600px;
  overflow-y: scroll;
}

.attendees-div {
  position: absolute;
  top: -60px;
  z-index: 80;
}

.NoOfView-div {
  position: absolute;
  bottom: 10px;
  right: 30px;
  z-index: 80;
}

.hosted-by-div {
  /* border: 1px solid rgb(212, 212, 212); */
  padding: 12px 20px;
  height: 130px;
  /* overflow-y: auto; */
  /* width: 50%; */
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(189, 189, 189, 0.35) 0px 5px 15px;
}

/* schedule div */
.schedule-main-div {
  /* border: 1px solid rgb(201, 201, 201); */
  padding: 20px 20px;
  border-radius: 10px;
  box-shadow: rgba(151, 151, 151, 0.2) 0px 12px 14px 6px;
  border: 1px solid rgb(226, 226, 226);
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.schedule-tabs-container {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
}

.schedule-tabs {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 500px;
  overflow-y: auto;
  /* width:80%; */
  /* border-bottom: 1px solid #ddd; */
  overflow-x: auto;
  gap: 15px;
}

.schedule-tab {
  padding: 18px 20px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  width: 95%;
  border-bottom: 2px solid transparent;
  border-radius: 10px;
  transition: border-bottom 0.3s ease;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; */
  color: #f43254;
  border: solid 1px #ffdddd;
}

.schedule-tab:hover {
  color: #f43254;
  background-color: #fff1f4;
}

.schedule-tab.active {
  background-color: #f43254;
  border-radius: 10px;
  color: white;
  border-right: 3px solid #f43254;
  font-weight: 500;
}

.tab-content {
  width: 100%;
  /* padding: 20px 0; */
  height: auto;
  max-height: 500px;
  overflow-y: auto;
}

.tab-value-section {
  display: flex;
  gap: 10px;
  border: solid 1px rgb(194, 194, 194);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px 15px;
}

.tab-panel {
  display: none;
  /* border-bottom:1px solid black; */
}

.tab-panel.active {
  display: block;
}

.time-div {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  padding: 13px 20px;
  font-weight: 500;
  border-radius: 5px;
  /* margin-bottom: 10px; */
  font-size: 14px;
  width: max-content;
}

.details {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #0e3b6b;
}

.description {
  color: #666;

}

.detail-main-div {
  background-color: rgb(216, 216, 216);
}

.detail-location-section {
  /* border: 1px solid rgb(228, 228, 228); */
  /* border: 1px solid rgb(212, 212, 212); */
  /* border-bottom: 1px solid rgb(212, 212, 212); */
  background-color: white;
  /* border-radius: 6px; */
  padding-bottom: 8px;
}

.detail-location-head-div {
  margin: 4px 0px;
  /*border-bottom: solid 1px rgb(214, 214, 214); */
}

.detail-location-section-head {
  color: rgb(78, 78, 78);
  margin: 0px;
  font-size: 14px;
  font-weight: 700;
}

.daysleft-p {
  font-size: 12px;
  margin: 0px;
  font-weight: 500;
}

.event-host-p {
  font-size: 13px;
}

.event-host-review-p {
  font-size: 13px;
}

.event-host-name-title {
  font-size: 18px;
  font-weight: 600;
}

.event-reject-input {
  font-size: 13px;
  font-weight: 400;
}

.approve-btn {
  border: none;
  background-color: rgb(0, 143, 0);
  border-radius: 6px;
  padding: 10px 15px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s;
}

.reject-btn {
  border: none;
  background-color: rgb(211, 0, 0);
  border-radius: 6px;
  padding: 10px 15px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s;
}

.approve-btn:hover {
  background-color: rgb(0, 117, 0);
}

.reject-btn:hover {
  background-color: rgb(185, 0, 0);
}

.package-inclusions-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #333;
}

@media only screen and (max-width: 720px) {
  .pdfview_popup {
    background: white;
    padding: 25px 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    height: auto;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (min-width: 720px) and (max-width: 1200px) {
  .pdfview_popup {
    background: white;
    padding: 25px 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    height: auto;
    width: 80%;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (min-width:1200px) {
  .pdfview_popup {
    background: white;
    padding: 25px 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    height: auto;
    width: 50%;
    margin-left: 20px;
    margin-right: 20px;
  }
}
