/* ======================================= */
/* main banner */
/* ======================================== */

@media only screen and (max-width: 992px) {
  .main-banner {
    height: 500px;
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .main-banner-content h1 {
    color: #ffffff;
    margin: 0;
    font-weight: normal;
    font-size: 22px;
    text-align: center;

  }

  .main-banner-content h2 {
    color: #ffffff;
    margin: 0;
    font-weight: normal;
    font-size: 15px;
    text-align: center;
  }

  .event-sponsor-btn {
    display: flex;
    justify-content: center;
    height: 40px;
    /* gap: 10px; */
    padding: 10px 30px;
    border-radius: 2px;
    /* background-color: #f43254; */
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    border: none;
  }

  .event-organisor-btn {
    display: flex;
    gap: 10px;
    height: 40px;
    padding: 10px 30px;
    border-radius: 2px;
    /* background-color: #fff; */
    border: none;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: normal; */
    letter-spacing: normal;
    color: #333;
  }


  .star-icon {
    color: #F9F9FF;
    object-fit: contain;
    margin-right: 6px;
  }

  .main-banner-content.banner-content-center {
    margin: 0px auto 0;
    text-align: center;
  }
}



@media (min-width: 992px) and (max-width: 1600px) {
  .main-banner {
    height: 90vh;
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .main-banner-content h1 {
    color: #ffffff;
    margin: 0;
    font-weight: normal;
    font-size: 35px;
    text-align: center;

  }

  .main-banner-content h2 {
    color: #ffffff;
    margin: 0;
    font-weight: normal;
    font-size: 26px;
    text-align: center;
  }

  /* .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    margin-left: 25px;
    content: 'prev';
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    margin-right: 25px;
    content: 'next';
  } */

  .event-sponsor-btn {
    display: flex;
    align-items: center;
    /* height: 50px; */
    /* gap: 10px; */
    padding: 12px 25px 12px 25px;
    border-radius: 8px;
    /* background-color: #f43254; */
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.38; */
    letter-spacing: normal;
    color: #fff;
    border: none;
    text-wrap: nowrap;
  }

  .event-organisor-btn {
    /* height: 50px; */
    padding: 12px 25px 12px 25px;
    border-radius: 8px;
    /* background-color: #fff; */
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.38; */
    letter-spacing: normal;
    color: #333;
    text-wrap: nowrap;
  }

  .star-icon {
    color: #F9F9FF;
    object-fit: contain;
    margin-right: 10px;
  }

  .main-banner-content.banner-content-center {
    margin: 0px auto 0;
    text-align: center;
  }
}

@media only screen and (min-width: 1600px) {
  .main-banner {
    height: 90vh;
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .main-banner-content h1 {
    color: #ffffff;
    margin: 0;
    font-weight: normal;
    font-size: 48px;
    text-align: center;

  }

  .main-banner-content h2 {
    color: #ffffff;
    margin: 0;
    font-weight: normal;
    font-size: 32px;
    text-align: center;
  }

  /* .swiper-button-prev {
    width: 45px;
    height: 45px;
    background-color: #f3173fb0;
    color: white;
    border-radius: 50%;
    transition: all .5s;
    margin-left: 12px;
  }

  .swiper-button-prev:hover {
    background-color: #f30a35;
    border: solid 2px #f43254;
    transition: all .5s;
  } */

  /* .swiper-button-next {
    width: 45px;
    height: 45px;
    background-color: #f3173fb0;
    color: white;
    border-radius: 50%;
    transition: all .5s;
    margin-right: 12px;
  }

  .swiper-button-next:hover {
    background-color: #f30a35;
    border: solid 2px #f43254;
    transition: all .5s;
  } */

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    /* margin-left: 25px; */
    content: 'prev';
    font-size: 18px;
    font-weight: 600;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 18px;
    font-weight: 600;
  }

  .event-sponsor-btn {
    display: flex;
    align-items: center;
    /* height: 50px; */
    /* gap: 10px; */
    padding: 10px 15px 10px 15px;
    border-radius: 8px;
    /* background-color: #f43254; */
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.38; */
    letter-spacing: normal;
    color: #fff;
    border: none;
    text-wrap: nowrap;
  }

  .event-organisor-btn {
    /* height: 50px; */
    padding: 10px 15px 10px 15px;
    border-radius: 8px;
    /* background-color: #fff; */
    border: none;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.38; */
    letter-spacing: normal;
    color: #333;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  }

  .star-icon {
    color: #F9F9FF;
    object-fit: contain;
    margin-right: 10px;
  }

  .main-banner-content.banner-content-center {
    margin: 0px auto 0;
    text-align: center;
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

.main-banner::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  /* background: #0d1028e3; */
  opacity: .77;
}

.slideshow-banner::before {
  z-index: 1;
}

.slideshow-banner .main-banner-content {
  /* margin-top: 50px; */
}

.main-banner-content {
  background: #000000ba;
  border-radius: 10px;
  padding: 13px 0px 20px 0px;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}

.slideshow-banner .main-banner-content::before {
  display: none;
}

.slideshow-banner .slideshow {
  z-index: -1;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.slideshow-banner .slideshow li span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation: imageAnimation 24s linear infinite 0s;
  animation: imageAnimation 24s linear infinite 0s;
}

/* .slideshow-banner .slideshow li:nth-child(1) span {
  background-image: url("../images/banner.png");
}

.slideshow-banner .slideshow li:nth-child(2) span {
  background-image: url("../images/slideshow-bg2.jpg");
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}

.slideshow-banner .slideshow li:nth-child(3) span {
  background-image: url("../images/slideshow-bg3.jpg");
  -webkit-animation-delay: 12s;
  animation-delay: 12s;
}

.slideshow-banner .slideshow li:nth-child(4) span {
  background-image: url("../images/slideshow-bg4.jpg");
  -webkit-animation-delay: 18s;
  animation-delay: 18s;
} */

.slideshow-banner .event-countdown {
  position: relative;
  right: 0;
  bottom: 0;
  text-align: left;
}

.slideshow-banner .event-countdown #days {
  margin-left: 0;
}

.video-banner {
  overflow: hidden;
}

.video-banner .video-background {
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  z-index: -2;
}



/* .item-bg1 {
    background-image: url("../images/banner.png");
  }
  
  .item-bg2 {
    background-image: url("../images/banner.png");
  }
  
  .item-bg3 {
    background-image: url("../images/banner.png");
  }
  
  .item-bg4 {
    background-image: url("../images/slider-bg2.jpg");
  }
  
  .item-bg5 {
    background-image: url("../images/slider-bg3.jpg");
  }  */


.main-banner-content {
  /* max-width: 850px; */
  position: relative;
  z-index: 1;
}

/* .main-banner-content::before {
  content: '';
  position: absolute;
  top: -75px;
  width: 100%;
  max-width: 380px;
  text-align: center;
  height: 500px;
  left: 18%;
  margin-left: -190px;
  z-index: -1;
  border: 18px solid rgba(104, 89, 222, 0.24);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 60px 40px -40px rgba(0, 0, 0, 0.3);
  box-shadow: 0 60px 40px -40px rgba(0, 0, 0, 0.3);
} */


.main-banner-content span {
  font-weight: 600;
  color: #f43254;
}

.main-banner-content h1 span {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(80%, rgba(0, 0, 0, 0)), color-stop(80%, #FF2D55));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #FF2D55 80%);
  background-size: 100px 1.2em !important;
}

.main-banner-content h1 b:nth-of-type(1) {
  color: #efa506;
}

.main-banner-content h1 b:nth-of-type(2) {
  color: #FF2D55;
}

.main-banner-content h1 b:nth-of-type(3) {
  color: #ffffff;
  -webkit-animation: colorchange 20s;
  animation: colorchange 20s;
}

.main-banner-content h1 b:nth-of-type(4) {
  color: #00acee;
}

.main-banner-content ul {
  padding: 0;
  margin: 25px 0 40px;
  list-style-type: none;
}

.main-banner-content ul li {
  color: #fff;
  margin-right: 20px;
  font-size: 17px;
  display: inline-block;
}

.main-banner-content ul li i {
  margin-right: 3px;
  color: #FF2D55;
}

.main-banner-content ul li:last-child {
  margin-right: 0;
}

.main-banner-content p {
  color: #efa506;
  font-size: 22px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.main-banner-content p span {
  color: #00acee;
}

.main-banner-content .button-box a {
  display: inline-block;
}

.main-banner-content .button-box .video-btn {
  margin-left: 30px;
  color: #fff;
  font-weight: 500;
}

.main-banner-content .button-box .video-btn i {
  width: 50px;
  display: inline-block;
  height: 50px;
  line-height: 51px;
  text-align: center;
  background-color: #00acee;
  border-radius: 50%;
  font-size: 17px;
  margin-right: 8px;
  z-index: 1;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.main-banner-content .button-box .video-btn i::after {
  z-index: -1;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  -webkit-animation: ripple 1.6s ease-out infinite;
  animation: ripple 1.6s ease-out infinite;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  opacity: 0;
  border-radius: 50%;
  background: rgba(51, 102, 204, 0.5);
}

.main-banner-content .button-box .video-btn:hover,
.main-banner-content .button-box .video-btn:focus {
  color: #00acee;
}

.main-banner-content .button-box .video-btn:hover i,
.main-banner-content .button-box .video-btn:focus i {
  background-color: #FF2D55;
  color: #ffffff;
}



.countdown-area {
  padding: 50px 0;
  background: #8dc63f;
}

.countdown-area .event-countdown {
  position: relative;
  right: 0;
  bottom: 0;
  text-align: center;
}

.countdown-area .event-countdown #timer div {
  margin: 0 55px;
}

.countdown-area .event-countdown #timer div#seconds {
  color: #FF2D55;
}

.event-countdown {
  position: absolute;
  right: 10%;
  bottom: 20px;
  text-align: right;
}

.event-countdown #timer div {
  display: inline-block;
  font-size: 55px;
  font-weight: 700;
  color: #ffffff;
  margin: 0 35px;
  position: relative;
}

.event-countdown #timer div#days {
  color: #FF2D55;
}

.event-countdown #timer div#hours {
  color: #00acee;
}

.event-countdown #timer div#hours span {
  right: -50px;
  margin-top: -12px;
}

.event-countdown #timer div#minutes {
  color: #ffffff;
}

.event-countdown #timer div#minutes span {
  right: -55px;
  margin-top: -12px;
}

.event-countdown #timer div#seconds {
  color: #efa506;
}

.event-countdown #timer div#seconds span {
  right: -60px;
  margin-top: -12px;
}

.event-countdown #timer span {
  display: block;
  color: #ffffff;
  margin-top: 0;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  right: -40px;
  top: 50%;
  text-transform: uppercase;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin-top: -10px;
  letter-spacing: 2px;
}

.countdown1 {
  bottom: 15px !important;
}

.countdown1 #timer div {
  font-size: 60px;
}

.countdown1 #timer span {
  display: inline-block;
  font-size: 14px;
  position: initial;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

@-webkit-keyframes ripple {

  0%,
  35% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.8;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}

@keyframes ripple {

  0%,
  35% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.8;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}

.shape1 {
  position: absolute;
  left: 70px;
  bottom: 70px;
  z-index: -1;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
  opacity: .6;
}

.shape2 {
  position: absolute;
  right: 70px;
  bottom: 70px;
  z-index: -1;
  opacity: .6;
}

.shape3 {
  position: absolute;
  left: 70px;
  top: 70px;
  z-index: -1;
  opacity: .6;
}

.shape4 {
  position: absolute;
  right: 100px;
  top: 100px;
  z-index: -1;
  opacity: .6;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes colorchange {
  0% {
    color: #FF2D55;
  }

  25% {
    color: #ffffff;
  }

  50% {
    color: red;
  }

  75% {
    color: #00acee;
  }

  0% {
    color: #efa506;
  }
}

@keyframes colorchange {
  0% {
    color: #FF2D55;
  }

  25% {
    color: #ffffff;
  }

  50% {
    color: red;
  }

  75% {
    color: #00acee;
  }

  0% {
    color: #efa506;
  }
}

.home-slides.owl-theme .owl-nav {
  margin-top: 0;
  line-height: .01;
}

.home-slides.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: 10px;
  top: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #565656;
  border-radius: 0;
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: .2;
  margin-top: -50px;
  padding: 0 !important;
  font-size: 25px;
}

.home-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 10px;
}

.home-slides.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #FF2D55;
  color: #ffffff;
  opacity: 1;
  outline: 0;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

/* ====================================== */
/* Event Near by you*/
/* ====================================== */
@media only screen and (max-width: 768px) {
  .date-text {
    margin: 2px 0 0 3px;
    font-family: Poppins;
    font-size: 9px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    /* line-height: normal; */
    /* letter-spacing: normal; */
    color: #fff;
  }
}

@media (min-width: 768px) and (max-width: 1600px) {
  .date-text {
    margin: 2px 0 0 4px;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    /* line-height: normal; */
    /* letter-spacing: normal; */
    color: #fff;
  }
}

@media only screen and (min-width: 1600px) {
  .date-text {
    margin: 2px 0 0 6px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    /* line-height: normal; */
    /* letter-spacing: normal; */
    color: #fff;
  }
}

@media only screen and (max-width: 768px) {
  .event-heading {
    font-family: 'Poppins';
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 20px;
    /* color: #fff; */
  }

  .card-img-top {
    position: relative;
    height: 230px;
    border-radius: 8px;
    object-fit: cover;
  }

  .card-image-div {
    position: relative;
    transition: all 500ms;
  }

  .card-image-div:hover {
    transform: scale(1.04, 1.04);
  }

  .feature-card-address {
    font-size: 14px;
    color: rgb(66, 66, 66);
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .IntrestP {
    font-size: 13px;
    color: rgb(66, 66, 66);
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    background-color: #ffefef;
    margin-bottom: 0px;
    color: #f43254;
  }

  .language-show {
    font-size: 13px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    background-color: #edeef1;
    margin-bottom: 0px;
    color: #747677;
  }

  .selected-IntrestP {
    display: flex;
    gap: 2px;
    align-items: center;
    font-size: 13px;
    color: rgb(66, 66, 66);
    white-space: nowrap;
    background-color: #f43254;
    margin-bottom: 0px;
    padding: 2px 4px;
    border: none;
    color: white;
  }

  .Card-IntrestP {
    font-size: 12px;
    color: rgb(66, 66, 66);
    white-space: nowrap;
    background-color: #fff3f3;
    margin-bottom: 0px;
    padding: 2px 4px;
    border: none;
    color: #f43254;
    margin: 3px 2px;
  }

  .Card-IntrestD {
    /* display: flex;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden; */
    display: -webkit-box;
    /* text-overflow: ellipsis; */
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #f5274c;
  }

  .main-banner-heading {
    text-align: center;
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 1600px) {
  .event-heading {
    font-family: 'Poppins';
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    /* color: #fff; */
  }

  .card-img-top {
    position: relative;
    height: 320px;
    border-radius: 8px;
    width: 100%;
    object-fit: cover;
  }

  .card-image-div {
    position: relative;
    transition: all 500ms;
  }

  .card-image-div:hover {
    transform: scale(1.04, 1.04);
  }

  .feature-card-address {
    font-size: 13px;
    color: rgb(66, 66, 66);
    /*text-wrap: nowrap;
    white-space: pre-wrap;*/
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .IntrestD {
    flex-wrap: wrap;
    gap: 5px
  }

  .IntrestP {
    font-size: 13px;
    color: rgb(66, 66, 66);
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    background-color: #ffefef;
    margin-bottom: 0px;
    color: #f43254;
  }

  .language-show {
    font-size: 13px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    background-color: #edeef1;
    margin-bottom: 0px;
    color: #747677;
  }

  .selected-IntrestP {
    display: flex;
    gap: 2px;
    align-items: center;
    font-size: 13px;
    color: rgb(66, 66, 66);
    white-space: nowrap;
    background-color: #f43254;
    margin-bottom: 0px;
    padding: 2px 4px;
    border: none;
    color: white;
    font-weight: 500;
  }

  .Card-IntrestP {
    font-size: 13px;
    color: rgb(66, 66, 66);
    white-space: nowrap;
    background-color: #fff5f5;
    margin-bottom: 0px;
    padding: 2px 4px;
    border: none;
    color: #f43254;
    font-weight: 500;
    margin: 4px 3px;
  }

  .Card-IntrestD {
    /* display: flex;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden; */
    display: -webkit-box;
    /* text-overflow: ellipsis; */
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #f5274c;
  }

  .main-banner-heading {
    text-align: center;
    font-weight: 700;
  }
}

@media only screen and (min-width: 1600px) {
  .event-heading {
    font-family: 'Poppins';
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    /* color: #fff; */
    font-size: 30px;
  }

  .card-image-div {
    position: relative;
    transition: all 500ms;
  }

  .card-image-div:hover {
    transform: scale(1.04, 1.04);
    border-radius: 8px;
  }

  .card-img-top {
    position: relative;
    height: 350px;
    border-radius: 8px;
    width: 100%;
    object-fit: cover;
  }

  .feature-card-address {
    font-size: 13px;
    color: rgb(66, 66, 66);
    /* text-wrap: nowrap;
    white-space: pre-wrap; */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .selected-IntrestP {
    display: flex;
    gap: 2px;
    align-items: center;
    font-size: 13px;
    color: rgb(66, 66, 66);
    white-space: nowrap;
    background-color: #f43254;
    margin-bottom: 0px;
    padding: 3px 4px;
    border: none;
    color: white;
    border-radius: 4px;
    font-weight: 500;
  }

  .Card-IntrestP {
    width: fit-content;
    font-size: 12px;
    color: rgb(66, 66, 66);
    white-space: nowrap;
    background-color: #fff2f2;
    margin-bottom: 0px;
    padding: 2px 4px;
    border: none;
    color: #f5274c;
    font-weight: 500;
    margin: 4px 2px;
    text-overflow: clip !important;
  }

  .Card-IntrestD {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #f5274c;
    text-overflow: clip;
    /* Ensure ellipsis is not applied */
    word-break: break-word;
  }

  /* .Card-IntrestD::-webkit-scrollbar { 
    display: none; 
    background: transparent;
  } */

  .IntrestD {
    flex-wrap: wrap;
    gap: 5px
  }

  .IntrestP {
    font-size: 13px;
    color: rgb(66, 66, 66);
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    background-color: #ffefef;
    margin-bottom: 0px;
    color: #f43254;
    padding: 0px 4px;
  }

  .language-show {
    font-size: 13px;
    color: rgb(66, 66, 66);
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    background-color: #edeef1;
    margin-bottom: 0px;
    color: #747677;
    padding: 0px 4px;
  }
  
  .main-banner-heading {
    text-align: center;
    font-weight: 700;
  }
}

.past-event-address {
  font-size: 14px;
  color: rgb(66, 66, 66);
  /* text-wrap: nowrap;
  white-space: pre-wrap; */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.view-all-btn {
  background-color: white;
  border: none;
  color: #f43254;
}


@media only screen and (max-width: 520px) {
  .event-near {
    background-color: #ffffff;
    width: 100%;
    padding: 10px 0px;
    color: #333333;
    /* mix-blend-mode: multiply; */
  }
}

@media only screen and (min-width: 520px) {
  .event-near {
    background-color: #ffffff;
    width: 100%;
    padding: 30px 0px;
    color: #333333;
    /* mix-blend-mode: multiply; */
  }
}

.viewall-btn-p {
  font-family: 'Poppins';
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #f43254;
}


.viewall-btn-p-for-seeAll {
  font-family: 'Poppins';
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #f43254;
}

.even-card-div {
  display: flex;
  justify-content: center;
}

.eventcard {
  position: relative;
  /* color: white; */
  width: 100%;
  max-width: 275px;
}

.seeAll-eventcard {
  height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff5f7;
  border: solid 1px #FF2D55;
}



.rectangle {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0px;
  width: 151px;
  height: 36px;
  padding: 8px 10px 8px 10px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #f43254;
  color: white;
}



.card-heading {
  height: 25px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: #fff; */
  text-transform: capitalize;
  /* text-wrap: nowrap; */
  /* white-space: pre-wrap; */
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


.card-text {
  /* width: 110px; */
  height: 20px;
  margin: 0 0 0 8px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: #fff; */
  color: black;
}

.featured {
  padding-top: 20px;
  padding-bottom: 20px;
}

.featured-heading {
  font-family: 'Poppins';
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.featured-rectangle {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0px;
  width: 100%;
  height: auto;
  padding: 6px 4px 6px 4px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #f43254;
  color: white;
  align-items: center;
}

.feature-card-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: capitalize;
  /* text-wrap: nowrap; */
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.feature-card-text {
  /* height: 25px; */
  /* margin: 0 0 0 8px; */
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  white-space: pre-wrap;
}

/* ========================================== */
/* Event-Banner */
/* ========================================== */
@media only screen and (max-width: 600px) {
  .banner-div {
    width: 100%;
    background-color: #ebebeb;
    padding: 0px 0px;
  }

  .banner-img {
    border-radius: 10px;
    width: 100%;
    height: 140px;
  }

}

@media only screen and (min-width: 600px) {
  .banner-div {
    width: 100%;
    background-color: #ebebeb;
    padding: 30px 0px;
  }

  .banner-img {
    border-radius: 20px;
    width: 100%;
    height: 200px;
  }
}


@media only screen and (min-width: 1200px) {
  .banner-div {
    width: 100%;
    background-color: #ebebeb;
    padding: 30px 0px;
  }

  .banner-img {
    border-radius: 20px;
    width: 100%;
    height: 320px;
  }
}


/* User Testimonials */
/* ============================================== */

@media only screen and (max-width: 600px) {
  .User-test-heading {
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
  }
}


@media only screen and (min-width: 600px) {
  .User-test-heading {
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
  }
}

.member-testimonial {
  padding: 40px 0px;
}

.mtm-card {
  width: 100%;
  height: 315px;
  padding: 16px 10px;
  border-radius: 6px;
  border: solid 1px #e1e6d5;
  background-color: #fff;
  position: relative;
  border-radius: 6px;
}

.mtm-card-home {
  width: 100%;
  height: 275px;
  padding: 10px 10px;
  border-radius: 6px;
  border: solid 1px #e1e6d5;
  background-color: #fff;
  position: relative;
  border-radius: 6px;
}

.mtm-delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  /*background-color: #FF2D55; */
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.mtm-delete-button:hover {
  /* background-color: #d32f2f; */
}

.user-img {
  height: 64px;
}

.user-name-text {
  width: 100%;
  margin: 0 0 4px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: wrap;

}

.mtm-text {
  margin: 4px 6px 0 0;
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4e4949;
}

.mtm-paragraph {
  /* margin: 23px 0 13px; */
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #333;
  height: 100px;
  overflow-y: auto;
}

.mtm-paragraph-home {
  /* margin: 23px 0 13px; */
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #333;
  /* height: 50px; */
  /* overflow-y: auto; */
}

.mtm-paragraph-height {
  /* height: auto; */
  /* max-height: 200px; */
  height: 75px;
  overflow-y: scroll;
}

/* Change scrollbar width, height, and color */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Change scrollbar track color */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Change scrollbar thumb color */
::-webkit-scrollbar-thumb {
  background: #f43256d8;
}

/* Change scrollbar thumb color on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f43254;
}

/* Change scrollbar corner color */
::-webkit-scrollbar-corner {
  background: #f1f1f1;
}



/*================================================
FunFacts CSS
=================================================*/
.funfacts-area {
  position: relative;
  z-index: 1;
  background-image: url("../images/funfacts-bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.funfacts-area::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #0d1028;
  opacity: .88;
}

.single-funfact {
  text-align: center;
}

.single-funfact .icon {
  color: #ffffff;
  font-size: 40px;
}

.single-funfact h3 {
  color: #ffffff;
  font-size: 55px;
  margin-top: 10px;
  margin-bottom: 8px;
}

.single-funfact p {
  color: #ffffff;
  letter-spacing: 1px;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 1;
}

/*funfact-style-two*/
.funFact {
  position: relative;
  padding-left: 60px;
}

.funFact .icon {
  color: #ffffff;
  font-size: 40px;
  position: absolute;
  left: 0;
  top: 0;
}

.funFact h3 {
  color: #ffffff;
  font-size: 55px;
  margin-top: 0;
  margin-bottom: 8px;
}

.funFact p {
  color: #ffffff;
  letter-spacing: 1px;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 1;
}

.col-lg-3:nth-child(1) .single-funfact h3,
.col-lg-3:nth-child(1) .funFact h3 {
  color: #FF2D55;
}

.col-lg-3:nth-child(2) .single-funfact h3,
.col-lg-3:nth-child(2) .funFact h3 {
  color: #00acee;
}

.col-lg-3:nth-child(3) .single-funfact h3,
.col-lg-3:nth-child(3) .funFact h3 {
  color: #efa506;
}

.col-lg-3:nth-child(4) .single-funfact h3,
.col-lg-3:nth-child(4) .funFact h3 {
  color: #8dc63f;
}




/* =================================================== */
/*WhySponsorRingUs */
/* ================================================== */

.SponsorRingUs {
  margin-top: 2px;
  /* padding-bottom: 10px; */
  padding-top: 20px;
  background-color: #ffffff;
  color: black
}

.Why-SponsorRingUs {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: black;
}

.Why-SponsorRingUs .Why-SponsorRingUs-text-1 {
  color: #f43254;
}

.Lorem-ipsum-dolor-si {
  /* width: 1141px; */
  margin: 16px 14px 40px 15.3px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  text-align: center;
  color: black;
}

.Event-Planners-div {
  width: 390px;
  height: 265px;
  margin: 0 0 0 0.3px;
  padding: 0 174px 201px 152px;
  background-color: rgba(216, 216, 216, 0);
}

.EventPlannersCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin: 0 0 0 0.3px;

  background-color: rgba(216, 216, 216, 0);
}

.event-planners-head {
  margin-top: 14px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
}

.cards {
  margin-top: 40px;
}

.rights {
  padding: 0 0 27px 0px;
}

.SeamlessPlatformTh {
  margin: 12px 0 0 0.1px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}

@media only screen and (max-width: 600px) {
  .ringus-startbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 50px;
    margin: 10px 0px 0px;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
    background-color: #f43254;
  }
}

@media only screen and (min-width: 600px) {
  .ringus-startbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 50px;
    margin: 50px 0px 0px;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
    background-color: #f43254;
  }
}

.arrow-img {
  height: 15px;
}

.eventposter-img {
  width: 100%;
  height: 250px;
  border-radius: 6px;
  object-fit: cover;
}

.bottom-banner {
  padding: 30px 0px 0px 0px;
}

.banner-cards .banner-card {
  transition: all 500ms;
  cursor: pointer;
}

.banner-cards .banner-card:hover {
  transform: scale(1.02, 1.02);
}

.banner-cards:hover .banner-card:not(:hover) {
  filter: blur(2px);
  transform: scale(1.0, 1.0);
}

/* ----------- */
.become-sponsor {
  border-radius: 6px;
  border: none;
  padding: 10px 20px;
  background-color: #f43254;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
}

.parter_slide {
  /* padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* =================================== */
/* social feeds */
/* =================================== */
@media only screen and (max-width: 600px) {
  .socialfeeds-img-div {
    margin-top: 40px;
    margin: 0px 20px;
  }

  .sf-rounded-ringus-img {
    height: auto;
    background-image: url("../images/ringus-rounded-background.svg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .sf-rounded-ringus-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    width: 154px;
    height: 154px;
    /* padding: 40px 29px 61px 29px; */
    box-shadow: 0 0 21px 0 0 0 40px 0 rgb(227 43 43 / 55%);
    ;
    background-color: #fffefe;
    border-radius: 100%;
  }

  .sf-sponsor-img {
    height: 16px;
  }


  .socialfeeds-google-div {
    /* margin-top: 176px; */
    /* margin-left: 57px; */
    height: 74px;
    width: 74px;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }


  .socialfeeds-twitter-div {
    margin-top: 74px;
    height: 74px;
    width: 74px;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }

  .socialfeeds-instagram-div {
    /* margin-top: 70px; */
    height: 74px;
    width: 74px;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }

  .socialfeeds-linkedin-div {
    margin-top: 6px;
    height: 74px;
    width: 74px;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }

  .sf-facebook-social-div {
    margin-left: 80px;
    display: flex;
    gap: 180px;

  }
}

@media (max-width: 992px) and (min-width: 600px) {
  /* .socialfeeds-img-div{
      margin-top: 40px;
      margin: 0px 20px;
      height: 400px;
      background-image: url('../images/line.png');
      background-size: cover;
      object-fit: cover;
      background-repeat: no-repeat;
    } */

  .sf-rounded-ringus-img {
    height: 320px;
    width: 310px;
    background-image: url("../images/ringus-rounded-background.svg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .sf-rounded-ringus-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    width: 200px;
    height: 200px;
    padding: 61px 29px 61px 29px;
    box-shadow: 0 0 21px 0 0 0 40px 0 rgb(227 43 43 / 55%);
    ;
    background-color: #fffdfd;
    border-radius: 100%;
  }

  .sf-sponsor-img {}

  .sf-rounded-ringus-main {
    display: flex;
    justify-content: center;
    /* align-items: center;   */
  }

  .socialfeeds-google-div {

    height: 74px;
    width: 74px;
    padding: 18px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }


  .socialfeeds-twitter-div {
    margin-top: 50px;
    height: 74px;
    width: 74px;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }

  .socialfeeds-instagram-div {
    margin-top: 10px;
    height: 74px;
    width: 74px;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }

  .socialfeeds-linkedin-div {
    margin-top: 60px;
    height: 74px;
    width: 74px;
    padding: 12px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }



  .sf-facebook-social-div {
    margin-left: 80px;
    display: flex;
    gap: 180px;

  }
}


@media only screen and (min-width: 992px) {
  .socialfeeds-img-div {
    width: 72%;
    margin-top: 40px;
    margin: 0px 20px;
    height: 260px;
    background-image: url('../images/line.png');
    background-size: 100% 260px;
    object-fit: cover;
    background-repeat: no-repeat;
  }

  .sf-rounded-ringus-img {
    height: 220px;
    width: 220px;
    /* background-image: url("../images/ringus-rounded-background.svg"); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .sf-rounded-ringus-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 170px;
    height: 170px;
    padding: 61px 20px 61px 20px;
    /* box-shadow:  0 0 40px 0 rgba(209, 51, 51, 0.55); */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;
    border-radius: 100%;
    transition: all 1s;
  }

  .sf-rounded-ringus-div:hover {
    background-color: #ffe4e4;
    height: 170px;
  }

  .sf-rounded-ringus-main {
    display: flex;
    justify-content: center;
    /* align-items: center;   */
  }

  .socialfeeds-google-div {
    margin-top: 60px;
    height: 74px;
    width: 74px;
    padding: 18px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }


  .socialfeeds-twitter-div {
    height: 74px;
    width: 74px;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }

  .socialfeeds-instagram-div {
    margin-top: 6px;
    height: 74px;
    width: 74px;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }

  .socialfeeds-linkedin-div {
    margin-top: 60px;
    height: 74px;
    width: 74px;
    padding: 12px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 100%;
  }

  .sf-facebook-social-div {
    margin-left: 80px;
    display: flex;
    gap: 180px;

  }
}

.socialfeeds-div {
  /* height: 666px; */
  padding: 20px 0px;
  background-color: #fafafa;
}

.socialfeeds-text-div {
  text-align: center;
}

.socialfeeds-h2 {
  font-family: 'Poppins';
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333; */
}

.socialfeeds-p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  text-align: center;
  color: #333;
}





/* ==================================================== */
.social-media-div {
  margin-top: 20px;
}

/* .social-m-head {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
} */

.sf-linkedin-google-div {
  display: flex;
  gap: 140px;
}

.pastevent-img1 {
  width: 98%;
  height: 180px;
  border-top-left-radius: 0px;
  margin: 2px;
  object-fit: cover;
  border-radius: 3px;
}

/* .pastevent-img2 {
  width: 310px;
  height: 180px;
  border-top-right-radius: 8px;
  margin: 2px;
}

.pastevent-img3 {
  width: 310px;
  height: 180px;
  border-bottom-left-radius: 8px;
  margin: 2px;
}

.pastevent-img4 {
  width: 310px;
  height: 180px;
  border-bottom-right-radius: 8px;
  margin: 2px;
} */

.showall-photo-btn {
  position: absolute;
  top: 2px;
  right: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  /* opacity: 0.9; */
  border-radius: 0px 10px 0px 10px;
  /* box-shadow: 0 0 5px 0 rgba(153, 153, 153, 0.17), 0 0 1px 0 rgba(0, 0, 0, 0.1); */
  background-color: #383838c7;
  font-family: "Poppins";
  font-size: 14px;
  color: #fff;
  border: none;
}

.pastevent-center-img {
  position: absolute;
  z-index: 50;
  top: 27%;
  left: 34%;
  width: 206px;
  height: 145px;
  border-radius: 4px;
  border: solid 2px #fff;
  object-fit: cover;
}

.pst_evt {
  position: relative;
  cursor: pointer;
}

.pastevent-card-title {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  /* width: 280px  ; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pastevent-hr-line {
  height: 20px;
  width: 1px;
  background-color: #e9e9e9;
}

.mainbanner-loader-div {
  padding-top: 10px;
}


/*================================================
Partner CSS
=================================================*/
.partner-area {
  overflow: hidden;
  background-color: #fafafa;
}

.section-title {
  position: relative;
  margin-bottom: 20px;
  z-index: 1;
  /* display: flex;
  justify-content: end; */
}

.partnerposter-img {
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  border: 1px solid #f43254;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.section-title span {
  color: #ff2d55;
  letter-spacing: 1px;
  font-size: 16px;
  display: block;
}

@media only screen and (max-width: 600px) {
  .section-title h2 {
    font-size: 24px;
    font-family: 'Poppins';
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .interests-edit-btn {
    padding: 10px 10px;
    background-color: #f43254;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    border-radius: 4px;
  }
}

@media only screen and (min-width: 600px) {
  .section-title h2 {
    font-size: 30px;
    font-family: 'Poppins';
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .interests-edit-btn {
    padding: 10px 30px;
    background-color: #f43254;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    border-radius: 4px;
  }
}




.partner-area .partner-title {
  text-align: center;
  margin-bottom: 50px;
}

.partner-area .partner-title h2 {
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 0;
  color: #ff2d55;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}

.partner-area .partner-title.gold-sponsor h3 {
  color: #8dc63f;
}

.partner-area .partner-item {
  text-align: center;
}

.partner-area .partner-item a {
  position: relative;
}

.partner-area .partner-item a img {
  width: unset !important;
  display: inline-block !important;
}

.partner-area .partner-item a img:last-child {
  position: absolute;
  left: 0;
  top: 30px;
  right: 0;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.partner-area .partner-item:hover a img:last-child {
  opacity: 1;
  visibility: visible;
  top: 0;
}

.partner-area .border {
  border: 0.3px solid #eeeeee !important;
  margin-top: 40px;
  margin-bottom: 40px;
}

.event_h5 {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  text-align: center;
  background-color: #f43254;
  padding: 8px 8px;
  border-radius: 5px;
}

.info-popup {
  position: absolute;
  z-index: 30;
  padding: 16px;
  top: 36px;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  width: 300px;
  border-radius: 4px;
  background-color: #fff5f7;
  color: rgb(0, 0, 0);
  font-size: 13px;
  box-shadow: 0 4px 8px 0 rgba(151, 151, 151, 0.2), 0 6px 20px 0 rgba(156, 156, 156, 0.19);
}

.info-popup-text {
  color: #f43254;
  font-size: 14px;
}

.extra-text-span {
  font-size: 16px
}

.feature-card-cutoff-p {
  font-size: 13px;
  color: rgb(66, 66, 66);
  /* text-wrap: nowrap;
  white-space: pre-wrap;
  /* font-weight: 500; */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

ul {
  list-style: none;
}

.example-2 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 0px;
  /* margin-top: 50px; */
}

.example-2 .icon-content {
  margin: 0 10px;
  position: relative;
}

.example-2 .icon-content .tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}

.example-2 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -50px;
}

.example-2 .icon-content a {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  color: #4d4d4d;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  box-shadow: rgba(133, 133, 133, 0.2) 0px 2px 8px 0px;
}

.example-2 .icon-content a:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}

.example-2 .icon-content a svg {
  position: relative;
  z-index: 1;
  width: 30px;
  height: 30px;
}

.example-2 .icon-content a:hover {
  color: white;
}

.example-2 .icon-content a .filled {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}

.example-2 .icon-content a:hover .filled {
  height: 100%;
}

.example-2 .icon-content a[data-social="linkedin"] .filled,
.example-2 .icon-content a[data-social="linkedin"]~.tooltip {
  background-color: #0274b3;
}

.example-2 .icon-content a[data-social="facebook"] .filled,
.example-2 .icon-content a[data-social="facebook"]~.tooltip {
  background-color: #11a1fa;
}

.example-2 .icon-content a[data-social="twitter"] .filled,
.example-2 .icon-content a[data-social="twitter"]~.tooltip {
  background-color: #00111a;
}


.example-2 .icon-content a[data-social="instagram"] .filled,
.example-2 .icon-content a[data-social="instagram"]~.tooltip {
  background: linear-gradient(45deg,
      #405de6,
      #5b51db,
      #b33ab4,
      #c135b4,
      #e1306c,
      #fd1f1f);
}

.example-2 .icon-content a[data-social="youtube"] .filled,
.example-2 .icon-content a[data-social="youtube"]~.tooltip {
  background-color: #ff0000;
}

.social-facebook-div {
  margin-top: 50px;
}

.main-banner-heading-div {
  background-color: white;
  padding: 10px 20px;
  border-radius: 8px;
  width: fit-content;
}

.main-banner-bottons {
  position: absolute;
  bottom: 20px;
}

/* @media only screen and (max-width: 1600px) {
  .eventcuttoffdatemodify-mdl {
    width: 40%;
    height: auto;
    max-height: 680px;
    padding: 25px;
    background-color: white !important;
    border-radius: 8px;
  }
} */

@media only screen and (max-width: 720px) {
  .eventcuttoffdatemodify-mdl {
    width: 80%;
    height: auto;
    max-height: 680px;
    padding: 25px;
    background-color: white !important;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 720px) {
  .eventcuttoffdatemodify-mdl {
    width: 40%;
    height: auto;
    max-height: 680px;
    padding: 25px;
    background-color: white !important;
    border-radius: 8px;
  }
}

.eventcuttoffdatemodify-content {
  height: auto;
  max-height: 580px;
  overflow-y: auto;
}

.change-cuttoffDate-btn{
 border:none ;
 background-color:#f43254;
 color: white;
 border-radius: 4px;
 font-size: 12px;
 font-weight: 500;
 padding: 4px 6px;
 transition: all 0.3s;
}

.change-cuttoffDate-btn:hover{
  background-color:#d41d3e;
}