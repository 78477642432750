.coupons-div{
  background-color: white;
  border: solid 1px rgb(235, 235, 235);
  padding: 10px 20px;
  border-radius: 4px;
}


.coupon-head-text{
   font-weight: 600; 
   font-size: 18px;
}

.coupon-form{
    width: 80%;
}

.coupon-text{
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: black;
    font-weight: 500;
}

.apply-coupon-btn{
    background-color: #f43254;
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    text-wrap: nowrap;
    padding: 15px 25px;
    font-size: 13px;
  }

  .applied-coupon-btn{
    background-color: rgb(0, 124, 0);
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    text-wrap: nowrap;
    padding: 15px 15px;
    font-size: 13px;
  }

  .price-detail-div{
    width: 100%;
   border: solid 1px rgb(240, 240, 240);
   padding: 10px 15px;
   box-shadow: rgba(170, 175, 180, 0.2) 0px 8px 24px;
   border-radius: 6px;
   background-color: white;
  }

  .coupon-disc-head-text{
    font-weight: 500;
    color: black;
    font-size: 16px;
    margin: 0
  }

  .coupon-code-text{
    font-weight: 500;
    color: #f43254;
    margin: 0
  }

  .coupon-code-div{
    border: dashed 1px #f43254;
    border-radius: 6px;
    padding: 4px 10px;
    width: fit-content;
  }

  .price-coupon-text{
    font-weight: 500;
    color: rgb(39, 39, 39); 
    padding: 0;
    margin: 0;
  }

  .total-price-text{
    font-weight: 600;
    color: rgb(20, 20, 20); 
    padding: 0;
    margin: 0;
  }

  .coupon-price-text{
    font-weight: 500;
    color:red;
    padding: 0;
    margin: 0;
  }

  .coupon-disc-text{
    font-weight: 400;
    color:green;
    padding: 0;
    margin: 0; 
  }

  .pay-now-btn{
    background-color: #f43254;
    color: white;
    border: none;
    padding: 10px 30px;
    font-weight: 500;
    border-radius: 4px;  
}

.coupon-card-div{
  border: solid 1px #f12f533a;
  padding: 15px 10px;
  border-radius: 10px;
  background-color:#fff5f7 ;
}

.copy-code-btn{
  border: none;
  background-color: transparent;
  color: #f43254;
  font-weight: 500;
}

.coupon-name-text{
  color:#303030;
  font-weight: 700;
}

.query-send-btn {
  font-family: inherit;
  font-size: 15px;
  font-weight: 500;
  /* background: linear-gradient(to bottom, #4dc7d9 0%,#66a6ff 100%); */
  background-color: #f43254;
  color: white;
  /* padding: 0.8em 1.2em; */
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 25px;
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); */
  transition: all 0.3s;
}

.query-send-btn:hover {
  transform: translateY(-3px);
  /* box-shadow: 0px 8px 15px rgba(219, 20, 20, 0.3);  */
}

.query-send-btn:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.query-send-btn span {
  display: block;
  margin-left: 0.4em;
  transition: all 0.3s;
}

.query-send-btn svg {
  width: 18px;
  height: 18px;
  fill: white;
  transition: all 0.3s;
}

.query-send-btn .svg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  /* margin-right: 0.5em; */
  transition: all 0.3s;
}

.query-send-btn:hover .svg-wrapper {
  background-color: rgba(255, 255, 255, 0.5);
}

.query-send-btn:hover svg {
  transform: rotate(45deg);
}
