.reject-popup {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    z-index: 200;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 600px) {
    .reject-popup-content {
        background-color: white;
        padding: 25px;
        border: 1px solid #888888;
        width: 100%;
        font-weight: bolder;
        height: auto;
        max-height: 600px;
        margin: 0px 10px;
        overflow-y: scroll;
        border-radius: 8px;
    }
}

@media (min-width: 600px) and (max-width: 1200px) {
    .reject-popup-content {
        background-color: white;
        padding: 25px;
        border: 1px solid #888888;
        width: 60%;
        font-weight: bolder;
        height: 650px;
        overflow-y: scroll;
        border-radius: 8px;
    }
}

@media only screen and (min-width: 1200px) {
    .reject-popup-content {
        background-color: white;
        padding: 25px;
        border: 1px solid #888888;
        width: 35%;
        font-weight: bolder;
        height: 650px;
        overflow-y: scroll;
        border-radius: 8px;
    }
}

.reject-close-btn {
    margin-left: 0px;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    background-color: #dc3545;
    color: white;
    cursor: pointer;
}

.reject-save-btn {
    margin-left: 0px;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    background-color: #dc3545;
    color: white;
    cursor: pointer;
}

.reject-p {
    font-weight: normal;
    font-size: 13px;
    word-wrap: break-word; /* Ensures long words break to the next line */
    white-space: normal; 
}

.rejected-form-p{
  font-size: 14px;
  font-weight: 700;
  color: rgb(88, 88, 88);
}