.btn-scrollBtn {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 40px;
    right: 40px;
    background-color: #f3173fb0;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s, transform 0.3s;
    z-index: 999;
}

.btn-scrollBtn:hover {
    background-color: #f30a35;
    border: solid 2px #da4963;
    transition: 0.5s;
}

.mge-btn-scroll1 {
    display: inline;
}

.mge-btn-scroll2 {
    display: none;
}