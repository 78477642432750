@media only screen and (max-width: 767px) {
  .elkevent-nav {
    padding-top: 5px;
  }
  .elkevent-nav .navbar-brand img {
    max-width: 100px;
  }
  .ptb-120 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .section-title {
    margin-bottom: 20px;
  }
  .section-title span {
    font-size: 13px;
  }
  .section-title h2 {
    font-size: 25px;
  }
  .section-title .btn {
    display: none;
  }
  .section-title .bg-title {
    font-size: 60px;
    left: auto;
    right: 0;
    -webkit-transform: unset !important;
            transform: unset !important;
  }
  p {
    font-size: 14px;
  }
  .btn {
    font-size: 14px;
    padding: 13px 24px;
  }
  .preloader .loader {
    margin: -80px 0 0 -55px;
  }
  .main-banner {
    height: 100%;
    padding-top: 150px;
    padding-bottom:40px;
  }
  .main-banner-content {
    text-align: center;
  }
  .main-banner-content::before {
    display: none;
  }
  .main-banner-content h1 {
    font-size: 28px;
    line-height: 1.5;
    font-weight: 800;
  }
  .main-banner-content h1 br {
    display: none;
  }
  .main-banner-content p {
    font-size: 15px;
  }
  .main-banner-content ul {
    margin: 20px 0 30px;
  }
  .main-banner-content ul li {
    font-size: 15px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .main-banner-content .button-box .video-btn {
    margin-left: 15px;
    margin-top: 25px;
  }
  .main-banner-content.banner-content-center {
    margin: 0 auto 0;
    text-align: center;
  }
  .event-countdown {
    right: 0;
    left: 0;
    margin: 50px auto 0;
    text-align: center;
    position: inherit;
  }
  .event-countdown #timer div {
    font-size: 30px;
    margin: 0 20px 0 0;
    position: relative;
  }
  .event-countdown #timer div:last-child {
    margin-right: 0;
  }
  .event-countdown #timer div:first-child {
    margin-left: 0;
  }
  .event-countdown #timer div span {
    margin-top: 0;
    font-size: 12px;
    position: relative;
    right: 0;
    top: 0;
    -webkit-transform: unset;
            transform: unset;
    margin-top: -5px;
    letter-spacing: 1px;
  }
  .event-countdown #timer div#hours span {
    right: 0;
    margin-top: -5px;
  }
  .event-countdown #timer div#minutes span {
    right: 0;
    margin-top: -5px;
  }
  .event-countdown #timer div#seconds span {
    right: 0;
    margin-top: -5px;
  }
  .shape1, .shape2, .shape3, .shape4 {
    display: none;
  }
  .slideshow-banner .event-countdown {
    text-align: center;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 25px;
  }
  .slideshow-banner .event-countdown #timer div {
    font-size: 30px;
    margin: 0 22px 0 0;
  }
  .slideshow-banner .event-countdown #timer div:last-child {
    margin-right: 0;
  }
  .home-slides.owl-theme .owl-nav [class*="owl-"] {
    left: 10px;
    width: 40px;
    height: 40px;
    top: auto;
    line-height: 40px;
    margin-top: 0;
    font-size: 20px;
    bottom: 8px;
  }
  .about-content span {
    font-size: 13px;
  }
  .about-content h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }
  .about-content h6 {
    font-size: 15px;
  }
  .about-image {
    margin-top: 40px;
  }
  .about-image .about-img1 {
    right: 0;
    top: 0;
    width: 100%;
  }
  .about-image .about-img2 {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-top: 20px;
  }
  .about-image .shape-img {
    top: 27%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .about-image .btn {
    bottom: 0;
    right: 15px;
    -webkit-transform: unset !important;
            transform: unset !important;
  }
  .about-area-two .about-content .signature {
    margin-bottom: 0;
  }
  .about-area-three .about-content .signature {
    margin-bottom: 0;
  }
  .about-area-three .about-image {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .about-area-three .about-image .about-img1 {
    margin-top: 0;
  }
  .about-area-three .about-image .btn {
    left: auto;
    bottom: 30px;
    right: 15px;
  }
  .countdown-area .event-countdown {
    margin: 0;
  }
  .countdown-area .event-countdown #timer {
    overflow: hidden;
    margin: 0 -10px;
    text-align: center;
  }
  .countdown-area .event-countdown #timer div {
    margin: 0;
    text-align: center;
    padding: 0 10px;
  }
  .why-choose-us {
    padding-top: 60px;
    padding-bottom: 35px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .why-choose-us::before {
    opacity: .80;
  }
  .single-box {
    height: 100%;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    border: none !important;
  }
  .single-box .d-table {
    height: auto;
    display: block;
  }
  .single-box .d-table-cell {
    display: block;
  }
  .single-box .content {
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
  .single-box .content .btn {
    opacity: 1;
    visibility: visible;
    margin-top: 20px;
  }
  .single-box .content .icon {
    font-size: 35px;
  }
  .single-box .content h3 {
    font-size: 20px;
  }
  .single-box:hover .content, .single-box:focus .content {
    bottom: 0;
  }
  .single-box:hover .content p, .single-box:focus .content p {
    margin-bottom: 0;
  }
  .single-box::before {
    display: none;
  }
  .why-choose-content {
    padding: 35px 25px;
  }
  .why-choose-content h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .why-choose-content span {
    margin: 0 auto 0;
    font-size: 60px;
  }
  .single-speakers .speakers-content {
    bottom: 20px;
    left: 20px;
    text-align: center;
    width: 100%;
  }
  .single-speakers .speakers-content h3 {
    font-size: 18px;
    margin-bottom: 0;
  }
  .single-speakers .speakers-content span {
    font-size: 14px;
  }
  .single-speakers:hover .speakers-content, .single-speakers:focus .speakers-content {
    bottom: 20px;
  }
  .single-speakers ul {
    padding: 10px;
  }
  .single-speakers ul li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
  .elkevent-single-speakers .speakers-content h3 {
    font-size: 18px;
  }
  .elkevent-single-speakers .speakers-content span {
    font-size: 14px;
  }
  .speakers-area-two {
    padding-bottom: 30px;
  }
  .single-speakers-box .speakers-content h3 {
    font-size: 20px;
  }
  .single-speakers-box .speakers-content span {
    font-size: 13px;
  }
  .tabs {
    display: block;
    margin-bottom: 0;
  }
  .tabs li::before {
    display: none;
  }
  .tabs li a {
    font-size: 17px;
    padding: 20px 10px;
  }
  .tabs li a span {
    font-size: 12px;
  }
  .tab_content {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .tab_content .tabs_item .accordion .accordion-item {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
  }
  .tab_content .tabs_item .accordion .accordion-item .accordion-title {
    display: block;
  }
  .tab_content .tabs_item .accordion .accordion-item .accordion-title .author {
    margin-right: auto;
    margin-bottom: 15px;
    margin-left: auto;
    position: relative;
    top: 0;
    -webkit-transform: unset;
            transform: unset;
    right: 0;
  }
  .tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info h3 {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 28px;
  }
  .tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li {
    margin-right: 0;
    margin-bottom: 10px;
    display: block;
    font-size: 14px;
  }
  .tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li:last-child {
    margin-bottom: 0;
  }
  .tab_content .tabs_item .accordion .accordion-item .accordion-content p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .tab_content .tabs_item .accordion .accordion-item .accordion-content .row .text-right {
    text-align: left !important;
    margin-top: 15px;
  }
  .schedule-slides-item .single-schedule {
    padding: 0 0 0 0;
  }
  .schedule-slides-item .single-schedule .schedule-date {
    font-size: 20px;
    padding: 25px;
    position: relative;
    width: auto;
  }
  .schedule-slides-item .single-schedule .schedule-date span {
    font-size: 14px;
  }
  .schedule-slides-item .single-schedule .schedule-content {
    display: block;
    text-align: center;
  }
  .schedule-slides-item .single-schedule .schedule-content .author {
    margin-right: auto;
    margin-bottom: 15px;
    margin-left: auto;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info h3 {
    font-size: 17px;
    margin-bottom: 12px;
    line-height: 28px;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li {
    margin-right: 0;
    margin-bottom: 10px;
    display: block;
    font-size: 14px;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li:last-child {
    margin-bottom: 0;
  }
  .schedule-style-three {
    padding-bottom: 30px;
  }
  .single-schedule-item .schedule-date {
    font-size: 20px;
    padding: 25px;
    position: relative;
    width: auto;
  }
  .single-schedule-item .schedule-date span {
    font-size: 14px;
  }
  .single-schedule-item .schedule-item-wrapper .schedule-content {
    display: block;
    text-align: center;
  }
  .single-schedule-item .schedule-item-wrapper .schedule-content .author {
    margin-right: auto;
    margin-bottom: 15px;
    margin-left: auto;
  }
  .single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info h3 {
    font-size: 18px;
    margin-bottom: 12px;
    line-height: 28px;
  }
  .single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li {
    margin-right: 0;
    margin-bottom: 10px;
    display: block;
    font-size: 14px;
  }
  .single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li:last-child {
    margin-bottom: 0;
  }
  .btn-box {
    margin-top: 20px;
  }
  .btn-box .btn {
    margin: 20px 10px 0;
  }
  .schedule-slides.owl-theme .owl-nav {
    margin-top: 30px;
  }
  .schedule-slides.owl-theme .owl-nav [class*="owl-"] {
    font-size: 25px;
    position: relative;
    left: 0;
    top: 0;
    right: 0 !important;
  }
  .schedule-style-four .col-lg-8 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .schedule-style-four .col-lg-4 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .funfacts-area {
    padding-bottom: 30px;
  }
  .single-funfact {
    margin-bottom: 30px;
  }
  .single-funfact .icon {
    font-size: 30px;
  }
  .single-funfact h3 {
    font-size: 35px;
    margin-top: 8px;
  }
  .single-funfact p {
    font-size: 13px;
  }
  .funFact {
    text-align: center;
    margin-bottom: 35px;
    padding-left: 0;
  }
  .funFact .icon {
    font-size: 30px;
    position: relative;
  }
  .funFact h3 {
    font-size: 35px;
    margin-top: 8px;
  }
  .funFact p {
    font-size: 14px;
  }
  .cta-area {
    text-align: center;
  }
  .cta-area span {
    font-size: 15px;
  }
  .cta-area h3 {
    font-size: 24px;
  }
  .cta-area .text-right {
    text-align: center !important;
    margin-top: 25px;
  }
  .pricing-area {
    padding-bottom: 30px;
  }
  .pricing-table-box {
    margin-bottom: 30px;
  }
  .pricing-table-box .title {
    font-size: 17px;
  }
  .pricing-table-box .price-value {
    font-size: 35px;
  }
  .pricing-table-box .price-value sup {
    font-size: 20px;
    top: -18px;
  }
  .pricing-table-box .pricing-content li {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .pricing-table-box .pricing-content li:last-child {
    margin-bottom: 0;
  }
  .pricing-plan {
    margin-bottom: 30px;
  }
  .pricing-plan h3 {
    font-size: 20px;
  }
  .pricing-plan h3 span {
    height: 130px;
    /* width: 130px; */
    line-height: 135px;
    font-size: 25px;
  }
  .pricing-plan .pricing-content li {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .pricing-plan .pricing-content li:last-child {
    margin-bottom: 0;
  }
  .partner-area .section-title {
    text-align: center;
  }
  .partner-area .section-title .bar {
    display: none;
  }
  .partner-area .partner-title {
    margin-bottom: 40px;
    -webkit-transform: unset !important;
            transform: unset !important;
  }
  .partner-area .partner-title h3 {
    font-size: 18px;
    font-weight: 600;
  }
  .single-blog-post .blog-post-content {
    padding: 20px;
  }
  .single-blog-post .blog-post-content .date {
    font-size: 13px;
  }
  .single-blog-post .blog-post-content h3 {
    line-height: 28px;
    font-size: 18px;
  }
  .blog-section {
    padding-bottom: 30px;
  }
  .single-blog-card .post-tag {
    top: 15px;
  }
  .single-blog-card .blog-post-content {
    padding: 0 20px 20px;
  }
  .single-blog-card .blog-post-content p, .single-blog-card .blog-post-content .read-more-btn {
    display: none;
  }
  .single-blog-card .blog-post-content h3 {
    font-size: 18px;
    margin-bottom: 0;
  }
  .single-blog-card:hover .post-tag, .single-blog-card:focus .post-tag {
    top: 15px;
  }
  .buy-tickets-area.ptb-120 {
    padding-bottom: 140px;
  }
  .subscribe-area::before {
    height: 74%;
  }
  .subscribe-area .subscribe-inner {
    padding: 20px;
  }
  .subscribe-area .subscribe-inner span {
    font-size: 13px;
  }
  .subscribe-area .subscribe-inner h2 {
    font-size: 25px;
    line-height: 36px;
  }
  .subscribe-area .subscribe-inner .newsletter-form .form-control {
    height: 50px;
  }
  .subscribe-area .subscribe-inner .newsletter-form .btn {
    position: relative;
    height: 54px;
    display: block;
    width: 100%;
    margin-top: 10px;
  }
  .page-title-area {
    padding-top: 160px;
    padding-bottom: 100px;
  }
  .page-title-area h1 {
    font-size: 28px;
  }
  .page-title-area span {
    margin-top: 10px;
    font-size: 15px;
  }
  .page-title-area ul li {
    font-size: 15px;
  }
  


  .login-area .login-form p {
    /* text-align: center; */
  }
  .login-area .login-form p a.pull-right {
    float: unset;
    margin-top: 5px;
  }
  .login-area .login-form p a.pull-left {
    float: unset;
  }
  .signup-area {
    height: 100%;
    /* padding: 120px 15px 120px; */
  }
  .signup-area .signup-form h3 {
    font-size: 20px;
  }
  .coming-soon {
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .coming-soon .coming-soon-content h1 {
    font-size: 30px;
    line-height: 45px;
  }
  .coming-soon .coming-soon-content #timer div {
    font-size: 40px;
    width: 120px;
    height: 120px;
    padding-top: 27px;
    line-height: 39px;
    margin: 0 5px 15px;
  }
  .coming-soon .coming-soon-content form button {
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    border-radius: 30px;
    margin-top: 10px;
    padding: 13px 0;
  }
  .error-area h1 {
    font-size: 110px;
    line-height: 85px;
  }
  .error-area h3 {
    margin: 30px 0 15px;
    font-size: 20px;
  }
  .faq-accordion {
    -webkit-box-shadow: unset;
            box-shadow: unset;
    padding: 0;
  }
  .faq-accordion .accordion .accordion-title {
    padding: 10px 10px 10px 50px;
    font-size: 14px;
  }
  .faq-contact {
    -webkit-box-shadow: unset;
            box-shadow: unset;
    padding: 0;
  }
  .faq-contact h3 {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .sidebar {
    margin-top: 50px;
  }
  .sidebar .widget .widget-title {
    font-size: 18px;
  }
  .sidebar .widget.widget_recent_entries ul li h5 {
    font-size: 14px;
  }
  .blog-details h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .blog-details blockquote, .blog-details .blockquote {
    padding: 88px 20px 20px 20px;
    border-width: 3px;
  }
  .blog-details blockquote::before, .blog-details .blockquote::before {
    left: 20px;
    top: 20%;
  }
  .blog-details blockquote p, .blog-details .blockquote p {
    font-size: 15px;
  }
  .post-tag-media {
    text-align: center;
  }
  .post-tag-media ul.social-share {
    text-align: center;
    margin-top: 15px;
  }
  .comments-area .comments-title, .comments-area .comment-reply-title {
    font-size: 20px;
  }
  .comments-area ol li .comment-body, .comments-area ul li .comment-body {
    padding: 20px 15px;
  }
  .comments-area ol li .comment-body .comment-meta .comment-author .fn, .comments-area ul li .comment-body .comment-meta .comment-author .fn {
    font-size: 16px;
  }
  .comments-area ol li .comment-body .reply, .comments-area ul li .comment-body .reply {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 20px;
  }
  .contact-box {
    margin-bottom: 30px;
  }
  .contact-box h4 {
    font-size: 18px;
  }
  .contact-form {
    margin-top: 0;
  }
  .leave-your-message h3 {
    font-size: 18px;
  }
  #contactForm {
    margin-top: 30px;
    padding: 20px 15px;
  }
  .footer-area {
    padding-top: 60px;
  }
  .single-footer-widget {
    margin-bottom: 30px;
  }
  .single-footer-widget h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .single-footer-widget span {
    font-size: 14px;
  }
  .single-footer-widget a {
    font-size: 14px;
  }
  .copyright-area {
    margin-top: 20px;
  }
  .copyright-area ul {
    margin: 25px 0 18px;
  }
  .countdown1 {
    position: initial;
    text-align: center !important;
  }
  .countdown1 #timer div {
    font-size: 30px;
    margin: 0 20px 0 0;
  }
  .blog-slides.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0;
  }
  .buy-tickets {
    text-align: center;
  }
  .buy-tickets .buy-ticket-btn {
    text-align: center;
    margin-top: 30px;
  }
  .copyright-area .logo img {
    max-width: 100px;
  }
  .speakers-area .p-0 {
    padding: 0 15px !important;
  }
  .speakers-area .single-speakers, .speakers-area .elkevent-single-speakers {
    margin-bottom: 30px;
  }
  section.speakers-area.ptb-120.pb-0 {
    padding-bottom: 30px !important;
  }
  .slideshow-banner .main-banner-content {
    margin-top: 0;
  }
  .modal-video-body {
    padding: 0 20px;
  }
  .modal-video-body .modal-video-close-btn {
    right: 0;
  }
}

@media only screen and (min-width: 423px) and (max-width: 767px) {
  .main-banner-content .button-box .video-btn {
    margin-top: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .schedule-area .tabs li {
    width: 50%;
    display: inline-block;
  }
  .why-choose-us-two {
    max-width: 540px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .elkevent-nav {
    padding-top: 10px;
  }
  .elkevent-nav .navbar-brand {
    padding-top: 0;
  }
  .elkevent-nav .navbar-brand img {
    max-width: 90px;
  }
  .countdown1 {
    bottom: 25px !important;
  }
  .copyright-area .logo img {
    max-width: 100px;
  }
  .ptb-120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .event-countdown {
    bottom: 15px;
  }
  .event-countdown #timer span {
    font-size: 12px;
  }
  .slideshow-banner .event-countdown {
    text-align: center;
  }
  .buy-tickets {
    text-align: center;
  }
  .buy-tickets .buy-ticket-btn {
    text-align: center;
    margin-top: 30px;
  }
  .section-title span {
    font-size: 14px;
  }
  .section-title h2 {
    font-size: 30px;
  }
  .section-title .bg-title {
    font-size: 100px;
    line-height: 100px;
    right: 20%;
    margin-top: -10px;
    -webkit-transform: unset !important;
            transform: unset !important;
  }
  .section-title .btn {
    margin-top: -25px;
  }
  .elkevent-mobile-nav .logo {
    left: 40px !important;
  }
  .elkevent-mobile-nav.mean-container a.meanmenu-reveal {
    padding: 12px 40px 0 0;
  }
  .main-banner {
    height: 100%;
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .main-banner-content {
    text-align: center;
  }
  .main-banner-content::before {
    display: none;
  }
  .main-banner-content p {
    font-size: 18px;
  }
  .main-banner-content h1 {
    font-size: 40px;
    line-height: 1.4;
  }
  .main-banner-content ul li {
    font-size: 15px;
  }
  .main-banner-content.banner-content-center {
    margin-top: 0;
  }
  .home-slides.owl-theme .owl-nav [class*="owl-"] {
    top: auto;
    bottom: 20px;
    left: 35px;
  }
  .home-slides.owl-theme .owl-nav [class*="owl-"].owl-next {
    left: auto;
    right: 35px;
  }
  .event-countdown #timer div {
    margin: 0 25px;
    font-size: 50px;
  }
  .event-countdown #timer div span {
    font-size: 13px;
    right: -30px;
    margin-top: -11px;
    letter-spacing: 0;
  }
  .event-countdown #timer div#hours span {
    right: -36px;
    margin-top: -10px;
  }
  .event-countdown #timer div#minutes span {
    right: -42px;
    margin-top: -10px;
  }
  .event-countdown #timer div#seconds span {
    right: -44px;
    margin-top: -10px;
  }
  .subscribe-area .subscribe-inner .newsletter-form .btn {
    height: 50px;
  }
  .countdown-area .event-countdown #timer div {
    margin: 0 30px;
  }
  .about-content span {
    font-size: 14px;
  }
  .about-content h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .about-image {
    margin-top: 50px;
  }
  .about-image .about-img1 {
    position: relative;
    right: 0;
    top: 0;
  }
  .about-image .about-img2 {
    display: none;
  }
  .about-image .btn {
    bottom: 0;
    right: 25px;
    -webkit-transform: unset !important;
            transform: unset !important;
  }
  .about-area-three .about-image {
    margin-bottom: 30px;
  }
  .about-area-three .about-image .about-img1 {
    width: 100%;
  }
  .about-area-three .about-image .btn {
    bottom: 30px;
  }
  .why-choose-us {
    padding-top: 80px;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .single-box {
    height: 100%;
    margin-bottom: 30px;
    border: none !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  .single-box .d-table {
    height: auto;
    display: block;
  }
  .single-box .d-table-cell {
    display: block;
  }
  .single-box .content {
    position: relative;
    z-index: 2;
    padding-left: 0;
    padding-right: 0;
  }
  .single-box .content .btn {
    opacity: 1;
    visibility: visible;
    margin-top: 20px;
  }
  .single-box .content .icon {
    font-size: 35px;
    line-height: 40px;
  }
  .single-box .content h3 {
    font-size: 22px;
  }
  .single-box:hover .content, .single-box:focus .content {
    bottom: 0;
  }
  .single-box:hover .content p, .single-box:focus .content p {
    margin-bottom: 0;
  }
  .why-choose-content h3 {
    font-size: 22px;
  }
  .why-choose-us-two .col-lg-4:nth-child(1) {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .why-choose-us-two .col-lg-4:nth-child(2) {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .why-choose-us-two .col-lg-4:nth-child(3) {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .why-choose-us-two .col-lg-4:nth-child(4) {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .why-choose-us-two .col-lg-4:nth-child(5) {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .why-choose-us-two .col-lg-4:nth-child(6) {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .why-choose-us-two .col-lg-4:nth-child(7) {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .why-choose-us-two .col-lg-4:nth-child(8) {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .single-speakers .speakers-content h3 {
    font-size: 22px;
  }
  .elkevent-single-speakers .speakers-content h3 {
    font-size: 20px;
  }
  .speakers-area-two {
    padding-bottom: 50px;
  }
  .single-speakers-box .speakers-content h3 {
    font-size: 20px;
  }
  .shape1, .shape2, .shape3, .shape4 {
    display: none;
  }
  .tabs li a {
    font-size: 18px;
  }
  .tabs li a span {
    font-size: 13px;
  }
  .tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info h3 {
    font-size: 18px;
    padding-right: 115px;
    line-height: 1.4;
  }
  .tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li {
    font-size: 13px;
  }
  .schedule-slides-item .single-schedule {
    padding: 0 0 0 175px;
  }
  .schedule-slides-item .single-schedule .schedule-date {
    font-size: 22px;
    padding: 20px 20px;
    width: 175px;
  }
  .schedule-slides-item .single-schedule .schedule-date span {
    font-size: 14px;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li {
    margin-right: 10px;
    font-size: 13px;
  }
  .schedule-slides.owl-theme .owl-nav [class*="owl-"] {
    left: -15px;
  }
  .schedule-slides.owl-theme .owl-nav [class*="owl-"].owl-next {
    right: -15px;
    left: auto;
  }
  .schedule-style-three {
    padding-bottom: 50px;
  }
  .single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li {
    font-size: 14px;
  }
  .schedule-style-four .tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li {
    font-size: 14px;
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .schedule-style-four .tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li:last-child {
    margin-bottom: 0;
  }
  .single-funfact .icon {
    font-size: 35px;
  }
  .single-funfact h3 {
    font-size: 40px;
  }
  .funFact {
    padding-left: 0;
    text-align: center;
  }
  .funFact .icon {
    font-size: 35px;
    position: relative;
  }
  .funFact h3 {
    margin-top: 10px;
    font-size: 40px;
  }
  .event-countdown {
    right: 0;
    text-align: center;
    left: 0;
  }
  .pricing-area {
    padding-bottom: 50px;
  }
  .pricing-table-box {
    margin-bottom: 30px;
  }
  .pricing-table-box .title {
    font-size: 22px;
  }
  .pricing-table-box .price-value {
    font-size: 40px;
  }
  .pricing-table-box .price-value sup {
    font-size: 20px;
    top: -20px;
  }
  .pricing-plan {
    margin-bottom: 30px;
  }
  .pricing-plan h3 {
    font-size: 22px;
  }
  .pricing-plan h3 span {
    height: 140px;
    /* width: 140px; */
    line-height: 145px;
    font-size: 28px;
  }
  .partner-area .partner-title {
    margin-bottom: 40px;
    -webkit-transform: unset !important;
            transform: unset !important;
  }
  .partner-area .partner-title h3 {
    font-size: 20px;
  }
  .cta-area span {
    font-size: 16px;
  }
  .cta-area h3 {
    font-size: 30px;
  }
  .cta-area .text-right {
    text-align: left !important;
    margin-top: 25px;
  }
  .single-blog-post .blog-post-content h3 {
    font-size: 20px;
  }
  .single-blog-card .blog-post-content {
    padding: 0 20px 20px;
  }
  .single-blog-card .blog-post-content h3 {
    margin-bottom: 0;
    font-size: 20px;
  }
  .single-blog-card .blog-post-content p, .single-blog-card .blog-post-content .read-more-btn {
    display: none;
  }
  .buy-tickets-area.ptb-120 {
    padding-bottom: 175px;
  }
  .subscribe-area .subscribe-inner span {
    font-size: 15px;
  }
  .subscribe-area .subscribe-inner h2 {
    font-size: 30px;
  }
  .page-title-area {
    padding-top: 160px;
    padding-bottom: 120px;
  }
  .page-title-area h1 {
    font-size: 30px;
  }
  .page-title-area span {
    margin-top: 8px;
    font-size: 16px;
  }
  .login-area {
    height: 100%;
    /* padding-top: 10px;
    padding-bottom: 10px; */
  }
  .signup-area {
    height: 100%;
    /* padding-top: 100px;
    padding-bottom: 100px; */
  }
  .coming-soon {
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .faq-accordion {
    padding: 30px;
  }
  .faq-contact {
    padding: 30px;
  }
  .faq-contact h3 {
    font-size: 22px;
  }
  .sidebar {
    margin-top: 50px;
  }
  .sidebar .widget .widget-title {
    font-size: 20px;
  }
  .comments-area .comments-title, .comments-area .comment-reply-title {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .contact-box {
    margin-bottom: 30px;
    padding: 30px 20px 30px 75px;
  }
  .contact-box .icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }
  .contact-box h4 {
    font-size: 18px;
    margin-bottom: 13px;
  }
  .contact-box p {
    margin-bottom: 3px;
    font-size: 14px;
  }
  .contact-box p:last-child {
    margin-bottom: 0;
  }
  .contact-form {
    margin-top: 20px;
  }
  .leave-your-message h3 {
    margin-bottom: 12px;
    font-size: 20px;
  }
  #contactForm {
    margin-top: 30px;
  }
  .footer-area {
    padding-top: 80px;
  }
  .single-footer-widget h3 {
    font-size: 22px;
  }
  .copyright-area {
    margin-top: 60px;
  }
  .elkevent-nav nav .navbar-nav .nav-item a {
    font-size: 15px;
    margin-left: 11px;
    margin-right: 11px;
  }
  .elkevent-nav nav .navbar-nav .nav-item:nth-last-child(1) .dropdown-menu, .elkevent-nav nav .navbar-nav .nav-item:nth-last-child(2) .dropdown-menu, .elkevent-nav nav .navbar-nav .nav-item:nth-last-child(3) .dropdown-menu {
    left: auto;
    right: 0;
  }
  .countdown1 #timer div {
    margin: 0 20px;
    font-size: 40px;
  }
  .single-funfact {
    margin-bottom: 30px;
  }
  .funfacts-area.ptb-120 {
    padding-bottom: 50px;
  }
  .modal-video-body {
    padding: 0 30px;
  }
  .modal-video-body .modal-video-close-btn {
    right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .elkevent-nav .navbar-brand {
    padding-top: 0;
  }
  .elkevent-nav .navbar-brand img {
    max-width: 100px;
  }
  .copyright-area .logo img {
    max-width: 100px;
  }
  .elkevent-nav nav .navbar-nav .nav-item a {
    font-size: 15px;
  }
  .main-banner-content p {
    font-size: 20px;
  }
  .main-banner-content h1 {
    font-size: 45px;
    font-weight: 800;
    line-height: 1.3;
  }
  .event-countdown #timer div {
    font-size: 50px;
  }
  .about-content span {
    font-size: 15px;
  }
  .about-content h2 {
    font-size: 27px;
    margin-bottom: 20px;
  }
  .about-content h6 {
    margin-bottom: 15px;
  }
  .about-content .signature {
    margin-top: 25px;
  }
  .about-image .about-img1 {
    width: 85%;
    top: -145px;
    right: -40px;
  }
  .about-image .about-img2 {
    left: 0;
    bottom: -142px;
  }
  .about-image .btn {
    bottom: 0;
    -webkit-transform: unset !important;
            transform: unset !important;
  }
  .about-area-three .about-image img {
    width: 100%;
  }
  .why-choose-content {
    padding: 10px;
  }
  .why-choose-content h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .single-box .content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .single-box .content h3 {
    font-size: 22px;
  }
  .single-box .content::before {
    display: none;
  }
  .cta-area h3 {
    font-size: 30px;
  }
  .cta-area span {
    font-size: 15px;
  }
  .single-speakers .speakers-content {
    bottom: 15px;
    left: 15px;
  }
  .single-speakers .speakers-content h3 {
    font-size: 18px;
  }
  .single-speakers ul li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
  .single-speakers:hover .speakers-content, .single-speakers:focus .speakers-content {
    bottom: 12px;
  }
  .elkevent-single-speakers .speakers-content {
    padding: 15px;
  }
  .elkevent-single-speakers .speakers-content h3 {
    font-size: 18px;
  }
  .elkevent-single-speakers .speakers-content span {
    font-size: 13px;
    margin-top: 5px;
  }
  .elkevent-single-speakers ul li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
  .single-speakers-box .speakers-content h3 {
    font-size: 20px;
  }
  .section-title span {
    font-size: 15px;
  }
  .section-title h2 {
    font-size: 34px;
  }
  .section-title .bg-title {
    -webkit-transform: unset !important;
            transform: unset !important;
    font-size: 80px;
  }
  .tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info h3 {
    font-size: 20px;
  }
  .single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li {
    font-size: 14px;
    display: block;
    margin-right: 0;
    margin-bottom: 5px;
  }
  .single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li:last-child {
    margin-bottom: 0;
  }
  .pricing-table-box .title {
    font-size: 18px;
  }
  .pricing-table-box .price-value {
    font-size: 40px;
  }
  .partner-area .partner-title {
    -webkit-transform: unset !important;
            transform: unset !important;
  }
  .partner-area .partner-title h3 {
    font-size: 20px;
  }
  .subscribe-area .subscribe-inner span {
    font-size: 15px;
  }
  .subscribe-area .subscribe-inner h2 {
    font-size: 34px;
  }
  .sidebar .widget .widget-title {
    font-size: 20px;
  }
  .countdown1 #timer div {
    font-size: 50px;
  }
  .elkevent-nav nav .others-option .btn {
    font-size: 14px;
    padding: 14px 20px;
  }
  .tabs li a {
    font-size: 20px;
  }
  .home-slides .main-banner-content {
    text-align: center;
  }
  .page-title-area {
    padding-top: 200px;
    padding-bottom: 130px;
  }
  .slideshow-banner .main-banner-content {
    text-align: center;
  }
  .slideshow-banner .event-countdown {
    text-align: center;
    bottom: 5px;
  }
  .slideshow-banner .event-countdown #timer span {
    font-size: 10px;
    right: -35px;
  }
  .slideshow-banner .event-countdown #timer div {
    font-size: 45px;
  }
  .slideshow-banner .event-countdown #timer div#hours span {
    right: -41px;
  }
  .slideshow-banner .event-countdown #timer div#minutes span {
    right: -48px;
  }
  .slideshow-banner .event-countdown #timer div#seconds span {
    right: -50px;
  }
}

@media only screen and (min-width: 1650px) {
  .main-banner {
    height: 100vh;
  }
  .event-countdown {
    right: 20%;
  }
  .about-image .about-img1 {
    right: -200px;
  }
}

@media only screen and (max-width: 991px) {
  .elkevent-nav nav .navbar-toggler {
    outline: 0;
    color: #ffffff;
    border-color: #ffffff;
  }
  .elkevent-nav nav .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  .elkevent-nav nav .collapse {
    background-color: #22252d;
    padding: 15px 12px;
    margin-top: 5px;
    overflow-y: auto;
    max-height: 100vh;
  }
  .elkevent-nav nav .collapse::-webkit-scrollbar {
    width: 10px;
  }
  .elkevent-nav nav .collapse::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .elkevent-nav nav .collapse::-webkit-scrollbar-thumb {
    background: #888;
  }
  .elkevent-nav nav .collapse::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .elkevent-nav nav .navbar-nav .nav-item {
    padding: 8px 0;
  }
  .elkevent-nav nav .navbar-nav .nav-item a {
    font-size: 15px;
    margin: 0;
  }
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu {
    padding-top: 12px;
    padding-bottom: 12px;
    position: relative;
    opacity: 1;
    visibility: visible;
    top: 0 !important;
    width: 100%;
    margin-top: 10px;
  }
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a {
    font-size: 14px;
  }
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    padding-top: 12px;
    padding-bottom: 12px;
    position: relative;
    opacity: 1 !important;
    visibility: visible !important;
    top: 0 !important;
    width: 90%;
    left: 15px;
    margin-top: 0;
    margin-bottom: 10px;
    background: #30334c;
  }
  .elkevent-nav nav .others-option {
    margin-left: 0;
    margin-top: 10px;
  }
}
