/* Sidebar.css */
.navbar-sidebar {
    width: 250px;
    height: 100vh;
    background-color: #333;
    color: #fff;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding-top: 20px;
}

.navbar-sidebar-logo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.navbar-sidebar-logo h2 {
    color: #fff;
    margin: 0;
}

.navbar-sidebar-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.navbar-sidebar-item {
    width: 100%;
    padding: 15px 20px;
    text-align: left;
}

.navbar-sidebar-item a {
    text-decoration: none;
    color: #fff;
    display: block;
    width: 100%;
}

.navbar-sidebar-item:hover {
    background-color: #575757;
}



/* {avatarDropdown ? (
    <div className="navbar-sidebar">
      <div className="navbar-sidebar-logo mx-4">
        <h2>Logo</h2>
        <button className="" onClick={() => setAvatarDropdown(!avatarDropdown)}>
          <MdClose size={18} />
        </button>
      </div>
      <ul className="navbar-sidebar-menu" style={{ display: 'grid' }}>
        <li className="navbar-sidebar-item">
          <a href="#">Home</a>
        </li>
        <li className="navbar-sidebar-item">
          <a href="#">About</a>
        </li>
        <li className="navbar-sidebar-item">
          <a href="#">Services</a>
        </li>
        <li className="navbar-sidebar-item">
          <a href="#">Contact</a>
        </li>
      </ul>
    </div>
  ) : (
    ""
  )} */