

.chat-field {
    position: relative;
}

.ic_search {
    width: 19.6px;
    height: 19.6px;
    margin: 0 16.4px 0.4px 0;
    object-fit: contain;
    position: absolute;
    top: 11px;
    left: 20px;
    z-index: 1;
}


.input-chat-list {
    width: 92%;
    height: 42px;
    margin: 0 10px 0px;
    padding: 11px 14px 11px 40px;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: #f9f9f9;
    border: none;
    z-index: 10;
    font-size: 14px;
}

.user-list {
    width: auto;
    height: auto;
    overflow: hidden;
    padding: 10px 15px;
    border-top: solid 1px #e9e9e9;
    background-color: #fff;
    cursor: pointer
}

.user-list:hover {
  background-color: #f5f5f5;
}

.active-user-list {
    width: auto;
    height: auto;
    padding: 10px 15px;
    border-top: solid 1px #e9e9e9;
    background-color: #e6e6e6;
    cursor: pointer;
}

.user-img {
    width: 50px;
    height: 48px;
    margin: 0 15px 0 0;
    border-radius: 50%;
}

.userName {
    display: -webkit-box;        /* Required for -webkit-line-clamp */
    -webkit-box-orient: vertical; /* Set box orientation to vertical */
    -webkit-line-clamp: 1;        /* Number of lines to display */
    overflow: hidden;             /* Hide overflowing content */
    text-overflow: ellipsis; 
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
}

.user-msg {
    display: -webkit-box;        /* Required for -webkit-line-clamp */
    -webkit-box-orient: vertical; /* Set box orientation to vertical */
    -webkit-line-clamp: 1;        /* Number of lines to display */
    overflow: hidden;             /* Hide overflowing content */
    text-overflow: ellipsis; 
    margin: 1px 99px 0 0;
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #818181;
}

.msg-count {
    width: 20px;
    height: 20px;
    margin: 0px 0 1px 45px;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 1px 3px;
    background-color: #f43254;
    border-radius: 50%;
}

.msg-text-count {
    width: 13px;
    height: 13px;
    font-family: Poppins;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.Timestamp {
    width: 46px;
    height: 17px;
    margin: 0 0 0 3px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #818181;
}





.single-user-head {
    /* width: 797px; */
    width: 100%;
    height: auto;
    padding: 19px 20px 17px;
    border-radius: 4px;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.03), 0 7px 9px 0 rgba(0, 0, 0, 0.03);
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: solid 1px rgb(190, 190, 190);
}

.chat-view-user {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis; 
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
}

.last-seen {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis; 
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #818181;
}

.moredots {
    width: 24px;
    height: 24px;
    margin: 8px 0 8px 0px;
    object-fit: contain;

}

.data-style {
    width: 80px;
    height: 30px;
    margin: 20px 0px 20px 0px;
    padding: 5px 19px 6px 20px;
    border-radius: 15px;
    background-color: #333;
}

.data-text {
    width: 41px;
    height: 19px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    text-align: center;
    display: flex
}

@media only screen and (max-width: 1600px) {
    .set-hign {
        height: 300px;
        width: 100%;
        overflow-y: scroll;
    }
}

@media only screen and (min-width: 1600px) {
    .set-hign {
        height: 380px;
        width: 100%;
        overflow-y: scroll;
    }
}

/* left */
.msg-bubble-left {
    width: auto;
    height: auto;
    margin: 0 0 5px 10px;
    padding: 10px 20px 10px 10px;
    border-radius: 16px;
    border: solid 1px #c4c9cd;
    background-color: #fff;
}

.left-msg {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 28px;

}

@media only screen and (max-width: 720px) {
    .msg-text-left {
        width: auto;
        max-width: 260px;
        height: auto;
        font-family: Poppins;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333;
    }

    .msg-text-right {
        width: auto;
        max-width: 260px;
        height: auto;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333;
    }

    .chat-view {
        /* width: 800px; */
        width: 100%;
        /* height: 500px;*/
        padding: 0 1px 0px 0;
        border-radius: 4px;
        border: solid 1px #e9e9e9;
        background-color: #fff;
    }

    .chat-message-section{
        height: 300px;
        overflow-y: auto;
    }

    .tab-button {
        padding: 18px 18px;
        border: solid 1px rgba(244, 50, 84, 0.12);
        background-color: #ffffff;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #888;
        border: solid 1px #e9e9e9
      }

      .active-tab-button {
        background-color: #fff5f7;
        font-family: Poppins;
        padding: 18px 18px;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #f43254;
        border-bottom: solid 4px rgba(230, 49, 82, 0.829);;
        border-left: solid 1px #e9e9e9;
        border-top: solid 1px #e9e9e9;
        border-right: solid 1px #e9e9e9;
      }

      .your-events-heading{
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333;
      }

      .chat-list {
        /* width: 390px; */
        width: 100%;
        height:500px;
        max-height:500px ;
        overflow-y: scroll;
        padding: 17px 0 0;
        border-radius: 4px;
        border: solid 1px #e9e9e9;
        background-color: #fff;
        overflow: auto;
    }
}

@media (min-width: 720px) and (max-width: 1600px) {
    .msg-text-left {
        width: auto;
        max-width: 260px;
        height: auto;
        font-family: Poppins;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333;
    }

    .msg-text-right {
        width: auto;
        max-width: 260px;
        height: auto;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333;
    }

    .chat-view {
        /* width: 800px; */
        width: 100%;
        /* height: 500px; */
        padding: 0 1px 0px 0;
        border-radius: 4px;
        border: solid 1px #e9e9e9;
        background-color: #fff;
    
    }

    .chat-message-section{
        height: 300px;
        overflow-y: auto;
    }

    .tab-button {
        padding: 18px 18px;
        border: solid 1px rgba(244, 50, 84, 0.12);
        background-color: #ffffff;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #888;
        border: solid 1px #e9e9e9
      }

      .active-tab-button {
        background-color: #fff5f7;
        font-family: Poppins;
        padding: 18px 18px;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #f43254;
        border-bottom: solid 4px rgba(230, 49, 82, 0.829);;
        border-left: solid 1px #e9e9e9;
        border-top: solid 1px #e9e9e9;
        border-right: solid 1px #e9e9e9;
      }

      .your-events-heading{
        font-family: Poppins;
        font-size: 30px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333;
      }
      .chat-list {
        /* width: 390px; */
        width: 100%;
        height:700px;
        max-height:500px ;
        overflow-y: scroll;
        padding: 17px 0 0;
        border-radius: 4px;
        border: solid 1px #e9e9e9;
        background-color: #fff;
        overflow: auto;
    }
}

@media only screen and (min-width: 1600px) {
    .msg-text-left {
        width: auto;
        max-width: 400px;
        height: auto;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333;
    }

    .msg-text-right {
        width: auto;
        max-width: 400px;
        height: auto;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333;
    }

    .chat-view {
        /* width: 800px; */
        width: 100%;
        height: 600px;
        padding: 0 1px 0px 0;
        border-radius: 4px;
        border: solid 1px #e9e9e9;
        background-color: #fff;
    }

    .chat-message-section{
        height: 360px;
        overflow-y: auto;
    }

    

    .tab-button {
        padding: 18px 18px;
        border: solid 1px rgba(244, 50, 84, 0.12);
        background-color: #ffffff;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #888;
        border: solid 1px #e9e9e9
      }

      .active-tab-button {
        background-color: #fff5f7;
        font-family: Poppins;
        padding: 18px 18px;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #f43254;
        border-bottom: solid 4px rgba(230, 49, 82, 0.829);;
        border-left: solid 1px #e9e9e9;
        border-top: solid 1px #e9e9e9;
        border-right: solid 1px #e9e9e9;
      }

      .your-events-heading{
        font-family: Poppins;
        font-size: 30px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333;
      }
      .chat-list {
        /* width: 390px; */
        width: 100%;
        height:600px;
        max-height:700px ;
        overflow-y: scroll;
        padding: 17px 0 0;
        border-radius: 4px;
        border: solid 1px #e9e9e9;
        background-color: #fff;
        overflow: auto;
    }
}


.msg-info-time-left {
    height: 17px;
    margin: 2px 0px 0px 0px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757575;
}

.user-img-left {
    width: 48px;
    height: 48px;
    margin: 0 1px 0 5px;
    border-radius: 50%;
}

/* right */
.right-msg .msg-bubble-left {
    width: auto;
    height: auto;
    margin: 0 0 5px 10px;
    padding: 10px 20px 10px 10px;
    border-radius: 16px;
    border: solid 1px #c4e7a3;
    background-color: #eaffd6;
}

.right-msg  {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 28px;
}

.msg-bubble-right {
    width: auto;
    height: auto;
    margin: 0 0 5px 10px;
    padding: 10px 20px 10px 10px;
    border-radius: 16px;
    border: solid 1px #c4c9cd;
    background-color: #fff;
    /* background-color: #e2ffc6; */

}

.msg-info-time-right {
    height: 17px;
    margin: 10px 0px 0px 0px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757575;
    display: flex;
    justify-content: flex-end;
}

.user-img-right {
    width: 48px;
    height: 48px;
    margin: 0 0px 0 5px;
    border-radius: 50%;
}

/*  --*/

.hr-line {
    width: auto;
    height: 2px;
    margin: 20px 0 0px;
    background-color: #e9e9e9;
}

.send-msg-input {
    width: 100%;
    height: 100px;
    padding: 8px 9px 11px 10px;
    border-radius: 4px;
    border: solid 1px #e9e9e9;
    background-color: #fff;
}

.send-msg-input:focus{
    outline: #f43254;
    border: solid 1px #f43254;
}

.input-sent-button {
    width: 120px;
    height: 40px;   
    padding: 8px 9px 11px 10px;
    border-radius: 4px;
    background-color:#f43254;
    cursor: pointer;
    border: none;
}

.send-btn {
    width: 21px;
    height: 18px;
    object-fit: contain;
}

.manage-request-p{
    font-size: 12px;
    line-height:1.6
}

.msg-input-btn{
    height: 160px;
}

.chat-support-mdl {
    width: 50%;
    padding: 20px;
    background-color: white !important;
    border-radius: 8px;
  }

  .chat-list-button{
    background-color: transparent;
    border: none;
    text-align: left;
    width: 100%;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    width: 130px;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    /* padding: 4px 10px; */
  }
  
  .dropdown-content p {
    color: black;
    padding: 6px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content.open {
    display: block;
  }
  
  .dropdown-content.closed {
    display: none;
  }

  .list-drop-button{
    padding: 10px 12px;
    border: none;
    background-color: transparent;
    font-weight: 500;
    width: 100%;
    border-radius: 6px;
  }

  .list-drop-button:hover{
    background-color: #f43254;
    color: white;
  }

.setting-btn{
  background-color: transparent;
  border: none;
}