.print-only-banner .single-banner {
    display: none;
}

.hide-social-icon {
    display: flex;
    gap: 5px
}

.detail-page-info {
    display: flex;
    gap: 5px;
    align-items: center;
}

.event-gallery-image-normal-mode {
    display: block
}

.event-gallery-image-print-mode {
    display: none;
}

.event-gellary-document-normal-mode {
    display: block;
}

.event-gellary-document-print-mode {
    display: none;
}

.confirmed-sponsors-normal-mode {
    display: block;
}

.confirmed-sponsors-print-mode {
    display: none;
}

.package-inclusions-div-print-mode {
    display: none;
}

.remove-language-intrest-printmode {
    display: block;
}

@media print {
    body {
        background-color: white;
        color: black;
        font-family: Arial, sans-serif;
        margin: 0;
        padding: 0;
    }

    button,
    .no-print,
    .header-area,
    .footer-area,
    .handle-print-pdf,
    .hide-social-icon,
    .detail-page-info {
        display: none;
    }

    .page-break {
        page-break-before: always;
    }

    .swiper-button-next,
    .swiper-button-prev,
    .swiper-pagination {
        display: none !important;
    }


    .event-pro-head {
        font-family: Poppins;
        font-size: 27px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333;
        text-transform: capitalize;
        padding-top: 20px;
    }

    .detail-p-text {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #494949;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* Number of lines to show */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .social-back-img {
        width: 85%;
        height: 270px;
        background-image: url('../images/group.png');
        background-size: 98% 260px;
        object-fit: contain;
        background-repeat: no-repeat;
        /* padding-right: 100px; */
    }

    .rounded-ringus-img {
        height: 200px;
        width: 200px;
        /*background-image: url("../images/ringus-rounded-background.svg"); */
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .rounded-ringus-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        width: 154px;
        height: 154px;
        padding: 61px 29px 61px 29px;
        /* box-shadow:  0 0 40px 0 rgb(227 43 43 / 55%); */
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background-color: #ffffff;
        border-radius: 100%;
    }

    .linkedin-div {
        margin-top: 100px;
        margin-left: 0px;
        height: 74px;
        width: 74px;
        padding: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
        background-color: #ffffff;
        border-radius: 100%;
    }

    .twitter-div {
        margin-right: -30px;
        height: 74px;
        width: 74px;
        padding: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
        background-color: #ffffff;
        border-radius: 100%;
    }

    .google-div {
        margin-top: 0px;
        height: 74px;
        width: 74px;
        padding: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
        background-color: #ffffff;
        border-radius: 100%;
    }

    .modal-content {
        padding: 20px;
        width: 521px;
        height: auto;
    }

    .modal-dis {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333;
        height: auto;
        max-height: 200px;
        overflow-y: auto;
    }

    .detail-gender-p {
        color: black;
        font-weight: 500;
        font-size: 12px;
    }

    .time-text {
        font-size: 11px;
        text-align: center;
    }

    .IntrestP {
        font-size: 13px;
        color: rgb(66, 66, 66);
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: nowrap;
        background-color: #ffefef !important;
        margin-bottom: 0px;
        color: #f43254;
        -webkit-print-color-adjust: exact;
        color-adjust: exact
    }

    .Card-IntrestP {
        font-size: 13px;
        color: rgb(66, 66, 66);
        white-space: nowrap;
        background-color: #fff3f3 !important;
        margin-bottom: 0px;
        padding: 2px 4px;
        border: none;
        color: #f43254;
        margin: 3px 2px;
        -webkit-print-color-adjust: exact;
        color-adjust: exact
    }

    .language-show {
        font-size: 13px;
        color: rgb(66, 66, 66);
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: nowrap;
        background-color: #edeef1 !important;
        margin-bottom: 0px;
        color: #747677;
        padding: 0px 4px;
        -webkit-print-color-adjust: exact;
        color-adjust: exact
    }

    .event-card-img {
        position: relative;
        width: 100%;
        height: 280px;
        border-radius: 8px;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }

    .event-mode-div {
        position: absolute;
        z-index: 10;
        background-color: rgb(57, 184, 40) !important;
        color: #fff;
        top: 10px;
        left: 10px;
        padding: 2px 8px;
        border-radius: 2px;
        font-family: Poppins;
        font-size: 12px;
        -webkit-print-color-adjust: exact;
        color-adjust: exact
    }

    .detail-event-status-div {
        position: absolute;
        z-index: 10;
        background-color: rgb(255, 84, 32) !important;
        color: #fff;
        top: 12px;
        right: 10px;
        padding: 2px 8px;
        border-radius: 3px;
        font-family: Poppins;
        font-size: 12px;
        -webkit-print-color-adjust: exact;
        color-adjust: exact
    }

    .event-live-status-div {
        position: absolute;
        z-index: 10;
        background-color: rgb(233, 25, 25) !important;
        color: #fff;
        top: 35px;
        left: 10px;
        padding: 2px 8px;
        border-radius: 2px;
        font-family: Poppins;
        font-size: 12px;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }

    .eventDetail-type-div {
        position: absolute;
        z-index: 20;
        background-color: rgb(40, 141, 184) !important;
        color: #fff;
        bottom: 12px;
        left: 12px;
        padding: 2px 8px;
        border-radius: 2px;
        font-family: Poppins;
        font-size: 12px;
        -webkit-print-color-adjust: exact;
        color-adjust: exact
    }

    .home-event-status-div {
        position: absolute;
        z-index: 10;
        background-color: rgb(255, 84, 32) !important;
        color: #fff;
        top: 35px;
        left: 10px;
        padding: 2px 8px;
        border-radius: 3px;
        font-family: Poppins;
        font-size: 12px;
        -webkit-print-color-adjust: exact;
        color-adjust: exact
    }

    .past-event-head-div {
        position: absolute;
        bottom: 50px;
        left: 10px;
        color: #f43254;
        background-color: #fff5f7c9 !important;
        padding: 3px 6px;
        z-index: 20;
        border-radius: 3px;
        font-weight: 500;
        font-size: 12px;
        -webkit-print-color-adjust: exact;
        color-adjust: exact
    }

    .event-online-div {
        position: absolute;
        z-index: 10;
        background-color: rgb(57, 184, 40) !important;
        color: #fff;
        top: 10px;
        left: 10px;
        padding: 2px 8px;
        border-radius: 2px;
        font-family: Poppins;
        font-size: 12px;
        -webkit-print-color-adjust: exact;
        color-adjust: exact
    }

    .print-only-banner .single-banner {
        display: block;
        height: 375px;
        width: 100%;
        object-fit: cover;
    }

    .stat-guest {
        display: flex;
        flex-direction: row;
        justify-content: start;
        gap: 20px
    }

    .stat-guest-2 {
        text-align: center;
        display: flex;
        align-items: center;
        gap: 30px;
        margin-bottom: 20px;
    }

    .sample-head-div {
        padding: 5px 9px;
        background-color: #ffefef !important;
        border-radius: 6px;
        -webkit-print-color-adjust: exact;
        color-adjust: exact
    }

    .sample-head-text {
        font-size: 13px;
        color: #f43254;
        border-radius: 6px;
    }

    .event-rectangle {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0px;
        width: 100%;
        height: auto;
        padding: 6px 3px 6px 3px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: #f43254 !important;
        color: white;
        -webkit-print-color-adjust: exact;
        color-adjust: exact
    }

    .date-text {
        margin: 2px 0 0 6px;
        font-family: Poppins;
        font-size: 15px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        color: #fff;
    }

    /* image start */
    .event-gallery-image-normal-mode {
        display: none;
    }

    .event-gallery-image-print-mode {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .event-gallery-image-print-mode .p-0 {
        flex: 1 1 calc(33.33% - 10px);
        max-width: calc(33.33% - 10px);
    }

    .event-gallery-image-print-mode img {
        width: 100%;
        height: 200px;
        display: block;
        /* object-fit: cover; */
        border-radius: 3px;
    }

    /* image end */

    /* pdf start */
    .event-gellary-document-normal-mode {
        display: none
    }

    .event-gellary-document-print-mode {
        /* display: grid; */
        /* grid-template-columns: repeat(2, 1fr); */
        /* gap: 16px; */
        display: grid;
        grid-template-columns: 1fr;
    }

    .document-item-print {
        text-align: center;
    }

    .document-item-print:not(:first-child) {
        margin-top: 20px;
        /* Adjust the top margin as needed */
    }

    .pdf-div {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .pdf-div img {
        width: 100px;
        height: 130px;
    }

    .pdf-div .pdf-title {
        width: 100%
            /* height: 130px; */
    }

    /* pdf end */

    /* confirmed sponsor start */
    .confirmed-sponsors-normal-mode {
        display: none;
    }

    .confirmed-sponsors-print-mode {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 10px;
    }

    .confirmed-sponsors-print-mode>div {
        flex: 1 1 calc(25% - 50px);
        box-sizing: border-box;
        text-align: center;
    }

    .confirmed-sponsors-print-mode p {
        text-align: center;
    }

    .confirmed-sponsors-print-mode img {
        border-radius: 50%;
        height: 100px;
        width: 100px;
        border: 1px solid #f43254;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    }

    /* confirmed sponsor end */

    .package-inclusions-div {
        display: none
    }

    .package-inclusions-div-print-mode {
        display: block;
    }

    .remove-language-intrest-printmode {
        display: none;
    }

    .reject-section-printmode{
        display: none;
    }
}