/*
@File: Evnia Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader CSS
** - Navbar CSS
** - Main Banner CSS
** - About CSS
** - Why Choose Us CSS
** - CTA CSS
** - Speakers CSS
** - Schedule CSS
** - FunFacts CSS
** - Pricing CSS
** - Partner CSS
** - Blog CSS
** - Buy Tickets CSS
** - Subscribe CSS
** - Page Title CSS
** - Login CSS
** - Signup CSS
** - 404 Error CSS
** - FAQ CSS
** - Coming Soon CSS
** - Pagination CSS
** - Blog Details CSS
** - Sidebar CSS
** - Contact Us CSS
** - Footer CSS
** - Back To Top CSS
*/
/*================================================
Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

@media only screen and (max-width: 992px) {
  .d-table {
    width: 100%;
    height: 100%;
    padding-top: 0px;
  }
}

@media only screen and (min-width: 992px) {
  .d-table {
    width: 100%;
    height: 100%;
    padding-top: 100px;
  }
}


:root {
  scroll-behavior: initial;
}

body {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
  font-size: 15px;
  top: 0 !important
}

a {
  display: inline-block;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
}



.d-table1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  padding-top: 50px;
}

.d-table-cell {
  /* vertical-align: middle; */
}

.ptb-120 {
  padding-top: 60px;
  padding-bottom: 60px;
}

/* .ptb-60 {
  padding-top: 30px;
  padding-bottom: 60px;
} */

.ptb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

img {
  max-width: 100%;
}

p {
  line-height: 1.7;
  color: #707070;
  margin-bottom: 10px;
  font-size: 14px;
}

p:last-child {
  margin-bottom: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #0d1028;
}

.text-right {
  text-align: end;
}

.text-left {
  text-align: start;
}

.form-group {
  margin-bottom: 15px;
}

textarea.form-control {
  min-height: auto !important;
  height: auto;
}

label {
  margin-bottom: 10px;
}

/*btn btn-primary*/
.btn {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  /* -webkit-box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4);
          box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4); */
  border: none;
  padding: 10px 15px;
  border-radius: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.btn-primary {
  color: #ffffff;
  background: #0d1028;
  position: relative;
  z-index: 1;
}

.btn-primary::before,
.btn-primary::after {
  content: "";
  position: absolute;
  top: 0;
  width: 51%;
  background: #ff2d55;
  height: 100%;
  z-index: -1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.btn-primary::before {
  left: 0;
}

.btn-primary::after {
  right: 0;
}

.btn-primary:hover::before,
.btn-primary:hover::after,
.btn-primary:focus::before,
.btn-primary:focus::after {
  width: 0;
}

.btn-primary.focus,
.btn-primary:focus {
  -webkit-box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
  box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
  color: #ffffff;
}

.btn-primary:hover,
.btn-primary:focus {
  background: #0d1028;
  color: #ffffff;
  -webkit-box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
  box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
  box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  background: #0d1028;
  color: #ffffff;
}

.btn-primary.disabled,
.btn-primary:disabled {
  opacity: 1;
  background: #0d1028;
}

.btn-secondary {
  color: #ffffff;
  background: #0d1028;
  position: relative;
  z-index: 1;
}

.btn-secondary::before,
.btn-secondary::after {
  content: "";
  position: absolute;
  top: 0;
  width: 51%;
  background: #00acee;
  height: 100%;
  z-index: -1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.btn-secondary::before {
  left: 0;
}

.btn-secondary::after {
  right: 0;
}

.btn-secondary:hover::before,
.btn-secondary:hover::after,
.btn-secondary:focus::before,
.btn-secondary:focus::after {
  width: 0;
}

.btn-secondary.focus,
.btn-secondary:focus {
  -webkit-box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
  box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
  color: #ffffff;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background: #0d1028;
  color: #ffffff;
  -webkit-box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
  box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
  box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
  background: #0d1028;
  color: #ffffff;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  opacity: 1;
  background: #0d1028;
}

/*section-title*/


.section-title h2 b {
  /* background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(80%, rgba(0, 0, 0, 0)),
      color-stop(80%, #ff2d55)); */
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #ff2d55 80%); */
  font-weight: unset;
  background-size: 100px 1.07em !important;
}

.section-title .btn {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -18px;
}

.section-title .bg-title {
  color: rgba(73, 57, 231, 0.05);
  font-weight: 700;
  font-size: 140px;
  line-height: 120px;
  position: absolute;
  top: 0;
  right: 35%;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: -1;
  margin-top: -25px;
}

.section-title .bar {
  position: relative;
  width: 20px;
  height: 4px;
  background: #ff2d55;
  display: block;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
}

.section-title .bar::after,
.section-title .bar::before {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  content: "";
}

.section-title .bar::after {
  width: 25px;
  background: #00acee;
  right: -29px;
}

.section-title .bar::before {
  background: #efa506;
  width: 30px;
  right: -63px;
}

/*owl-carousel-btn*/
.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 0;
  line-height: 0.01;
}

.owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 0 4px;
  background: transparent;
  border: 1px solid #dfdfdf;
  display: block;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  position: relative;
}

.owl-theme .owl-dots .owl-dot:hover,
.owl-theme .owl-dots .owl-dot:focus {
  outline: 0;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}

.owl-theme .owl-dots .owl-dot:hover span,
.owl-theme .owl-dots .owl-dot:focus span {
  background: #00acee;
  border-color: #00acee;
}

.owl-theme .owl-dots .owl-dot.active span {
  background: #ff2d55;
  border-color: #ff2d55;
}

/*form-control*/
.form-control {
  height: 51px;
  padding: 15px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.form-control:hover,
.form-control:focus,
.form-control.active {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  border-color: #ff2d55;
}

/* Event Near by you*/
/* ====================================== */

/* Event-Banner */
/* ========================================== */

/* Members Testimonials */
/* ============================================== */

/*WhySponsorRingUs */
/* ================================================== */

/*================================================
Preloader CSS
=================================================*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  overflow: hidden;
  background: #0d1028;
}

.preloader .loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 110px;
  height: 110px;
  margin: -80px 0 0 -75px;
  border-radius: 50%;
  border: 7px solid transparent;
  border-top-color: #ff2d55;
  -webkit-animation: spin 1.7s linear infinite;
  animation: spin 1.7s linear infinite;
  z-index: 11;
}

.preloader .loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 7px solid transparent;
  border-top-color: #efa506;
  -webkit-animation: spin-reverse 0.6s linear infinite;
  animation: spin-reverse 0.6s linear infinite;
}

.preloader .loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 7px solid transparent;
  border-top-color: #00acee;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.container-fluid {
  max-width: 1750px;
}

@media only screen and (max-width: 1200px) {
  .container-fluid {
    max-width: 1320px;
  }
}

/*================================================
Navbar CSS
=================================================*/
/* .container-fluid{
  
} */

/*================================================
Main Banner CSS
=================================================*/

/*================================================
About CSS
=================================================*/
.about-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.about-area.bg-image {
  background-image: url("../images/bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-content span {
  color: #ff2d55;
  letter-spacing: 1px;
  font-size: 16px;
  text-transform: uppercase;
  display: block;
}

.about-content h2 {
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.about-content h2 b {
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(80%, rgba(0, 0, 0, 0)),
      color-stop(80%, #ff2d55));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #ff2d55 80%);
  font-weight: unset;
  background-size: 100px 1.05em !important;
}

.about-content .signature {
  margin-top: 35px;
  margin-bottom: 35px;
}

.about-content .btn-primary {
  margin-right: 20px;
}

.about-image {
  position: relative;
}

.about-image .about-img1 {
  position: relative;
  right: -100px;
  top: -80px;
}

.about-image .about-img2 {
  position: absolute;
  left: 20px;
  bottom: -80px;
}

.about-image .shape-img {
  position: absolute;
  top: -28px;
  left: 48%;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.about-image .btn {
  position: absolute;
  bottom: -20px;
  right: 0;
}

/*about-style-two*/
.about-area-two {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.about-area-two.bg-image {
  background-image: url("../images/bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-area-two .about-image .about-img1 {
  position: relative;
  right: 0;
  top: 0;
}

.about-area-two .about-image .btn {
  bottom: 0;
}

.about-area-two .about-content .signature {
  margin-bottom: 0;
}

/*about-style-three*/
.about-area-three {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.about-area-three.bg-image {
  background-image: url("../images/bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-area-three .about-image .about-img1 {
  position: relative;
  right: 0;
  top: 0;
}

.about-area-three .about-image .btn {
  bottom: 0;
  left: 70px;
  right: auto;
}

.about-area-three .about-content .signature {
  margin-bottom: 0;
}

.about-area-three .about-content h6 {
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
}

.about-area-three .about-content p {
  margin-bottom: 0;
}

.about-area-three .about-content ul {
  padding: 0;
  margin: 20px 0 0;
  list-style-type: none;
}

.about-area-three .about-content ul li {
  margin-bottom: 10px;
  color: #707070;
  padding-left: 20px;
  position: relative;
}

.about-area-three .about-content ul li i {
  color: #ff2d55;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -7px;
}

.about-area-three .about-content ul li:last-child {
  margin-bottom: 0;
}

/*================================================
Why Choose Us CSS
=================================================*/
.why-choose-us {
  position: relative;
  z-index: 1;
}

.why-choose-us::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: #0d1028;
  opacity: 0.7;
}

.why-choose-us .slideshow {
  z-index: -1;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.why-choose-us .slideshow li span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation: imageAnimation 24s linear infinite 0s;
  animation: imageAnimation 24s linear infinite 0s;
}

/* .why-choose-us .slideshow li:nth-child(1) span {
  background-image: url("../images/slideshow-bg1.jpg");
}

.why-choose-us .slideshow li:nth-child(2) span {
  background-image: url("../images/slideshow-bg2.jpg");
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}

.why-choose-us .slideshow li:nth-child(3) span {
  background-image: url("../images/slideshow-bg3.jpg");
  -webkit-animation-delay: 12s;
  animation-delay: 12s;
}

.why-choose-us .slideshow li:nth-child(4) span {
  background-image: url("../images/slideshow-bg4.jpg");
  -webkit-animation-delay: 18s;
  animation-delay: 18s;
} */

.col-lg-3:first-child .single-box {
  border-left: none;
}

.single-box {
  position: relative;
  z-index: 1;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
  height: 450px;
}

.single-box .d-table-cell {
  vertical-align: bottom;
}

.single-box .content {
  position: absolute;
  padding-left: 25px;
  padding-right: 25px;
  bottom: 0;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-box .content::before {
  content: "";
  position: absolute;
  bottom: 5px;
  width: 100%;
  max-width: 280px;
  text-align: center;
  height: 260px;
  left: 50%;
  margin-left: -155px;
  z-index: -1;
  border: 10px solid rgba(104, 89, 222, 0.03);
}

.single-box .content .icon {
  font-size: 45px;
  color: #ff2d55;
}

.single-box .content h3 {
  font-size: 26px;
  color: #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
}

.single-box .content p {
  color: #ffffff;
  margin-bottom: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-box .content .btn {
  opacity: 0;
  visibility: hidden;
}

.single-box:hover .content,
.single-box:focus .content {
  bottom: 35px;
}

.single-box:hover .content p,
.single-box:focus .content p {
  margin-bottom: 25px;
}

.single-box:hover .content .btn,
.single-box:focus .content .btn {
  opacity: 1;
  visibility: visible;
}

@-webkit-keyframes imageAnimation {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  12.5% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  25% {
    opacity: 1;
  }

  37.5% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  12.5% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  25% {
    opacity: 1;
  }

  37.5% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.no-cssanimations .slideshow li span {
  opacity: 1;
}

/*why-choose-style-two*/
.why-choose-us-two {
  max-width: 1920px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.why-choose-content {
  text-align: center;
  padding: 20px 25px;
  position: relative;
  z-index: 1;
}

.why-choose-content h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.why-choose-content p {
  margin-bottom: 0;
}

.why-choose-content a {
  color: #ff2d55;
  margin-top: 15px;
}

.why-choose-content a:hover,
.why-choose-content a:focus {
  letter-spacing: 1px;
}

.why-choose-content span {
  font-size: 100px;
  font-weight: 700;
  color: #f2f2f2;
  line-height: 90px;
  position: absolute;
  left: 0;
  right: 0;
  margin: -10px auto 0;
  z-index: -1;
  top: 0;
}

/*================================================
CTA CSS
=================================================*/
.cta-area {
  background-color: #ff2d55;
  padding-top: 50px;
  padding-bottom: 50px;
}

.cta-area span {
  display: block;
  color: #ffffff;
  font-size: 20px;
}

.cta-area h3 {
  color: #ffffff;
  font-size: 35px;
  margin-bottom: 10px;
}

/*================================================
Speakers CSS
=================================================*/
.single-speakers {
  overflow: hidden;
  position: relative;
}

.single-speakers::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(transparent),
      color-stop(40%, transparent),
      color-stop(58%, rgba(0, 0, 0, 0.3)),
      color-stop(89%, rgba(0, 0, 0, 0.8)),
      to(rgba(0, 0, 0, 0.63)));
  background: linear-gradient(transparent 0%,
      transparent 40%,
      rgba(0, 0, 0, 0.3) 58%,
      rgba(0, 0, 0, 0.8) 89%,
      rgba(0, 0, 0, 0.63) 100%);
  width: 100%;
  height: 100%;
}

.single-speakers .speakers-content {
  position: absolute;
  bottom: 35px;
  left: 35px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-speakers .speakers-content h3 {
  font-size: 24px;
  margin-bottom: 0;
}

.single-speakers .speakers-content h3 a {
  color: #ff2d55;
}

.single-speakers .speakers-content h3 a:hover,
.single-speakers .speakers-content h3 a:focus {
  color: #8dc63f;
}

.single-speakers .speakers-content span {
  display: block;
  margin-top: 6px;
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-speakers ul {
  padding: 15px;
  margin: 0 0 0;
  opacity: 0;
  visibility: hidden;
  list-style-type: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  right: -15px;
  top: 0;
  background-color: #ffffff;
}

.single-speakers ul li {
  display: block;
  margin-bottom: 10px;
}

.single-speakers ul li:last-child {
  margin-bottom: 0;
}

.single-speakers ul li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background-color: #ff2d55;
  color: #ffffff;
  border-radius: 50%;
  font-size: 14px;
}

.single-speakers ul li a.facebook {
  background-color: #4267b2;
}

.single-speakers ul li a.twitter {
  background-color: #38a1f3;
}

.single-speakers ul li a.instagram {
  background: radial-gradient(circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    -webkit-gradient(linear, left top, left bottom, from(#6559ca), color-stop(30%, #bc318f), color-stop(50%, #e33f5f), color-stop(70%, #f77638), to(#fec66d));
  background: radial-gradient(circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(#6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%);
}

.single-speakers ul li a.linkedin {
  background-color: #0077b5;
}

.single-speakers ul li a:hover,
.single-speakers ul li a:focus {
  background-color: #ff2d55;
  color: #ffffff;
}

.single-speakers:hover .speakers-content,
.single-speakers:focus .speakers-content {
  bottom: 45px;
}

.single-speakers:hover ul,
.single-speakers:focus ul {
  right: 0;
  opacity: 1;
  visibility: visible;
}

/*Speakers-Style-Two*/
.elkevent-single-speakers {
  overflow: hidden;
  position: relative;
}

.elkevent-single-speakers .speakers-content {
  position: absolute;
  bottom: -25px;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  height: auto;
  padding: 25px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.elkevent-single-speakers .speakers-content h3 {
  font-size: 24px;
  margin-bottom: 0;
}

.elkevent-single-speakers .speakers-content h3 a {
  color: #0d1028;
}

.elkevent-single-speakers .speakers-content h3 a:hover,
.elkevent-single-speakers .speakers-content h3 a:focus {
  color: #ff2d55;
}

.elkevent-single-speakers .speakers-content span {
  display: block;
  margin-top: 8px;
  color: #ff2d55;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.elkevent-single-speakers ul {
  padding: 15px 20px 10px;
  margin: 0 0 0;
  opacity: 0;
  visibility: hidden;
  list-style-type: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  right: -15px;
  top: 0;
  background-color: #ffffff;
}

.elkevent-single-speakers ul li {
  display: block;
  margin-bottom: 5px;
}

.elkevent-single-speakers ul li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background-color: #ff2d55;
  color: #ffffff;
  border-radius: 50%;
  font-size: 14px;
}

.elkevent-single-speakers ul li a.facebook {
  background-color: #4267b2;
}

.elkevent-single-speakers ul li a.twitter {
  background-color: #38a1f3;
}

.elkevent-single-speakers ul li a.instagram {
  background: radial-gradient(circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    -webkit-gradient(linear, left top, left bottom, from(#6559ca), color-stop(30%, #bc318f), color-stop(50%, #e33f5f), color-stop(70%, #f77638), to(#fec66d));
  background: radial-gradient(circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(#6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%);
}

.elkevent-single-speakers ul li a.linkedin {
  background-color: #0077b5;
}

.elkevent-single-speakers ul li a:hover,
.elkevent-single-speakers ul li a:focus {
  background-color: #ff2d55;
  color: #ffffff;
}

.elkevent-single-speakers img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.elkevent-single-speakers:hover .speakers-content,
.elkevent-single-speakers:focus .speakers-content {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}

.elkevent-single-speakers:hover ul,
.elkevent-single-speakers:focus ul {
  right: 0;
  opacity: 1;
  visibility: visible;
}

.elkevent-single-speakers:hover img,
.elkevent-single-speakers:focus img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

/*Speakers-style-three*/
.speakers-area {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.speakers-area-two {
  padding-bottom: 90px;
}

.single-speakers-box {
  margin-bottom: 30px;
  text-align: center;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
}

.single-speakers-box .speakers-content {
  background: #ffffff;
  padding: 30px 0;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-speakers-box .speakers-content::before {
  content: "";
  width: 0;
  height: 20px;
  color: #ffffff;
  background: #ff2d55;
  position: absolute;
  top: -20px;
  right: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-speakers-box .speakers-content h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.single-speakers-box .speakers-content h3 a {
  color: #0d1028;
}

.single-speakers-box .speakers-content span {
  display: block;
  text-transform: capitalize;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #ff2d55;
}

.single-speakers-box .speakers-content .social {
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: absolute;
  top: -37px;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-speakers-box .speakers-content .social li {
  display: inline-block;
}

.single-speakers-box .speakers-content .social li a {
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  color: #a0a0a0;
  margin-right: 10px;
  position: relative;
  z-index: 1;
}

.single-speakers-box .speakers-content .social li a:hover {
  color: #ffffff;
}

.single-speakers-box .speakers-content .social li a:hover::before {
  background: #00acee;
  color: #ffffff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.single-speakers-box .speakers-content .social li a::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-speakers-box:hover {
  -webkit-box-shadow: 0 0 28px 0 rgba(6, 74, 255, 0.22);
  box-shadow: 0 0 28px 0 rgba(6, 74, 255, 0.22);
}

.single-speakers-box:hover::before,
.single-speakers-box:hover::after {
  border-color: #ff2d55;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.single-speakers-box:hover .speakers-content {
  background: #ff2d55;
}

.single-speakers-box:hover .speakers-content::before {
  width: 100%;
}

.single-speakers-box:hover .speakers-content .social {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.single-speakers-box:hover .speakers-content h3 a,
.single-speakers-box:hover .speakers-content span {
  color: #ffffff;
}

/*================================================
Schedule CSS
=================================================*/
.schedule-area {
  position: relative;
  z-index: 1;
}

.schedule-area.bg-image {
  background-image: url("../images/bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.tab {
  width: 100%;
  -webkit-box-shadow: 0 0 10px 0 rgba(137, 173, 255, 0.35);
  box-shadow: 0 0 10px 0 rgba(137, 173, 255, 0.35);
  background-color: #ffffff;
}

.tabs {
  list-style-type: none;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  text-align: center;
  padding: 0;
}

.tabs li {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #efa506;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
}

.tabs li::before {
  width: 30px;
  height: 30px;
  left: 0;
  bottom: -15px;
  position: absolute;
  background: #ff2d55;
  content: "";
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 0;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.tabs li a {
  font-size: 24px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 25px 10px;
  font-weight: 600;
}

.tabs li a span {
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  text-transform: capitalize;
  margin-top: 5px;
}

.tabs li:nth-child(2) {
  background-color: #00acee;
}

.tabs li:nth-child(3) {
  background-color: #8dc63f;
}

.tabs li:nth-child(4) {
  background-color: #efa506;
}

.tabs li.current {
  background-color: #ff2d55;
}

.tabs li.current::before {
  opacity: 1;
  visibility: visible;
}

.tab_content {
  padding: 30px;
  background-color: #f9f9ff;
}

.tab_content .tabs_item {
  display: none;
}

.tab_content .tabs_item:first-child {
  display: block;
}

.tab_content .tabs_item .accordion {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: none;
}

.tab_content .tabs_item .accordion .accordion-item {
  display: block;
  margin-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 35px;
  padding-top: 35px;
  background-color: #ffffff;
}

.tab_content .tabs_item .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author {
  max-width: 100px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author img:nth-child(1) {
  -webkit-clip-path: polygon(50% 0%,
      38.11% 6.7%,
      24.99% 6.72%,
      18.06% 18.44%,
      6.7% 25%,
      6.56% 38.64%,
      0% 50%,
      6.7% 61.89%,
      6.7% 75%,
      18.44% 81.94%,
      25% 93.3%,
      38.64% 93.44%,
      50% 100%,
      61.88% 93.3%,
      75% 93.3%,
      81.94% 81.56%,
      93.3% 74.9%,
      93.44% 61.36%,
      100% 50%,
      93.3% 38.11%,
      93.3% 25%,
      81.56% 18.06%,
      74.96% 6.7%,
      61.36% 6.56%,
      50% 0%);
  clip-path: polygon(50% 0%,
      38.11% 6.7%,
      24.99% 6.72%,
      18.06% 18.44%,
      6.7% 25%,
      6.56% 38.64%,
      0% 50%,
      6.7% 61.89%,
      6.7% 75%,
      18.44% 81.94%,
      25% 93.3%,
      38.64% 93.44%,
      50% 100%,
      61.88% 93.3%,
      75% 93.3%,
      81.94% 81.56%,
      93.3% 74.9%,
      93.44% 61.36%,
      100% 50%,
      93.3% 38.11%,
      93.3% 25%,
      81.56% 18.06%,
      74.96% 6.7%,
      61.36% 6.56%,
      50% 0%);
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author img:nth-child(2) {
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author img:nth-child(3) {
  -webkit-clip-path: polygon(50% 0%,
      100% 25%,
      100% 75%,
      50% 100%,
      0% 75%,
      0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author img:nth-child(4) {
  border-radius: 50%;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author img:nth-child(5) {
  -webkit-clip-path: polygon(50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%);
  clip-path: polygon(50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%);
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author img:nth-child(6) {
  -webkit-clip-path: polygon(30% 0%,
      70% 0%,
      100% 30%,
      100% 70%,
      70% 100%,
      30% 100%,
      0% 70%,
      0% 30%);
  clip-path: polygon(30% 0%,
      70% 0%,
      100% 30%,
      100% 70%,
      70% 100%,
      30% 100%,
      0% 70%,
      0% 30%);
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author img:nth-child(7) {
  -webkit-clip-path: polygon(50% 0%,
      83% 12%,
      100% 43%,
      94% 78%,
      68% 100%,
      32% 100%,
      6% 78%,
      0% 43%,
      17% 12%);
  clip-path: polygon(50% 0%,
      83% 12%,
      100% 43%,
      94% 78%,
      68% 100%,
      32% 100%,
      6% 78%,
      0% 43%,
      17% 12%);
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author img:nth-child(8) {
  -webkit-clip-path: polygon(50% 0%,
      80% 10%,
      100% 35%,
      100% 70%,
      80% 90%,
      50% 100%,
      20% 90%,
      0% 70%,
      0% 35%,
      20% 10%);
  clip-path: polygon(50% 0%,
      80% 10%,
      100% 35%,
      100% 70%,
      80% 90%,
      50% 100%,
      20% 90%,
      0% 70%,
      0% 35%,
      20% 10%);
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author img:nth-child(9) {
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author img:nth-child(10) {
  -webkit-clip-path: polygon(50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%);
  clip-path: polygon(50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%);
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author-multi {
  overflow: hidden;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author-multi img {
  width: 47%;
  border-radius: 50%;
  margin-bottom: 0.2rem;
  margin-right: 1.5%;
  margin-left: 1.5%;
  margin-top: 0.2rem;
  float: left;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info h3 {
  font-size: 20px;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li {
  display: inline-block;
  color: #707070;
  margin-right: 20px;
  font-size: 14px;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li:last-child {
  margin-right: 0;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li i {
  margin-right: 5px;
  color: #ff2d55;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li span {
  color: #00acee;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-content {
  margin-top: 25px;
  display: none;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-content p {
  font-size: 16px;
  margin-bottom: 20px;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-content .location b {
  color: #ff2d55;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-content .location span {
  color: #00acee;
}

.btn-box {
  text-align: center;
  margin-top: 50px;
}

.btn-box .btn {
  margin: 0 15px;
}

/*schedule-style-two*/
.schedule-slides-item .single-schedule {
  position: relative;
  background: #ffffff;
  text-align: center;
  padding: 0 0 0 200px;
}

.schedule-slides-item .single-schedule .schedule-date {
  font-size: 24px;
  color: #ffffff;
  background: #ff2d55;
  text-transform: uppercase;
  padding: 25px 40px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 200px;
}

.schedule-slides-item .single-schedule .schedule-date span {
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  text-transform: capitalize;
  margin-top: 5px;
}

.schedule-slides-item .single-schedule .schedule-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  border-bottom: 1px solid #eeeeee;
  padding: 20px;
  text-align: left;
}

.schedule-slides-item .single-schedule .schedule-content .author {
  max-width: 100px;
  margin-right: 25px;
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(1) {
  -webkit-clip-path: polygon(50% 0%,
      38.11% 6.7%,
      24.99% 6.72%,
      18.06% 18.44%,
      6.7% 25%,
      6.56% 38.64%,
      0% 50%,
      6.7% 61.89%,
      6.7% 75%,
      18.44% 81.94%,
      25% 93.3%,
      38.64% 93.44%,
      50% 100%,
      61.88% 93.3%,
      75% 93.3%,
      81.94% 81.56%,
      93.3% 74.9%,
      93.44% 61.36%,
      100% 50%,
      93.3% 38.11%,
      93.3% 25%,
      81.56% 18.06%,
      74.96% 6.7%,
      61.36% 6.56%,
      50% 0%);
  clip-path: polygon(50% 0%,
      38.11% 6.7%,
      24.99% 6.72%,
      18.06% 18.44%,
      6.7% 25%,
      6.56% 38.64%,
      0% 50%,
      6.7% 61.89%,
      6.7% 75%,
      18.44% 81.94%,
      25% 93.3%,
      38.64% 93.44%,
      50% 100%,
      61.88% 93.3%,
      75% 93.3%,
      81.94% 81.56%,
      93.3% 74.9%,
      93.44% 61.36%,
      100% 50%,
      93.3% 38.11%,
      93.3% 25%,
      81.56% 18.06%,
      74.96% 6.7%,
      61.36% 6.56%,
      50% 0%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(2) {
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(3) {
  -webkit-clip-path: polygon(50% 0%,
      100% 25%,
      100% 75%,
      50% 100%,
      0% 75%,
      0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(4) {
  border-radius: 50%;
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(5) {
  -webkit-clip-path: polygon(50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%);
  clip-path: polygon(50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(6) {
  -webkit-clip-path: polygon(30% 0%,
      70% 0%,
      100% 30%,
      100% 70%,
      70% 100%,
      30% 100%,
      0% 70%,
      0% 30%);
  clip-path: polygon(30% 0%,
      70% 0%,
      100% 30%,
      100% 70%,
      70% 100%,
      30% 100%,
      0% 70%,
      0% 30%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(7) {
  -webkit-clip-path: polygon(50% 0%,
      83% 12%,
      100% 43%,
      94% 78%,
      68% 100%,
      32% 100%,
      6% 78%,
      0% 43%,
      17% 12%);
  clip-path: polygon(50% 0%,
      83% 12%,
      100% 43%,
      94% 78%,
      68% 100%,
      32% 100%,
      6% 78%,
      0% 43%,
      17% 12%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(8) {
  -webkit-clip-path: polygon(50% 0%,
      80% 10%,
      100% 35%,
      100% 70%,
      80% 90%,
      50% 100%,
      20% 90%,
      0% 70%,
      0% 35%,
      20% 10%);
  clip-path: polygon(50% 0%,
      80% 10%,
      100% 35%,
      100% 70%,
      80% 90%,
      50% 100%,
      20% 90%,
      0% 70%,
      0% 35%,
      20% 10%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(9) {
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(10) {
  -webkit-clip-path: polygon(50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%);
  clip-path: polygon(50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%);
}

.schedule-slides-item .single-schedule .schedule-content .author-multi {
  overflow: hidden;
}

.schedule-slides-item .single-schedule .schedule-content .author-multi img {
  width: 47%;
  border-radius: 50%;
  margin-bottom: 0.4rem;
  margin-right: 3%;
  float: left;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info h3 {
  font-size: 18px;
  margin-bottom: 12px;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a {
  color: #0d1028;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a:hover,
.schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a:focus {
  color: #ff2d55;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info ul li {
  display: inline-block;
  color: #707070;
  margin-right: 20px;
  font-size: 14px;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info ul li:last-child {
  margin-right: 0;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info ul li i {
  margin-right: 6px;
  color: #ff2d55;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info ul li:last-child {
  margin-right: 0;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a {
  display: inline-block;
  color: #00acee;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a:hover,
.schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a:focus {
  color: #ff2d55;
}

.schedule-slides-item .single-schedule:nth-child(2) .schedule-date {
  background: #00acee;
}

.schedule-slides-item .single-schedule:nth-child(3) .schedule-date {
  background: #efa506;
}

.schedule-slides-item .single-schedule:nth-child(4) .schedule-date {
  background: #8dc63f;
}

.schedule-slides-item .single-schedule:nth-child(5) .schedule-date {
  background: #00acee;
}

.schedule-slides-item .single-schedule:nth-child(6) .schedule-date {
  background: #efa506;
}

.schedule-slides-item .single-schedule:nth-child(7) .schedule-date {
  background: #8dc63f;
}

.schedule-slides.owl-theme .owl-nav {
  margin-top: 0;
  line-height: 0.01;
}

.schedule-slides.owl-theme .owl-nav [class*="owl-"] {
  color: #0d1028;
  font-size: 25px;
  margin: 0;
  background: transparent;
  border-radius: 0;
  position: absolute;
  left: -25px;
  top: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: 0;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.schedule-slides.owl-theme .owl-nav [class*="owl-"]:hover,
.schedule-slides.owl-theme .owl-nav [class*="owl-"]:focus {
  color: #ff2d55;
}

.schedule-slides.owl-theme .owl-nav [class*="owl-"].owl-next {
  left: auto;
  right: -25px;
}

/*schedule-style-three*/
.schedule-style-three {
  padding-bottom: 90px;
}

.schedule-style-three .col-lg-6:nth-child(2) .single-schedule-item .schedule-date {
  background-color: #00acee;
}

.schedule-style-three .col-lg-6:nth-child(3) .single-schedule-item .schedule-date {
  background-color: #efa506;
}

.schedule-style-three .col-lg-6:nth-child(4) .single-schedule-item .schedule-date {
  background-color: #8dc63f;
}

.schedule-style-three .col-lg-6:nth-child(5) .single-schedule-item .schedule-date {
  background-color: #00acee;
}

.schedule-style-three .col-lg-6:nth-child(6) .single-schedule-item .schedule-date {
  background-color: #efa506;
}

.schedule-style-three .col-lg-6:nth-child(7) .single-schedule-item .schedule-date {
  background-color: #8dc63f;
}

.single-schedule-item {
  text-align: center;
  margin-bottom: 30px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
}

.single-schedule-item .schedule-date {
  font-size: 24px;
  color: #ffffff;
  background: #ff2d55;
  text-transform: uppercase;
  padding: 20px;
}

.single-schedule-item .schedule-date span {
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  text-transform: capitalize;
  margin-top: 5px;
}

.single-schedule-item .schedule-item-wrapper .schedule-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  border-bottom: 1px solid #eeeeee;
  padding: 25px 20px;
  text-align: left;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author {
  max-width: 90px;
  margin-right: 20px;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(1) {
  -webkit-clip-path: polygon(50% 0%,
      38.11% 6.7%,
      24.99% 6.72%,
      18.06% 18.44%,
      6.7% 25%,
      6.56% 38.64%,
      0% 50%,
      6.7% 61.89%,
      6.7% 75%,
      18.44% 81.94%,
      25% 93.3%,
      38.64% 93.44%,
      50% 100%,
      61.88% 93.3%,
      75% 93.3%,
      81.94% 81.56%,
      93.3% 74.9%,
      93.44% 61.36%,
      100% 50%,
      93.3% 38.11%,
      93.3% 25%,
      81.56% 18.06%,
      74.96% 6.7%,
      61.36% 6.56%,
      50% 0%);
  clip-path: polygon(50% 0%,
      38.11% 6.7%,
      24.99% 6.72%,
      18.06% 18.44%,
      6.7% 25%,
      6.56% 38.64%,
      0% 50%,
      6.7% 61.89%,
      6.7% 75%,
      18.44% 81.94%,
      25% 93.3%,
      38.64% 93.44%,
      50% 100%,
      61.88% 93.3%,
      75% 93.3%,
      81.94% 81.56%,
      93.3% 74.9%,
      93.44% 61.36%,
      100% 50%,
      93.3% 38.11%,
      93.3% 25%,
      81.56% 18.06%,
      74.96% 6.7%,
      61.36% 6.56%,
      50% 0%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(2) {
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(3) {
  -webkit-clip-path: polygon(50% 0%,
      100% 25%,
      100% 75%,
      50% 100%,
      0% 75%,
      0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(4) {
  border-radius: 50%;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(5) {
  -webkit-clip-path: polygon(50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%);
  clip-path: polygon(50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(6) {
  -webkit-clip-path: polygon(30% 0%,
      70% 0%,
      100% 30%,
      100% 70%,
      70% 100%,
      30% 100%,
      0% 70%,
      0% 30%);
  clip-path: polygon(30% 0%,
      70% 0%,
      100% 30%,
      100% 70%,
      70% 100%,
      30% 100%,
      0% 70%,
      0% 30%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(7) {
  -webkit-clip-path: polygon(50% 0%,
      83% 12%,
      100% 43%,
      94% 78%,
      68% 100%,
      32% 100%,
      6% 78%,
      0% 43%,
      17% 12%);
  clip-path: polygon(50% 0%,
      83% 12%,
      100% 43%,
      94% 78%,
      68% 100%,
      32% 100%,
      6% 78%,
      0% 43%,
      17% 12%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(8) {
  -webkit-clip-path: polygon(50% 0%,
      80% 10%,
      100% 35%,
      100% 70%,
      80% 90%,
      50% 100%,
      20% 90%,
      0% 70%,
      0% 35%,
      20% 10%);
  clip-path: polygon(50% 0%,
      80% 10%,
      100% 35%,
      100% 70%,
      80% 90%,
      50% 100%,
      20% 90%,
      0% 70%,
      0% 35%,
      20% 10%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(9) {
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(10) {
  -webkit-clip-path: polygon(50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%);
  clip-path: polygon(50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author-multi {
  overflow: hidden;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author-multi img {
  width: 47%;
  border-radius: 50%;
  margin-bottom: 0.4rem;
  margin-right: 3%;
  float: left;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info h3 {
  font-size: 18px;
  margin-bottom: 12px;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info h3 a {
  color: #0d1028;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info h3 a:hover,
.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info h3 a:focus {
  color: #ff2d55;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li {
  display: inline-block;
  color: #707070;
  margin-right: 20px;
  line-height: 1.9;
  font-size: 14px;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li i {
  margin-right: 5px;
  color: #ff2d55;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li .icofont-user-suited {
  margin-right: 8px;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li:last-child {
  margin-right: 0;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li a {
  display: inline-block;
  color: #00acee;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li a:hover,
.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li a:focus {
  color: #ff2d55;
}

.single-schedule-item .schedule-item-wrapper .schedule-content:last-child {
  border-bottom: none;
}

/*schedule-style-four*/
.schedule-style-four .tab {
  background-color: transparent;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.schedule-style-four .tabs {
  display: block;
}

.schedule-style-four .tabs li::before {
  left: -15px;
  bottom: 37%;
  margin: 0 0 0;
  right: auto;
}

.schedule-style-four .tab_content {
  -webkit-box-shadow: 0 0 10px 0 rgba(137, 173, 255, 0.35);
  box-shadow: 0 0 10px 0 rgba(137, 173, 255, 0.35);
}

/*================================================
FunFacts CSS
=================================================*/

/*================================================
Pricing CSS
=================================================*/
.pricing-area {
  position: relative;
  z-index: 1;
}

.pricing-area.bg-image {
  background-image: url("../images/bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.pricing-table-box {
  padding-bottom: 30px;
  background: #ffffff;
  text-align: center;
  z-index: 1;
  position: relative;
  border-radius: 5px;
  background-image: url("../images/patt.png");
  background-position: center center;
  overflow: hidden;
  -webkit-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.pricing-table-box .pricingTable-header {
  padding: 30px 15px 10px;
  background: #ff2d55;
  -webkit-clip-path: polygon(50% 100%, 100% 60%, 100% 0, 0 0, 0 60%);
  clip-path: polygon(50% 100%, 100% 60%, 100% 0, 0 0, 0 60%);
  position: relative;
}

.pricing-table-box .pricingTable-header::before {
  content: "";
  width: 400px;
  height: 400px;
  border-radius: 50%;
  position: absolute;
  right: -50%;
  top: -130%;
  background: repeating-radial-gradient(rgba(255, 255, 255, 0.04),
      rgba(255, 255, 255, 0.17) 20%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.pricing-table-box .title {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.pricing-table-box .price-value {
  display: block;
  font-size: 55px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
}

.pricing-table-box .price-value sup {
  font-size: 23px;
  top: -25px;
}

.pricing-table-box .pricing-content {
  padding: 35px 0;
  margin-bottom: 0;
  list-style-type: none;
}

.pricing-table-box .pricing-content li {
  color: #707070;
  text-transform: capitalize;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 12px;
  padding-bottom: 12px;
}

.pricing-table-box .pricing-content li:last-child {
  margin-bottom: 0;
}

.pricing-table-box .pricing-content li:first-child {
  border-top: 1px solid #eeeeee;
  padding-top: 12px;
}

.pricing-table-box:hover,
.pricing-table-box:focus,
.pricing-table-box.active {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.pricing-table-box:hover .pricingTable-header::before,
.pricing-table-box:focus .pricingTable-header::before,
.pricing-table-box.active .pricingTable-header::before {
  right: 50%;
}

/*pricing-style-two*/
.pricing-plan {
  position: relative;
  -webkit-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  text-align: center;
  padding-bottom: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #ffffff;
}

.pricing-plan-top {
  border-radius: 6px 6px 0px 0px;
  padding: 30px 15px;
  margin-bottom: 60px;
  background-color: #ff2d55;
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  height: 200px;
}

.popular_plan_div {
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 5px 0px 10px 0px;
  background-color: #e02862;
  border: none;
  padding: 6px 20px;

}

.popular-plan-text {
  font-size: 13px;
  color: rgb(233, 233, 233);
  text-transform: uppercase;
  font-weight: 500;
}

.pricing-plan h3 {
  color: #ffffff;
  font-size: 28px;
  font-weight: 500;
  text-transform: uppercase;
}

.pricing-plan h3 span {
  display: block;
  color: #ff2d55;
  background: #ffffff;
  height: 150px;
  width: 150px;
  line-height: 155px;
  font-size: 26px;
  -webkit-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  margin: 30px auto -95px;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  /* font-weight: 600; */
}

.pricing-plan h3 p {
  line-height: 100px;
  padding: 0px 0px 0px 0px;
  color: #ff2d55;
  font-size: 12px;
  font-weight: 500;
}

.pricing-plan h3 span sup {
  font-weight: 500;
  font-size: 25px;
}

.pricing-plan .pricing-content {
  padding: 0 15px 20px 15px;
  margin-bottom: 0;
  list-style-type: none;
  /* height: 280px; */
  min-height: 280PX;
  height: auto;
}

.pricing-plan .pricing-content li {
  color: #303030;
  /* text-transform: capitalize; */
  /* border-bottom: 1px solid #eeeeee; */
  margin-bottom: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  text-align: left;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-wrap: break-word;
  overflow-wrap: break-word;
}

.pricing-plan .pricing-content li:last-child {
  margin-bottom: 0;
}

.pricing-plan .pricing-content li:first-child {
  /* border-top: 1px solid #eeeeee; */
  padding-top: 12px;
}

.pricing-plan:hover,
.pricing-plan:focus {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

/* Prime  */
.prime-pricing-plan {
  position: relative;
  -webkit-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  text-align: center;
  padding-bottom: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #ffffff;
}

.prime-pricing-plan h3 {
  font-size: 24px;
  font-weight: 500;
  padding: 30px 15px;
  margin-bottom: 100px;
  background-image: url('../../assets/images/prime-background.jpg');
  background-size: cover;
  /* background-color: #dabb0f; */
  border-radius: 6px 6px 0px 0px;
  border: solid 1px #cfb41a52;
  color: #a85202;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.prime-pricing-plan h3 span {
  display: block;
  color: #ff2d55;
  background: #ffffff;
  height: 150px;
  width: 150px;
  line-height: 155px;
  font-size: 25px;
  -webkit-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  margin: 30px auto -95px;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.prime-pricing-plan h3 span sup {
  font-weight: 500;
}

.prime-pricing-plan .pricing-content {
  padding: 0 0 35px;
  margin-bottom: 0;
  list-style-type: none;
}

.prime-pricing-plan .pricing-content li {
  color: #707070;
  text-transform: capitalize;
  /* border-bottom: 1px solid #eeeeee; */
  margin-bottom: 12px;
  padding-bottom: 12px;
}

.prime-pricing-plan .pricing-content li:last-child {
  margin-bottom: 0;
}

.prime-pricing-plan .pricing-content li:first-child {
  border-top: 1px solid #eeeeee;
  padding-top: 12px;
}

.prime-pricing-plan:hover,
.prime-pricing-plan:focus {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.col-lg-4:nth-child(2) .pricing-table-box .pricingTable-header {
  background: #00acee;
}

.col-lg-4:nth-child(2) .pricing-table-box .btn-primary::before,
.col-lg-4:nth-child(2) .pricing-table-box .btn-primary::after {
  background: #00acee;
}

.col-lg-4:nth-child(2) .pricing-plan-top {
  background: #00acee;
}

/* .col-lg-4:nth-child(2) .pricing-plan h3 {
  background: #00acee;
} */

.col-lg-4:nth-child(2) .pricing-plan h3 span {
  color: #00acee;
}

.col-lg-4:nth-child(2) .pricing-plan h3 p {
  color: #00acee;
}

.col-lg-4:nth-child(2) .pricing-plan .btn-primary::before,
.col-lg-4:nth-child(2) .pricing-plan .btn-primary::after {
  background: #00acee;
}

.col-lg-4:nth-child(3) .pricing-table-box .pricingTable-header {
  background: #8dc63f;
}

.col-lg-4:nth-child(3) .pricing-table-box .btn-primary::before,
.col-lg-4:nth-child(3) .pricing-table-box .btn-primary::after {
  background: #8dc63f;
}

.col-lg-4:nth-child(3) .pricing-plan-top {
  background: #8dc63f;
}

/* .col-lg-4:nth-child(3) .pricing-plan h3 {
 background: #8dc63f;
} */

.col-lg-4:nth-child(3) .pricing-plan h3 span {
  color: #8dc63f;
}

.col-lg-4:nth-child(3) .pricing-plan h3 p {
  color: #8dc63f;
}

.col-lg-4:nth-child(3) .pricing-plan .btn-primary::before,
.col-lg-4:nth-child(3) .pricing-plan .btn-primary::after {
  background: #8dc63f;
}

/*================================================
Blog CSS
=================================================*/
.blog-area {
  position: relative;
  z-index: 1;
}

.blog-area.bg-image {
  background-image: url("../images/bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.single-blog-post {
  margin-bottom: 30px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.06);
}

.single-blog-post .blog-image {
  position: relative;
  overflow: hidden;
}

.single-blog-post .blog-image a {
  display: block;
}

.single-blog-post .blog-image a img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-blog-post .blog-image .post-tag {
  position: absolute;
  right: 0;
  top: 60px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-blog-post .blog-image .post-tag a {
  background: #ff2d55;
  color: #ffffff;
  padding: 9px 28px;
}

.single-blog-post .blog-post-content {
  padding: 25px;
}

.single-blog-post .blog-post-content .date {
  color: #8dc63f;
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-blog-post .blog-post-content h3 {
  margin-top: 13px;
  margin-bottom: 15px;
  line-height: 30px;
  font-size: 22px;
}

.single-blog-post .blog-post-content h3 a {
  color: #0d1028;
}

.single-blog-post .blog-post-content h3 a:hover,
.single-blog-post .blog-post-content h3 a:focus {
  color: #ff2d55;
}

.single-blog-post .blog-post-content p {
  margin-bottom: 15px;
}

.single-blog-post .read-more-btn {
  position: relative;
  font-weight: 500;
  color: #ff2d55;
}

.single-blog-post .read-more-btn:hover {
  color: #00acee;
  letter-spacing: 1px;
}

.single-blog-post:hover .blog-image a img,
.single-blog-post:focus .blog-image a img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.single-blog-post:hover .blog-image .post-tag,
.single-blog-post:focus .blog-image .post-tag {
  top: 70px;
}

.single-blog-post:hover .blog-image .post-tag a,
.single-blog-post:focus .blog-image .post-tag a {
  background: #8dc63f;
  color: #ffffff;
}

.blog-slides.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 25px;
  line-height: 0.01;
}

/*blog-style-two*/
.blog-section {
  padding-bottom: 90px;
}

.single-blog-card {
  position: relative;
  margin-bottom: 30px;
}

.single-blog-card>a {
  display: block;
}

.single-blog-card>a img {
  width: 100%;
}

.single-blog-card::before {
  opacity: 0.6;
  background: #0d1028;
  content: "";
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  right: 0;
  position: absolute;
  width: 100%;
}

.single-blog-card .post-tag {
  position: absolute;
  right: 0;
  top: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 2;
}

.single-blog-card .post-tag a {
  background: #ff2d55;
  color: #ffffff;
  padding: 9px 28px;
}

.single-blog-card .blog-post-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px 30px 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 2;
}

.single-blog-card .blog-post-content h3 {
  font-size: 22px;
  margin-bottom: 15px;
  line-height: 1.5;
}

.single-blog-card .blog-post-content h3 a {
  color: #ffffff;
}

.single-blog-card .blog-post-content h3 a:hover,
.single-blog-card .blog-post-content h3 a:focus {
  color: #ff2d55;
}

.single-blog-card .blog-post-content p {
  color: #ffffff;
}

.single-blog-card .blog-post-content .read-more-btn {
  position: relative;
  font-weight: 500;
  opacity: 0;
  visibility: hidden;
  color: #ff2d55;
}

.single-blog-card .blog-post-content .read-more-btn:hover {
  color: #00acee;
  letter-spacing: 1px;
}

.single-blog-card:hover .post-tag,
.single-blog-card:focus .post-tag {
  top: 40px;
}

.single-blog-card:hover .post-tag a,
.single-blog-card:focus .post-tag a {
  background: #8dc63f;
  color: #ffffff;
}

.single-blog-card:hover .blog-post-content,
.single-blog-card:focus .blog-post-content {
  padding-bottom: 30px;
}

.single-blog-card:hover .blog-post-content .read-more-btn,
.single-blog-card:focus .blog-post-content .read-more-btn {
  opacity: 1;
  visibility: visible;
}

/*================================================
Buy Tickets CSS
=================================================*/
.buy-tickets-area {
  position: relative;
  z-index: 1;
  background-image: url("../images/buy-tickets-bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.buy-tickets-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #0d1028;
  opacity: 0.88;
}

.buy-tickets-area.ptb-120 {
  padding-bottom: 200px;
}

.buy-tickets .section-title {
  margin-bottom: 0;
}

.buy-tickets .section-title h2 {
  color: #ffffff;
}

.buy-tickets .section-title p {
  color: #ffffff;
  margin: 0;
}

.buy-tickets .buy-ticket-btn {
  text-align: right;
}

/*================================================
Subscribe CSS
=================================================*/
.subscribe-area {
  text-align: center;
  position: relative;
  margin-top: -80px;
  z-index: 2;
}

.subscribe-area::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 72%;
  background: #0d1028;
  content: "";
  z-index: -1;
}

.subscribe-area .subscribe-inner {
  padding: 50px;
  -webkit-transform: unset !important;
  transform: unset !important;
  background-image: url("../images/color-bg.jpg");
  background-repeat: no-repeat;
}

.subscribe-area .subscribe-inner span {
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
}

.subscribe-area .subscribe-inner h2 {
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 0;
  color: #ffffff;
}

.subscribe-area .subscribe-inner .newsletter-form {
  max-width: 650px;
  position: relative;
  margin: 35px auto 0;
}

.subscribe-area .subscribe-inner .newsletter-form .form-control {
  border: none;
  height: 60px;
  -webkit-box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4);
  box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4);
}

.subscribe-area .subscribe-inner .newsletter-form .btn {
  position: absolute;
  right: 0;
  top: 0;
  text-transform: uppercase;
  height: 60px;
}

.subscribe-area .subscribe-inner .newsletter-form .btn:after,
.subscribe-area .subscribe-inner .newsletter-form .btn::before {
  background: #00acee;
}

/*================================================
Page Title CSS
=================================================*/
.page-title-area {
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 250px;
  padding-bottom: 180px;
}

.page-title-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #0d1028;
  opacity: 0.88;
}

.page-title-area h1 {
  color: #ffffff;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 40px;
}

.page-title-area span {
  display: block;
  color: #ffffff;
  margin-top: 10px;
  font-size: 17px;
}

.page-title-area ul {
  padding: 0;
  margin: 12px 0 0;
  list-style-type: none;
}

.page-title-area ul li {
  display: inline-block;
  position: relative;
  color: #ff2d55;
  font-size: 16px;
  margin: 0 13px;
}

.page-title-area ul li a {
  color: #ffffff;
}

.page-title-area ul li a:hover,
.page-title-area ul li a:focus {
  color: #00acee;
}

.page-title-area ul li::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 50%;
  width: 2px;
  height: 15px;
  background: #efa506;
  margin-top: -9px;
  -webkit-transform: rotate(10deg);
  transform: rotate(10deg);
}

.page-title-area ul li:first-child {
  margin-left: 0;
}

.page-title-area ul li:first-child::before {
  display: none;
}

.page-title-area ul li:last-child {
  margin-right: 0;
}

/*================================================
Login CSS
=================================================*/

/*================================================
Signup CSS
=================================================*/

/*================================================
404 Error CSS
=================================================*/
.error-area {
  background-color: #f1f1f1;
  padding: 30px 15px;
  height: 100vh;
  text-align: center;
  z-index: 10000;
  position: relative;
}

.error-area h1 {
  font-size: 150px;
  font-weight: bold;
  line-height: 110px;
  margin: 0;
  color: #ff2d55;
}

.error-area h1 span {
  color: #efa506;
}

.error-area h1 b {
  font-weight: unset;
  color: #8dc63f;
}

.error-area h3 {
  margin: 35px 0 15px;
}

.error-area p {
  margin: 10px 0 20px;
}

/*================================================
FAQ CSS
=================================================*/
.faq-accordion {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 60px;
  background: #ffffff;
  border-radius: 10px;
}

.faq-accordion .accordion {
  border: none;
  border-radius: 0;
}

.faq-accordion .accordion__item {
  margin-bottom: 15px;
}

.faq-accordion .accordion__item .accordion__button {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  color: #0d1028;
  outline: 0;
  font-weight: 600;
  font-size: 18px;
}

.faq-accordion .accordion__item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion__item+.accordion__item {
  border: none;
}

.faq-contact {
  margin-top: 45px;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 60px;
  text-align: center;
  border-radius: 4px;
}

.faq-contact h3 {
  font-size: 25px;
  margin-bottom: 35px;
}

.faq-contact .form-group {
  margin-bottom: 25px;
}

.faq-contact .form-control {
  background-color: #f5f5f5;
}

/*================================================
Coming Soon CSS
=================================================*/
.coming-soon {
  position: relative;
  z-index: 1000;
  height: 100vh;
  text-align: center;
  background-image: url("../images/main-bg3.jpg");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.coming-soon::before {
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #0d1028;
  opacity: 0.66;
  position: absolute;
  content: "";
}

.coming-soon .coming-soon-content {
  max-width: 650px;
  margin: 0 auto;
}

.coming-soon .coming-soon-content h1 {
  color: #ffffff;
  font-size: 45px;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 57px;
}

.coming-soon .coming-soon-content p {
  color: #c7c3c7;
  margin-top: 20px;
  margin-bottom: 35px;
}

.coming-soon .coming-soon-content form {
  position: relative;
}

.coming-soon .coming-soon-content form .form-control {
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50px;
  color: #ffffff;
  height: 55px;
  padding-left: 20px;
}

.coming-soon .coming-soon-content form .form-control:focus,
.coming-soon .coming-soon-content form .form-control:hover {
  border-color: #ff2d55;
}

.coming-soon .coming-soon-content form .form-control::-webkit-input-placeholder {
  color: #c7c3c7;
}

.coming-soon .coming-soon-content form .form-control:-ms-input-placeholder {
  color: #c7c3c7;
}

.coming-soon .coming-soon-content form .form-control::-ms-input-placeholder {
  color: #c7c3c7;
}

.coming-soon .coming-soon-content form .form-control::placeholder {
  color: #c7c3c7;
}

.coming-soon .coming-soon-content form button {
  position: absolute;
  right: -1px;
  height: 100%;
  background: #ff2d55;
  top: 0;
  color: #ffffff;
  border: none;
  width: 130px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 0 50px 50px 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: 0 !important;
  border: none;
}

.coming-soon .coming-soon-content form button:hover,
.coming-soon .coming-soon-content form button:focus {
  background: #00acee;
}

.coming-soon .coming-soon-content #timer {
  margin-bottom: 35px;
}

.coming-soon .coming-soon-content #timer div {
  display: inline-block;
  font-size: 45px;
  font-weight: 700;
  color: #ffffff;
  width: 135px;
  height: 135px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  padding-top: 34px;
  line-height: 40px;
  margin: 0 10px;
}

.coming-soon .coming-soon-content #timer span {
  display: block;
  font-size: 16px;
  font-weight: 400;
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
  margin-top: 30px;
}

.pagination-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.pagination-area ul li a {
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0 5px;
  background-color: #ffffff;
  border-radius: 0 !important;
  text-align: center;
  line-height: 40px;
  color: #0d1028;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
  box-shadow: 0 2px 10px 0 #d8dde6;
  border: none;
}

.pagination-area ul li a.active,
.pagination-area ul li a:hover,
.pagination-area ul li a:focus {
  background: #ff2d55;
  color: #ffffff;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

/*================================================
Blog Details CSS
=================================================*/
.blog-details .post-image {
  margin-bottom: 35px;
}

.blog-details h3 {
  font-size: 25px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.blog-details .blog-meta ul {
  padding: 0;
  margin: 15px 0;
  list-style-type: none;
}

.blog-details .blog-meta ul li {
  display: inline-block;
  position: relative;
  color: #707070;
  font-size: 14px;
  margin-left: 9px;
  margin-right: 9px;
}

.blog-details .blog-meta ul li i {
  margin-right: 4px;
  color: #ff2d55;
}

.blog-details .blog-meta ul li::before {
  width: 2px;
  height: 14px;
  background: #707070;
  left: -10px;
  top: 50%;
  position: absolute;
  content: "";
  margin-top: -6px;
  -webkit-transform: rotate(11deg);
  transform: rotate(11deg);
}

.blog-details .blog-meta ul li a {
  display: inline-block;
  color: #707070;
}

.blog-details .blog-meta ul li a:hover,
.blog-details .blog-meta ul li a:focus {
  color: #00acee;
}

.blog-details .blog-meta ul li:last-child {
  margin-right: 0;
}

.blog-details .blog-meta ul li:first-child {
  margin-left: 0;
}

.blog-details .blog-meta ul li:first-child::before {
  display: none;
}

.blog-details p {
  margin-top: 15px;
  margin-bottom: 0;
}

.blog-details p:last-child {
  margin-bottom: 0;
}

.blog-details blockquote,
.blog-details .blockquote {
  background: #f6f6f6 none repeat scroll 0 0;
  padding: 35px 20px 35px 100px;
  position: relative;
  margin-top: 25px;
  margin-bottom: 25px;
}

.blog-details blockquote::before,
.blog-details .blockquote::before {
  font-family: IcoFont !important;
  position: absolute;
  content: "\efcd";
  left: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 55px;
  color: #ff2d55;
}

.blog-details blockquote p,
.blog-details .blockquote p {
  color: #0d1028;
  font-style: italic;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.post-tag-media {
  background-color: #f5f7fb;
  padding: 20px;
  margin-top: 25px;
  margin-bottom: 35px;
}

.post-tag-media ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.post-tag-media ul li {
  display: inline-block;
}

.post-tag-media ul li span {
  font-weight: 600;
  margin-right: 5px;
  display: inline-block;
}

.post-tag-media ul li a {
  color: #707070;
  margin-right: 4px;
}

.post-tag-media ul li a:hover,
.post-tag-media ul li a:focus {
  color: #ff2d55;
}

.post-tag-media ul.social-share {
  text-align: right;
}

.post-tag-media ul.social-share li {
  padding-left: 5px;
}

.comments-area .comments-title,
.comments-area .comment-reply-title {
  font-size: 22px;
  margin-bottom: 25px;
}

.comments-area .comment-reply-title {
  margin-bottom: 8px;
}

.comments-area ol,
.comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area ol li ol li,
.comments-area ol li ul li,
.comments-area ul li ol li,
.comments-area ul li ul li {
  margin-top: 15px;
  margin-left: 35px;
  margin-bottom: 15px;
}

.comments-area ol li .comment-body,
.comments-area ul li .comment-body {
  background: #f7f7ff;
  padding: 25px 20px;
  position: relative;
}

.comments-area ol li .comment-body .comment-meta,
.comments-area ul li .comment-body .comment-meta {
  position: relative;
  padding-left: 85px;
  padding-top: 8px;
}

.comments-area ol li .comment-body .comment-meta .comment-author img,
.comments-area ul li .comment-body .comment-meta .comment-author img {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  max-width: 68px;
}

.comments-area ol li .comment-body .comment-meta .comment-author .fn,
.comments-area ul li .comment-body .comment-meta .comment-author .fn {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}

.comments-area ol li .comment-body .comment-meta .comment-author .says,
.comments-area ul li .comment-body .comment-meta .comment-author .says {
  display: none;
}

.comments-area ol li .comment-body .comment-meta .comment-metadata a,
.comments-area ul li .comment-body .comment-meta .comment-metadata a {
  display: inline-block;
  color: #707070;
  font-size: 14px;
}

.comments-area ol li .comment-body .comment-meta .comment-metadata a:hover,
.comments-area ol li .comment-body .comment-meta .comment-metadata a:focus,
.comments-area ul li .comment-body .comment-meta .comment-metadata a:hover,
.comments-area ul li .comment-body .comment-meta .comment-metadata a:focus {
  color: #ff2d55;
}

.comments-area ol li .comment-body .comment-content,
.comments-area ul li .comment-body .comment-content {
  margin-top: 25px;
}

.comments-area ol li .comment-body .reply,
.comments-area ul li .comment-body .reply {
  position: absolute;
  right: 30px;
  top: 30px;
}

.comments-area ol li .comment-body .reply a,
.comments-area ul li .comment-body .reply a {
  display: inline-block;
  background: #0d1028;
  padding: 5px 15px;
  color: #ffffff;
}

.comments-area ol li .comment-body .reply a:hover,
.comments-area ol li .comment-body .reply a:focus,
.comments-area ul li .comment-body .reply a:hover,
.comments-area ul li .comment-body .reply a:focus {
  background: #ff2d55;
}

.comments-area .comment-respond {
  margin-top: 30px;
}

.comments-area .comment-respond .comment-notes {
  font-size: 14px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form p {
  margin-bottom: 25px;
}

.comments-area .comment-respond .comment-form-comment input,
.comments-area .comment-respond .comment-form-comment textarea,
.comments-area .comment-respond .comment-form-author input,
.comments-area .comment-respond .comment-form-author textarea,
.comments-area .comment-respond .comment-form-email input,
.comments-area .comment-respond .comment-form-email textarea,
.comments-area .comment-respond .comment-form-url input,
.comments-area .comment-respond .comment-form-url textarea {
  display: block;
  width: 100%;
  height: 50px;
  background-color: #f5f5f5;
  outline: 0 !important;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  border: 1px solid #eeeeee;
  padding: 10px 20px;
  border-radius: 6px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.comments-area .comment-respond .comment-form-comment input:focus,
.comments-area .comment-respond .comment-form-comment textarea:focus,
.comments-area .comment-respond .comment-form-author input:focus,
.comments-area .comment-respond .comment-form-author textarea:focus,
.comments-area .comment-respond .comment-form-email input:focus,
.comments-area .comment-respond .comment-form-email textarea:focus,
.comments-area .comment-respond .comment-form-url input:focus,
.comments-area .comment-respond .comment-form-url textarea:focus {
  border-color: #ff2d55;
}

.comments-area .comment-respond .comment-form-comment textarea,
.comments-area .comment-respond .comment-form-author textarea,
.comments-area .comment-respond .comment-form-email textarea,
.comments-area .comment-respond .comment-form-url textarea {
  height: auto;
}

.comments-area .comment-respond .form-submit {
  margin: 0 !important;
}

.comments-area .comment-respond .form-submit input {
  display: inline-block;
  background: #ff2d55;
  border: none;
  color: #ffffff;
  padding: 13px 25px;
  text-transform: uppercase;
  font-weight: 600;
  outline: 0 !important;
  margin-top: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.comments-area .comment-respond .form-submit input:hover,
.comments-area .comment-respond .form-submit input:focus {
  background: #0d1028;
  -webkit-box-shadow: 0 13px 27px 0 rgba(255, 82, 2, 0.11);
  box-shadow: 0 13px 27px 0 rgba(255, 82, 2, 0.11);
}

/*================================================
Sidebar CSS
=================================================*/
.sidebar .widget {
  margin-bottom: 40px;
}

.sidebar .widget:last-child {
  margin-bottom: 0;
}

.sidebar .widget .widget-title {
  font-size: 22px;
  margin-bottom: 25px;
  position: relative;
}

.sidebar .widget .widget-title::before {
  content: "";
  position: absolute;
  right: 0;
  top: 2px;
  width: 17px;
  height: 17px;
  background: #ff2d55;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sidebar .widget:hover .widget-title::before,
.sidebar .widget:focus .widget-title::before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sidebar .widget.widget_search {
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  padding: 15px;
}

.sidebar .widget.widget_search form {
  position: relative;
}

.sidebar .widget.widget_search form .form-control {
  background: transparent;
}

.sidebar .widget.widget_search form button {
  position: absolute;
  right: 5px;
  top: 0;
  height: 100%;
  border: none;
  outline: 0;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  background: transparent;
  color: #ff2d55;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sidebar .widget.widget_search form button:hover,
.sidebar .widget.widget_search form button:focus {
  color: #00acee;
}

.sidebar .widget.widget_categories ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.sidebar .widget.widget_categories ul li {
  position: relative;
  border-top: 1px solid #eeeeee;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
}

.sidebar .widget.widget_categories ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 10px;
  height: 10px;
  background: #ff2d55;
  margin-top: -5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sidebar .widget.widget_categories ul li:last-child {
  /* border-bottom: 1px solid #eeeeee; */
}

.sidebar .widget.widget_categories ul li a {
  color: #707070;
}

.sidebar .widget.widget_categories ul li a:hover {
  color: #ff2d55;
}

.sidebar .widget.widget_categories ul li:hover::before {
  border-radius: 50%;
}

.sidebar .widget.widget_recent_entries ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.sidebar .widget.widget_recent_entries ul li {
  position: relative;
  padding-left: 100px;
  margin-bottom: 15px;
  padding-right: 20px;
}

.sidebar .widget.widget_recent_entries ul li a {
  display: block;
}

.sidebar .widget.widget_recent_entries ul li a img {
  position: absolute;
  left: 0;
  top: 3px;
  width: 85px;
  height: 65px;
}

.sidebar .widget.widget_recent_entries ul li h5 {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 2px;
  font-weight: 600;
}

.sidebar .widget.widget_recent_entries ul li h5 a {
  color: #0d1028;
  display: inline-block;
}

.sidebar .widget.widget_recent_entries ul li h5 a:hover,
.sidebar .widget.widget_recent_entries ul li h5 a:focus {
  color: #ff2d55;
}

.sidebar .widget.widget_recent_entries ul li:last-child {
  margin-bottom: 0;
}

.sidebar .widget.widget_tag_cloud .tagcloud a {
  font-size: 14px !important;
  border: 1px dashed #eeeeee;
  padding: 6px 20px;
  margin-top: 5px;
  color: #707070;
  display: inline-block;
  background: #f5f5f5;
  margin-right: 7px;
  border-radius: 30px;
  margin-bottom: 5px;
}

.sidebar .widget.widget_tag_cloud .tagcloud a:hover {
  background: #ff2d55;
  color: #ffffff;
  border-color: #ff2d55;
}

.sidebar .widget.widget_archive ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.sidebar .widget.widget_archive ul li {
  position: relative;
  border-top: 1px solid #eeeeee;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
}

.sidebar .widget.widget_archive ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 10px;
  height: 10px;
  background: #ff2d55;
  margin-top: -5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sidebar .widget.widget_archive ul li:last-child {
  /* border-bottom: 1px solid #eeeeee; */
}

.sidebar .widget.widget_archive ul li a {
  color: #707070;
  display: inline-block;
}

.sidebar .widget.widget_archive ul li a:hover {
  color: #ff2d55;
}

.sidebar .widget.widget_archive ul li:hover::before {
  border-radius: 50%;
}

/*================================================
Contact Us CSS
=================================================*/
.contact-box {
  background: #ffffff;
  padding: 30px 20px 30px 90px;
  border-radius: 5px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
}

.contact-box .icon {
  position: absolute;
  left: 20px;
  top: 30px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 47px;
  border: 1px dashed #ff2d55;
  border-radius: 50%;
  color: #ff2d55;
  font-size: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.contact-box h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.contact-box .content p {
  margin-bottom: 0;
}

.contact-box .content p a {
  color: #707070;
}

.contact-box .content p a:hover,
.contact-box .content p a:focus {
  color: #ff2d55;
}

.contact-box .content p:last-child {
  margin-bottom: 0;
}

.contact-box:hover,
.contact-box:focus {
  -webkit-box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.contact-box:hover .icon,
.contact-box:focus .icon {
  background: #ff2d55;
  color: #ffffff;
}

#map {
  height: 400px;
  width: 100%;
}

.contact-form {
  margin-top: 50px;
}

.leave-your-message h3 {
  margin-bottom: 12px;
  font-size: 22px;
}

.leave-your-message .stay-connected {
  margin-top: 25px;
}

.leave-your-message .stay-connected h3 {
  margin-bottom: 20px;
}

.leave-your-message .stay-connected ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.leave-your-message .stay-connected ul li {
  margin-bottom: 10px;
}

.leave-your-message .stay-connected ul li:last-child {
  margin-bottom: 0;
}

.leave-your-message .stay-connected ul li a {
  font-weight: 500;
  display: inline-block;
  color: #707070;
}

.leave-your-message .stay-connected ul li a i {
  width: 40px;
  height: 40px;
  text-align: center;
  display: inline-block;
  line-height: 40px;
  border: 1px solid;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 15px;
}

.leave-your-message .stay-connected ul li a:hover,
.leave-your-message .stay-connected ul li a:focus {
  color: #ff2d55;
}

#contactForm {
  background: #ffffff;
  padding: 30px;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
}

#contactForm label {
  font-weight: 500;
}

#contactForm .form-control {
  background-color: #f1f1f1;
}

#contactForm ul {
  padding: 0;
  margin: 0;
}

#contactForm ul li {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

#contactForm .text-danger,
#contactForm .text-success {
  margin-bottom: 0;
  margin-top: 20px;
}

#contactForm .h4 {
  font-size: 15px;
}

/*================================================
Footer CSS
=================================================*/

/*================================================
Back To Top CSS
=================================================*/
.back-to-top {
  position: fixed;
  right: 1.5%;
  bottom: 15px;
  height: 15px;
  cursor: pointer;
  width: 40px;
  padding: 3px 5px;
  font-size: 10px;
  font-weight: bold;
  color: transparent;
  text-align: center;
  z-index: 3;
  outline: 0 !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #00acee;
}

.back-to-top::before,
.back-to-top::after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top: 0;
}

.back-to-top::before {
  top: -20px;
  z-index: 4;
  border-bottom: 20px solid #00acee;
}

.back-to-top::after {
  bottom: 0;
  z-index: 5;
  border-bottom: 20px solid #ff2d55;
}

.back-to-top:hover,
.back-to-top:focus {
  opacity: 1;
  text-decoration: none;
  height: 40px;
  color: #ffffff;
}

.back-to-top.show-back-to-top {
  display: block;
  bottom: 1.25%;
  visibility: visible;
}

:invalid {
  -webkit-box-shadow: none;
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

.modal-video {
  position: absolute !important;
}



/* ========================================= */
/* YourEvents */
/* ========================================= */

/* ======================================= */
/* newevent */
/* ======================================= */

/* =================================== */
/* Event detail  */
/* =================================== */


body>.skiptranslate>iframe.skiptranslate,
.VIpgJd-yAWNEb-L7lbkb {
  display: none !important;
  visibility: hidden !important;
}

#google_translate_element {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#google_translate_element div span {
  display: none !important;
  visibility: hidden !important;
}


.goog-te-gadget {
  color: #6660;
}

@media only screen and (max-width: 1600px) {
  .goog-te-combo {
    width: 150px;
    padding: 11px 12px 10px;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    color: #fff;
    border: none;
  }
}

@media only screen and (min-width: 1601px) {
  .goog-te-combo {
    width: 180px;
    padding: 11px 16px 10px;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    border: none;
  }
}

.error {
  color: red;
  font-size: small;
}

.asterisk {
  color: red;
  font-size: medium;
}

/* ============================ */
/* loader  */
/* ============================ */
.loader-main-div {
  height: 100vh;
}

.loader-div {
  height: 100%;
}

/* FAQ page */
.cw-fix {
  max-width: 800px !important;
  margin: auto;
  width: 100%;
  height: 700px;
  overflow-y: scroll;
  padding: 10px 0px;
}


.cw-section {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.faq-top {
  text-align: center;
}

.faq-top p {
  margin: 0px;
}

.cw-section__title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 0px;
  color: #332470;
}

.cw-section__title span {
  color: #6A49F2;
}

.cw-accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}

.cw-accordion__item {
  padding: 0.8rem 1rem;
  background: #fff;
  position: relative;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 4px 10px #ede9fe;
  border: #cdcdcd 1px solid;
}

.cw-accordion__item .cw-label {
  padding-left: 26px;
  font-size: 20px;
  position: relative;
  width: 100%;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
}

.cw-accordion__item .cw-label:after {
  position: absolute;
  left: 0;
  content: "+";
  font-size: 14px;
  top: 3px;
  border: #000 1px solid;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  line-height: 1.1;
  text-align: center;
}

.cw-accordion__item .cw-label.cw-open:after {
  transform: rotate(45deg);
}

.cw-accordion__item .cw-acordion-cont {
  height: 0px;
  overflow: hidden;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  font-size: 15px;
  padding-left: 25px;
  padding-right: 25px;
}

.cw-accordion__item .cw-acordion-cont ul {
  padding-left: 20px;
}

.cw-accordion__item .cw-acordion-cont ul li {
  margin: 7px 0px;
}

.cw-accordion__item .cw-open+.cw-acordion-cont {
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

.faq-contact {
  text-align: center;
  padding: 10px 0;
}

.faq-contact h3 {
  margin: 0px 0px 15px 0px;
}

.faq-contact .contact-btn {
  padding: 8px 20px;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  border: #000 1px solid;
  transition: 0.5s all;
  font-weight: 700;
}

.faq-contact .contact-btn:hover {
  background-color: transparent;
  color: #000;
}


.VIpgJd-ZVi9od-aZ2wEe-OiiCO {
  display: none;
}

.VIpgJd-ZVi9od-aZ2wEe-OiiCO-ti6hGc {
  display: none;
}

.navigate-detail-btn {
  width: 100%;
  border: none;
  padding: 0;
}

.error-pitch {
  color: red;
  font-size: small;
  position: relative;
  top: 53px
}

@media only screen and (max-width: 992px){
  .Feature-payment-mdl {
    width: 100%;
    padding: 20px;
    background-color: white !important;
    border-radius: 8px;
  }
}

@media (min-width: 992px) and (max-width: 1300px){
  .Feature-payment-mdl {
    width: 70%;
    padding: 20px;
    background-color: white !important;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 1300px){
  .Feature-payment-mdl {
    width: 50%;
    padding: 20px;
    background-color: white !important;
    border-radius: 8px;
  }
}


.warning-mdl {
  width: 400px;
  padding: 20px;
  background-color: white !important;
  border-radius: 8px;
}

.warning-text {
  color: #f43254;
  font-weight: 600;
  font-size: 19px;
}

.warning-mdl-btn {
  background-color: #f43254;
  border: none;
  color: white;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 4px;
}

.warning-mdl-disable-btn {
  background-color: #d46c7f;
  border: none;
  color: white;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 4px;
}

.avatar-img {
  height: 70px;
  /* width: 20px; */
  border-radius: 50%;
}

.profile-image-check {
  position: absolute;

}

@media only screen and (max-width: 720px) {
  .swiper-button-prev {
    width: 35px;
    height: 35px;
    background-color: #f3173fb0;
    color: white;
    border-radius: 50%;
    transition: all .5s;
    /* margin-left: 12px; */
  }

  .swiper-button-prev:hover {
    background-color: #f30a35;
    border: solid 2px #f43254;
    transition: all .5s;
  }

  .swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: #f3173fb0;
    color: white;
    border-radius: 50%;
    transition: all .5s;
    /* margin-right: 12px; */
  }

  .swiper-button-next:hover {
    background-color: #f30a35;
    border: solid 2px #f43254;
    transition: all .5s;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    /* margin-left: 25px; */
    content: 'prev';
    font-size: 15px;
    font-weight: 600;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 15px;
    font-weight: 600;
  }
}

@media only screen and (min-width: 720px) {
  .swiper-button-prev {
    width: 45px;
    height: 45px;
    background-color: #f3173fb0;
    color: white;
    border-radius: 50%;
    transition: all .5s;
    margin-left: 12px;
  }

  .swiper-button-prev:hover {
    background-color: #f30a35;
    border: solid 2px #f43254;
    transition: all .5s;
  }

  .swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background-color: #f3173fb0;
    color: white;
    border-radius: 50%;
    transition: all .5s;
    margin-right: 12px;
  }

  .swiper-button-next:hover {
    background-color: #f30a35;
    border: solid 2px #f43254;
    transition: all .5s;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    /* margin-left: 25px; */
    content: 'prev';
    font-size: 18px;
    font-weight: 600;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 18px;
    font-weight: 600;
  }
}

.select__input {
  padding: 10px;
}

.primecolor {
  background-color: #e7c50d;
}

.my-header-class {
  transition: all 1;
}

.collapse-main-div {
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: white;
  border: none;
}

.rc-collapse>.rc-collapse-item {
  border: none;
}

.rc-collapse>.rc-collapse-item>.rc-collapse-header {
  display: flex;
  align-items: center;
  line-height: 30px;
  padding: 10px 16px;
  color: #f43254;
  cursor: pointer;
  background-color: #fff5f5;
  border: solid 1px #ffd4da;
  gap: 0px;
  font-size: 15px;
  font-weight: 400;
  transition: all 0.5;
  border-radius: 8px;
}

.rc-collapse>.rc-collapse-item>.rc-collapse-header .arrow {
  display: inline-block;
  content: '\20';
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #f43254;
  vertical-align: middle;
  margin-right: 6px;
  transition: all 0.5;
}

.rc-collapse>.rc-collapse-item-active>.rc-collapse-header .arrow {
  position: relative;
  top: 2px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #f43254;
  margin-right: 6px;
  transition: all 0.5;
}

/* Hurray Animation start */
.ConfettiExplosion-container {
  text-align: center;
}

.ConfettiExplosion-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0px;
}

/* Hurray Animation end */
/* .select__menu */
.createtable>.css-b62m3t-container>.select__menu {
  display: none;
}

.css-zrlv9q {
  background-color: #f43254;
  color: white;
  border-bottom: solid 1px white;
}

.css-i4bv87-MuiSvgIcon-root {
  color: white;
  font-size: 18px;
}

.css-apge8j-MuiTableRow-root {

  color: white;
}

.css-apge8j-MuiTableRow-root {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;
  background-color: #f43254 !important;
  box-shadow: 4px 0 8px rgba(0, 0, 0, 0.1);
  position: relative;
  top: 0;
  border-top: solid 2px white;
}

.MuiTableSortLabel-icon {
  opacity: 1;
  color: rgba(247, 247, 247, 0.945);
}

.champ-dashboard {
  /* background-color: #f5f5f5;  */
}

/* .home-main-img{
  height: 100vh;
  width: 100%;
} */

@media only screen and (max-width: 1600px) {

}

@media only screen and (min-width: 1600px) {

}

@media only screen and (min-width: 768px) {
  .home-main-img {
    width: 100%;
    height:100vh; 
    background-size: cover;
    background-position: center;
    background-image: url('../../assets/images/sponsor24.jpg');
    background-size: 100% 100vh;
  }

  .home-login-button{
    position: absolute;
    z-index: 10;
    padding: 13px 50px;
    background-color: #f43254;
    color: white;
    font-size: 16px;
    bottom: 20px;
    right: 20px;
    border: none;
    border-radius: 12px;
    font-weight: 500;
  }
}

@media (max-width: 768px) {
  .home-main-img {
    width: 100%; 
    height:100vh;
    background-size: cover;
    background-position: center;
    background-image: url('../../assets/images/mobile size sign up-02.jpg');
    background-size: 100% 100vh;
 }

  .home-login-button{
      position: absolute;
      z-index: 10;
      padding: 10px 30px;
      background-color: #f43254;
      color: white;
      font-size: 16px;
      bottom: 12px;
      right: 15px;
      border: none;
      border-radius: 10px;
      font-weight: 400;
    }
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  /* padding: 0 0 0 8px; */
  border-radius: 3px 0 0 3px;
  background-color: transparent;
}

.react-tooltip {
  white-space: pre-line;
}

.n-btn{
  background-color: #f43254;
  border: none;
  border-radius: 4px;
  padding: 14px 12px;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.sponsor-name{
  font-size:13px;
  color: black;
  font-weight:500;
}

@media print {
  .swiper-container {
    width: 100% !important;
    height: auto !important;
  }
  .swiper-slide {
    width: 100% !important;
    height: auto !important;
    /* Add any additional styling needed for print */
  }
}