.package_form_overlay {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px 0px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    /* align-items: center; */
    z-index: 500;

}

@media only screen and (max-width: 768px) {
    .package_form_popup {
        background: white;
        padding: 40px 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        width: 92%;
    }
}

@media only screen and (min-width: 768px) {
    .package_form_popup {
        background: white;
        padding: 25px 35px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
    }
}


.package_form_popup-content {
    /* text-align: center; */
}

.package_form_close {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    font-size: 35px;
    color: #333;
}