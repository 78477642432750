@media only screen and (max-width: 992px) {
  .nav-social-links{
    display: flex !important;
    flex-direction: row !important;
  }
}

@media only screen and (max-width: 600px) {
  .logo-img {
    height: 30px;
  }

  .signup-btn {
    border: none;
    padding: 7px 12px;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
    background-color: #f43254;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
  }

  .signin-btn {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    padding: 8px 16px;
  }

  .elkevent-home-nav nav ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  
}

.mobile-sidemenu {
  position: fixed;
  z-index: 10;
  width: 270px !important;
  background: #22252d;
  right: 3px;
  height: 100vh;
  top: 77px;
  color: black;
}

@media (min-width: 600px) and (max-width: 1600px) {
  .logo-img {
    height: 38px;
  }

  .signup-btn {
    border: none;
    padding: 10px 27px 10px 26px;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
    background-color: #f43254;
    color: white;
    font-weight: 500;
  }

  .signin-btn {
    font-family: Poppins;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    font-size: 13px;
    padding: 8px 16px;
  }

  .elkevent-home-nav nav ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
}

@media only screen and (min-width: 1600px) {
  .logo-img {
    height: 40px;
  }

  .signup-btn {
    border: none;
    padding: 10px 27px 10px 26px;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
    background-color: #f43254;
    color: white;
    font-weight: 500;
  }

  .signin-btn {
    font-family: Poppins;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    padding: 8px 16px;
  }

  .elkevent-home-nav nav ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
}

.navbar-toggler {
  padding: 0;
}

.navbar-light .navbar-toggler {
  color: #22252d;
  border: none;
}

.navbar-light .navbar-toggler-icon {
  background-size: 20px;
  background-image: url("../images/toggler-icon.png");
}

.elkevent-mobile-nav {
  display: none;
}

.elkevent-mobile-nav.mean-container .mean-nav ul li a.active {
  color: #FF2D55;
}

.elkevent-home-nav {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  padding: 14px 0px;
  width: 100%;
  z-index: 999;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
  height: auto;
}


.elkevent-nav nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}




.login-signup-div {
  display: flex;
}

.elkevent-home-nav nav .navbar-nav .nav-item {
  position: relative;
  padding: 0px 0;
}

.elkevent-nav nav .navbar-nav .nav-item {
  position: relative;
  padding: 0px 0;
}

/* .elkevent-nav nav .navbar-nav .nav-item a {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
  } */
/*   
  .elkevent-nav nav .navbar-nav .nav-item a:hover, .elkevent-nav nav .navbar-nav .nav-item a:focus, .elkevent-nav nav .navbar-nav .nav-item a.active {
    color: #FF2D55;
  }
   */
/* .elkevent-nav nav .navbar-nav .nav-item:hover a {
    color: #FF2D55;
  } */

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu {
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  background: #0d1028;
  position: absolute;
  top: 80px;
  left: 0;
  width: 250px;
  z-index: 99;
  display: block;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li {
  position: relative;
  padding: 0;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 9px 15px;
  margin: 0;
  display: block;
  color: #ffffff;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover,
.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a:focus,
.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #FF2D55;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  position: absolute;
  left: 220px;
  top: 0;
  opacity: 0 !important;
  visibility: hidden !important;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #ffffff;
  text-transform: capitalize;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #FF2D55;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1 !important;
  visibility: visible !important;
  top: -20px !important;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li:hover a {
  color: #FF2D55;
}

.elkevent-nav nav .navbar-nav .nav-item:hover ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
}


@media only screen and (max-width: 600px) {
  .other-option-div {
    width: 100%;
  }
}



.elkevent-nav nav .others-option {
  margin-left: 13px;
}


/* search input */

@media only screen and (max-width: 576px) {
  .elkevent-nav {
    background-color: #22252d;
    position: sticky;
    top: 0;
    left: 0;
    padding: 8px 0px;
    width: 100%;
    z-index: 300;
    height: auto;
  }

  .elkevent-nav.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px 0;
    z-index: 100;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: #22252d !important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }

  .elkevent-home-nav.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px 0;
    z-index: 200;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: #22252d !important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }

  .search-input-maindiv {
    width: auto
  }

  .search-img {
    height: 20px;
  }

  .get-location-btn {
    border: none;
    position: absolute;
    height: 20px;
    top: 8px;
    left: 4px;
    background-color: transparent;
  }

  .main-input {
    margin-top: 15px;
    padding-left: 10px;
    position: relative;
    display: flex;
    align-items: center;
    /* margin-left: 30px; */
    padding-right: 10px;
    background-color: #e4e4e4;
    border-radius: 5px;
    border-left: none;
  }

  .search-input {
    font-size: 12x;
    width: 100%;
    background-color: #e4e4e4;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: none;
    padding: 10px 20px 10px 30px;
    border: none;
  }

  .location-input {
    width: 140px;
    background-color: #e4e4e4;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: none;
    margin-left: 28px;
    padding: 10px 20px 10px 10px;
    border: none;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .location-img {

    /* top:15px; */
    height: 20px;
  }

  .ul-list {
    display: flex;
    align-items: start;
    gap: 20px;
    margin-left: 20px !important;
  }

  .currency-select-button {
    padding: 11px 16px 10px;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    border: none;
  }
}

@media (min-width: 576px) and (max-width: 1600px) {
  .elkevent-nav {
    background-color: #22252d;
    position: sticky;
    top: 0;
    left: 0;
    padding: 12px 0px;
    width: 100%;
    z-index: 200;
    height: auto;
  }

  .elkevent-nav.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px 0;
    z-index: 300;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: #22252d !important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }

  .elkevent-home-nav.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px 0;
    z-index: 200;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: #22252d !important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }

  .search-input-maindiv {
    width: 230px;
  }

  .main-input {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding-right: 10px;
    background-color: #e4e4e4;
    border-radius: 10px;
    border-left: none;
  }

  .search-input {
    font-size: 12px;
    width: 200px;
    background-color: #e4e4e4;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: none;
    padding: 10px 5px 10px 30px;
    border: none;
  }

  .search-input:focus {
    outline: none;
  }

  .location-input {
    width: 120px;
    background-color: #e4e4e4;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: none;
    padding: 8px 6px 7px 0px;
    border: none;
    /*text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
  }

  .location-text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .currency-select-button {
    padding: 11px 16px 10px;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    border: none;
  }

  .search-img {
    /* position: absolute; */
    height: 17px;
    /* left: 10px; */
  }

  .get-location-btn {
    border: none;
    position: absolute;
    height: 20px;
    top: 6px;
    left: 2px;
    background-color: transparent;
  }
}

@media only screen and (min-width: 1600px) {
  .elkevent-nav {
    background-color: #22252d;
    position: sticky;
    top: 0;
    left: 0;
    padding: 12px 0px;
    width: 100%;
    z-index: 200;
    height: auto;
  }

  .elkevent-nav.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px 0;
    z-index: 200;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: #22252d !important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }

  .elkevent-home-nav.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px 0;
    z-index: 200;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: #22252d !important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }

  .search-input-maindiv {
    width: 345px;
  }

  .main-input {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 30px;
    padding-right: 10px;
    background-color: #e4e4e4;
    border-radius: 10px;
    border-left: none;
  }

  .search-input {
    font-size: 14px;
    width: 304px;
    background-color: #e4e4e4;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: none;
    padding: 10px 5px 10px 40px;
    border: none;
  }

  .search-input:focus {
    outline: none;
  }

  .location-input {
    width: 160px;
    background-color: #e4e4e4;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: none;
    padding: 8px 6px 7px 0px;
    border: none;
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
  }

  .location-text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .currency-select-button {
    padding: 11px 16px 10px;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    border: none;
  }

  .search-img {
    /* position: absolute; */
    height: 20px;
    /* left: 12px; */
  }

  .get-location-btn {
    border: none;
    position: absolute;
    height: 20px;
    top: 8px;
    left: 6px;
    background-color: transparent;
  }
}


.location-img {
  /* top:15px; */
  height: 20px;
}

.ul-list {
  display: flex;
  align-items: start;
  gap: 20px;
  margin-left: 18px !important;
}




/* .language-currency-div{
    display: flex;
    align-items: center;
    gap:20px;
    margin-left: 10px;
  } */

/* .currency-select-button {
  padding: 11px 16px 10px;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  border: none;
} */

.Upgrade-btn {
  padding: 10px 16.5px 10px 16px;
  border-radius: 6px;
  border: none;
  background-color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f43254;
}

@media only screen and (max-width: 1800px) {
  .nav-btn {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background: 0 0;
    border: 0;
  }
}

@media only screen and (min-width: 1800px) {
  .nav-btn {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background: 0 0;
    border: 0;
  }
}

/* .avatar-dropdown-menu {
  position: absolute;
  z-index: 10;
  display: none;
  top: 56px;
  right: -10px;
  width: 220px;
  padding: 24px 33px 24px 24px;
  border-radius: 4px;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px 0 rgba(92, 92, 92, 0.2), 0 6px 20px 0 rgba(110, 110, 110, 0.19);
} */

.avatar-dropdown-menu {
  position: absolute;
  z-index: 10;
  display: none;
  top: 56px;
  right: -10px;
  width: 250px;
  padding: 24px 33px 24px 24px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(92, 92, 92, 0.2), 0 6px 20px 0 rgba(110, 110, 110, 0.19);
  opacity: 5;
  transform: scale(0.97);
  transition: opacity 0.10s ease, transform 0.5s ease;
}

.avatar-dropdown-item {
  display: block;
  width: 100%;
  padding: 10px 20px;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.avatar-dropdown-item:hover {
  background-color: rgb(230, 230, 230);
}

.avatar-toggle-btn {
  border: solid 1px #f43254;
}

.user_info {
  width: 100%;
  height: 50px;
  background-color: #f43254;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.user_name_text {
  display: flex;
  align-items: center;
  gap: 2px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background: 0 0;
  border: 0;
}


.srch-location {
  display: block;
  position: absolute;
  z-Index: 1;
}

.srch-ul {
  float: none;
  text-decoration: none;
  display: grid !important;
  margin-left: 30px !important;
  background: #e4e4e4;
  width: auto;
  border-radius: 4px
}

.srch-li {
  /* cursor: pointer; */
  padding: 10px 10px 10px 10px;
}

:hover.srch-li {
  cursor: pointer;
  background: #f43254;
  color: white;
  border-radius: 5px;
}

.social-icon {
  height: 19px;
  border-radius: 4px;
}

