/* src/components/ProgressBar.css */
.progress-bar {
    width: 100%;
    background-color: #ffcad4;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 15px;
  }

  
  .progress {
    height: 17px;
    background-color: #ed9035;
    width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    transition: width 0.2s ease-in-out;
  }
  
  .success-progress {
    height: 17px;
    background-color: #f43254;
    width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    transition: width 0.2s ease-in-out;
  }