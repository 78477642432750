.sitemap-head {
    border-radius: 6px;
    border: 1px solid #f43254;
    width: fit-content;
    padding: 8px 120px;
    background-color: #f43254;
}

.landing-head-title {
    font-weight: 500;
    color: white;
    font-size: 16px;
}

.sitemap-bottom {
    border-radius: 6px;
    background-color: #a592fb;
    padding: 0px 12px;
    /* height: 45px;
    width: 160px; */
    padding: 8px 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #a592fb;
}

.landing-bottom-title {
    font-weight: 500;
    color: black
}

.sitemap-ul {
    list-style-type: none;
    padding: 0;
    display: grid;
    align-items: center;
    justify-content: center;
}

.sitemap-li1 {
    margin-top: 15px;
    padding: 8px 10px;
    border-radius: 6px;
    text-align: center;
    border: 1px solid #efb9aa;
    background-color: #efb9aa;
}

:hover.sitemap-li1 {
    transition: 0.3s;
    background-color: #eda692;
}

.sitemap-li2 {
    margin-top: 15px;
    padding: 8px 10px;
    border-radius: 6px;
    text-align: center;
    border: 1px solid #98e1f9;
    background-color: #98e1f9;
}

:hover.sitemap-li2 {
    transition: 0.3s;
    background-color: #7ac3db;
}

.sitemap-li3 {
    margin-top: 15px;
    padding: 8px 10px;
    border-radius: 6px;
    text-align: center;
    border: 1px solid #c5c4b0;
    background-color: #c5c4b0;
}

:hover.sitemap-li3 {
    transition: 0.3s;
    background-color: #adac98;
}

.sitemap-li4 {
    margin-top: 15px;
    padding: 8px 10px;
    border-radius: 6px;
    text-align: center;
    border: 1px solid #ffdc8c;
    background-color: #ffdc8c;
}

:hover.sitemap-li4 {
    transition: 0.3s;
    background-color: #e4c479;
}

.sitemap-li5 {
    margin-top: 15px;
    padding: 8px 10px;
    border-radius: 6px;
    text-align: center;
    border: 1px solid #91ead2;
    background-color: #91ead2;
}

:hover.sitemap-li5 {
    transition: 0.3s;
    background-color: #80d4bd;
}


.a-li {
    color: black
}