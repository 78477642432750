@media (min-width: 200px) and (max-width: 992px) {
  .signup-area .signup-form {
    width: 100%;
    height: auto;
    /* opacity: .77; */
    background: rgba(255, 255, 255);
    backdrop-filter: blur(8px);
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.11);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 10px;
    margin: 0 auto;
    padding: 20px 25px 20px;
  }

}

@media only screen and (min-width: 992px) {
  .signup-area .signup-form {
    width: 80%;
    height: auto;
    /* opacity: .77; */
    background: rgba(255, 255, 255);
    backdrop-filter: blur(8px);
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.11);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 10px;
    margin: 0 auto;
    padding: 40px 60px;
  }
}

.signup-area {
  height: 100vh;
  /* position: relative; */
  z-index: 100000;
  background-image: url("../images/singinbackground.jpeg");
  /* background-position: cover cover; */
  background-size: cover;
  background-repeat: no-repeat;

}

/* .signup-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #FF2D55;
    opacity: .77;
  } */

.signup-area .signup-form h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

}

.signup-area .signup-form form {
  /* padding: 35px; */
}

.signup-area .signup-form form label {
  font-weight: 500;
}

.signup-area .signup-form form .form-control {
  background: transparent;
  font-size: 13px;
}

.signup-area .signup-form .btn {
  display: block;
  width: 100%;
  margin-top: 20px;
}

.signup-area .signup-form p {
  /* text-align: center; */
  /* color: white; */
  margin-bottom: 0;
  margin-top: 10px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: #fff; */
}

.signup-area .signup-form p a {
  /* color: #0d1028; */
  display: inline-block;
  font-weight: 600;
}

.signup-area .signup-form p a:hover,
.signup-area .signup-form p a:focus {
  color: #FF2D55;
}

.otp-send-btn {
  position: absolute;
  z-index: 30;
  right: 4px;
  border: none;
  background-color: white;
  background-color: transparent;
}

.otp-input {
  width: 100%;
  font-size: 13px;
  border: solid 1px rgb(201, 201, 201);
  padding: 7px 6px;
  outline: #FF2D55;
}

.otp-input:focus {
  border: solid 1px #FF2D55;
  outline: #FF2D55;
}

.otpcheck-div {
  position: absolute;
  right: 10px;
}

.document-img {
  height: 120px;
  width: 220px;
}

.signup-verify-otp {
  position: absolute;
  right: 12px;
  top: 10px;
  border: none;
  background-color: #f43254;
  color: white;
  padding: 4px 8px;
}

.signup-verified {
  position: absolute;
  right: 12px;
  top: 10px;
  border: none;
}

.terms-head-text {
  color: #f43254;
  font-weight: 700;
  font-size: 24px;
}

.TermsAndPrivacyPolicy-mdl {
  /* width: 60%; */
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: white !important;
  border-radius: 8px;
}

.termsandprivacypolicy-content {
  height: 500px;
  overflow-y: auto;
}

.terms-mdl-close-btn {
  position: absolute;
  top: 10px;
  right: 12px;
  background-color: transparent;
  border: none;
}

.tooltip-password-head {
  font-size: 14px;
  font-weight: 600;
}