@media only screen and (max-width: 720px) {
  .your-orders-heading{
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #333;
  }
}

@media only screen and (min-width: 720px) {
  .your-orders-heading{
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
  }
}



  .your-order-card{
    background-color: white;
    height: auto;
    width: 100%;
    border: solid 1px rgb(206, 206, 206);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .order-card-head{
    align-items: center;
    display: flex;
    height: 50px;
    background-color:rgb(236, 236, 236);
    border-bottom: solid 1px rgb(206, 206, 206);  
    padding: 0px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.order-id-div{
    display: flex;
    align-items: center;
    border:solid 1px #f43254;
    background-color: #fff5f7;
    padding: 3px 10px;
    border-radius: 4px;
  }

  .order-id-text{
    color: #f43254;
    font-weight: 500;
   padding: 0;
   margin: 0;
  }

  .payment-id-div{
    display: flex;
    align-items: center;
    border:solid 1px green;
    background-color: rgb(242, 253, 242);
    padding: 3px 10px;
    border-radius: 4px;
  }

  .payment-id-text{
    color: green;
    font-weight: 500;
   padding: 0;
   margin: 0;
  }

.order-card-content{
    padding: 10px 20px;
}

.order-card-p{
    font-size: 13px;
    font-weight: 500;
    color: rgb(131, 131, 131);
    padding: 0;
    margin: 0;
}

.order-event-title{
    padding-top: 5px;
    font-size: 14px;
    font-weight: 600;
    color:#f43254;
    margin: 0;  
}

.order-data-p{
   padding-top: 5px;
   font-size: 14px;
   font-weight: 500;
   color:black;
   margin: 0; 
}

.order-price-text{
    padding-top: 5px;
    font-size: 14px;
    font-weight: 500;
    color:red;
    margin: 0;  
}

.order-discount-text{
    padding-top: 5px;
    font-size: 14px;
    font-weight: 500;
    color:green;
    margin: 0; 
}


.success-status-text{
    padding: 2px 8px;
    font-size: 14px;
    font-weight: 500;
    color:green;
    border-radius: 20px;
 }

 .failed-status-text{
    padding: 2px 8px;
    font-size: 14px;
    font-weight: 500;
    color:rgb(167, 1, 1);
    border-radius: 20px;
 }

 .pending-status-text{
    padding: 2px 8px;
    font-size: 14px;
    font-weight: 500;
    color:rgb(24, 163, 218);
    border-radius: 20px;
 }

.cancel-btn{
    border-radius: 6px;
    border: solid 1px #f43254;
    padding: 4px 8px;
    background-color: #f43254;
    color: white;
    font-weight: 500;
}

/* new */
.order-head-div{
  background-color: #f43254;
  width: 100%;
  padding: 18px 20px;
  border-radius: 5px;
}

.order-head-text{
  color: white;
  font-size: 13px;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
  /* text-align: center; */
}

.order-list-div{
  background-color: white;
  width: 100%;
  padding: 8px 20px;
  border-radius: 4px;
  border-bottom: solid 1px rgb(214, 214, 214);
}

.order-list-text{
  color: black;
  font-size: 13px;
  /* text-align: center; */
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.order-list-main-div{
  height: 600px;
  overflow: auto;
  width: 100%;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

/* .order-dropdown-button {
  padding: 13px 12px; 
  cursor: pointer;
  background-color:#f43254;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 6px;
} */

.order-dropdown-button {
padding: 8px 6px;
cursor: pointer;
color: #f43254;
font-weight: 500;
border: none;
border-radius: 5px;
background-color: white;
border-bottom: solid 1px #f43254;
font-size: 14px;
}

.dropdown-menu-list{
  position: absolute;
  top: 50px;
  right: 0px;
  z-index: 40;
  font-size: 13px;
  /* padding: 10px 20px; */
  border-radius: 6px;
  width: 160px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-menu-data{
  position: absolute;
  top: 55px;
  left: 0px;
  z-index: 10;
  font-size: 13px;
  /* padding: 10px 20px; */
  border-radius: 6px;
  width: 160px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  color: black;
  &:hover {
    background-color: #fceaee;
    border-radius: 4px;
  }
}
