
.change-password-container {
    max-width: 600px;
    margin: 10px 10px;
    padding: 20px;
    border: none;
    border-radius: 5px;
    background-color: #ffffff;
}

.change-password-container h2 {
    margin-top: 0;
}

.change-password-container form {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-row-gap: 15px;
        margin-top: 30px;
}

.change-password-container label {
    grid-column: 1;
}

.change-password-container input {
    /* grid-column: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px; */
}

.change-password-container button {
    grid-column: span 2;
    padding: 10px 20px;
    background-color: #f43254;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.change-password-container button:hover {
    background-color: #d84762;
}