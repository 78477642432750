/* src/DiscountOffer.css */

@media only screen and (max-width: 540px) {
  .code-container-c {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .copy-button-c {
    /* background-color: #ff4757; */
    font-size: 12px;
    color: #ff4757;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }

  .code-c {
    font-size: 12px;
    font-weight: bold;
    background-color: #f2f2f2;
    padding: 10px;
    border: 1px dashed #ccc;
    border-radius: 4px;
  }
}

@media only screen and (min-width: 540px) {
  .code-container-c {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .copy-button-c {
    font-size: 14px;
    /* background-color: #ff4757; */
    color: #ff4757;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }

  .code-c {
    font-size: 14px;
    font-weight: bold;
    background-color: #f2f2f2;
    padding: 10px;
    border: 1px dashed #ccc;
    border-radius: 4px;
  }
}

.offer-container-c {
  width: 100%;
  /* border: 1px solid #e0e0e0; */
  border-radius: 8px;
  padding: 20px;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.brand-c {
  font-size: 21px;
  color: #333;
}

.offer-title-c {
  font-size: 17px;
  color: #333;
  margin-top: 10px;
}

.offer-subtitle-c {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.copied-button-c {
  color: #ff4757;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
}

.copy-button-c:hover {
  background-color: #e04040;
  color: white;
  transition: 7ms;
}

.offer-details-c {
  margin-top: 20px;
  padding-left: 0px;
}

.offer-details-c li {
  margin-bottom: 2px;
  color: #333;
}

.offer-details-c li::before {
  content: '✔';
  color: green;
  margin-right: 10px;
}


/* EventHostUi component ui  -------------------------------------------------------------*/

.profile-header-h {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* width: 300px; */
  padding: 12px;
  border-radius: 10px;
  box-shadow: rgba(189, 189, 189, 0.35) 0px 5px 15px;
}

.create-event-shield-name {
  font-size: 16px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
  cursor: pointer;
  color: #777;
}

.profile-picture-h {
  text-align: center;
}

.profile-picture-h img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.super-host-h {
  font-size: 12px;
  text-align: center;
}

.profile-details-h {
  margin-left: 20px;
}

.b-bold-h {
  font-size: 16px;
}

.span-text-h {
  font-size: 11px;
}

.superhost-badge-h {
  background-color: #FF5A5F;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.reviews-h {
  display: grid;
  /* gap: 15px; */
}

/*profile info --------------------- */

.profile-info-h {
  display: flex;
  /* padding: 20px; */
}

.confirmed-info-h {
  flex: 1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.confirmed-info-h ul {
  list-style: none;
  padding: 0;
}

.confirmed-info-h>ul li {
  margin: 8px 0;
}


.list-item ul {
  padding-left: 20px;
}


.confirmed-info-h a {
  font-size: 11px;
  color: black;
  text-decoration: underline;
}

.about-h {
  flex: 2;
  padding-left: 20px;
  font-size: 15px;
}

.about-h a {
  text-decoration: underline;
  color: black;
}


/* review -------------------- */

.reviews-section-h {
  padding: 0 0 0 20px;
}

.reviews-h-h {
  /* display: flex; */
  /* gap: 15px; */
}

.review-card-h {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  height: 180px
}

.review-card-h p {
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.review-card-h img {
  border-radius: 50%;
  object-fit: cover;
  height: 50px;
  width: 50px;
}

.review-card-h span {
  display: block;
  margin-top: 10px;
  color: #777;
}

/*listing ------------------ */

.listings-section-h {
  /* padding: 20px; */
  padding: 20px 0 0 20px;
}

.listings-h {
  /* display: flex; */
  gap: 10px;
}

.listing-card-h {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  flex: 1;
}

.link-listing-items {
  width: 100%
}


.listing-card-h img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  cursor: pointer;
}

.host-card-listing-1 {
  font-size: 14px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 170px;
  cursor: pointer;
  color: #777
}

.host-card-listing-2 {
  font-size: 13px;
  cursor: pointer;
  color: #777;
}

.host-card-listing-3 {
  font-size: 13px;
  cursor: pointer;
  color: #777;
  display: flex;
  align-items: center;
}

.host-card-listing-4 {
  font-size: 13px;
  cursor: pointer;
  color: #777;
  display: flex;
  align-items: center;
}