.chat-close-btn{
    background-color: white;
    border: none;
}

.back-btn{
    border: none;
    background-color: transparent;
    color:#e94d6a;
    font-weight: 600;
    padding: 0px;
    margin: 0px;
}

.archive-btn{
    width:fit-content;
    background-color:#fff5f7;
    border: solid 1px #ff2d55;
    border-radius: 6px;
    padding: 8px 20px;
    font-weight: 500;
    color: #f11840;
    cursor: pointer;
}

.archive-active-btn{
    width:fit-content;
    background-color:#f11840 ;
    border: solid 1px #ff2d55;
    border-radius: 6px;
    padding: 8px 20px;
    font-weight: 500;
    color: #fff5f7;
    cursor: pointer;
}


.msg-sent-button {
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    background: #f43254;
    color: white;
    padding: 14px 25px;
    padding-left: 0.9em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.2s;
    cursor: pointer;
  }
  
  .msg-sent-button span {
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
  }
  
  .msg-sent-button svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
    height: 24px;
  }
  
  .msg-sent-button:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
  }
  
  .msg-sent-button:hover svg {
    transform: translateX(1.2em) rotate(45deg) scale(1.1);
  }
  
  .msg-sent-button:hover span {
    transform: translateX(5em);
  }
  
  .msg-sent-button:active {
    transform: scale(0.95);
  }
  
  @keyframes fly-1 {
    from {
      transform: translateY(0.1em);
    }
  
    to {
      transform: translateY(-0.1em);
    }
  }
  