/* Popup.css */

.newuser-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.newuser-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    /* Default width for responsiveness */
    max-width: 500px;
    /* Set a max-width for larger screens */
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.newuser-open-popup-button,
.newuser-close-popup-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.newuser-open-popup-button:hover,
.newuser-close-popup-button:hover {
    background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .newuser-popup-content {
        width: 80%;
        /* Adjust width for tablets and small screens */
    }
}

@media (max-width: 480px) {
    .newuser-popup-content {
        width: 95%;
        /* Adjust width for very small screens */
        padding: 15px;
        /* Adjust padding for small screens */
    }

    .newuser-open-popup-button,
    .newuser-close-popup-button {
        padding: 8px 16px;
        font-size: 14px;
        /* Adjust font size for buttons */
    }
}