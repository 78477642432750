@media only screen and (max-width: 768px) {
  .champ-dashboard-list {
    /* width: 390px;
      height:700px; */
    height: auto;
     max-height: 526px;
    /* overflow-y: scroll; */
    padding: 0px 0 0;
    border-radius: 4px;
    border: solid 2px #d3d3d36b;
  
    background-color: #fff;
    overflow: auto;
    padding: 2px 4px;
  }
}

@media only screen and (min-width: 768px) {
  .champ-dashboard-list {
    /* width: 390px;
      height:700px; */
    height: 650px;
    /* overflow-y: scroll; */
    padding: 0px 0 0;
    border-radius: 4px;
    border: solid 2px #d3d3d36b;
    background-color: #fff;
    overflow: auto;
    padding: 2px 4px;
  }
}


.champ-dashboard-view {
  /* width: 800px;
    height: 700px; */
  height: 650px;
  overflow-y: auto;
  padding: 0 1px 0px 0;
  border-radius: 4px;
  /* border: solid 1px #e9e9e9; */
  border-radius: 4px;
  background-color: #fff;
}

.btn-champ-div {
  border-top: solid 1px #e9e9e9;
  border-bottom: solid 1px #e9e9e9;
}

.active-btn-champ {
  border: none;
  color: white;
  padding: 10px 10px;
  background-color: #f43254;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
}

.deactive-btn-champ {
  border: none;
  color: rgb(58, 58, 58);
  padding: 10px 10px;
  background-color: white;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
}

/* dashhboard css start */
.champ_dashboard-btn {
  border: none;
  width: 100%;
  padding: 0px;
  text-align: left;
  border-radius: 10px;
}

.total-hosts-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px;
  height: 100px;
  width: 100%;
  background-color: #d62d4e;
  border-radius: 10px;
}

.total-hosts-text {
  color: white;
  font-size: 13px;
  font-weight: 500;
  margin: 0px;
  text-transform: uppercase;
}

.total-hosts-data-text {
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin: 0px;
}

.total-sponsors-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px;
  height: 100px;
  width: 100%;
  background-color: #008b8b;
  border-radius: 10px;
}

.total-earning-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px;
  height: 100px;
  width: 100%;
  background-color: #cd5b45;
  border-radius: 10px;
}

.introductory-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px;
  height: 100px;
  width: 100%;
  background-color: #104e8b;
  border-radius: 10px;
}

.manage-host-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px;
  height: 100px;
  width: 100%;
  background-color: #5f5f00;
  border-radius: 10px;
}

.onboard-sponsors-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px;
  height: 100px;
  width: 100%;
  background-color: #8b2323;
  border-radius: 10px;
}

.total_income_till_date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px;
  height: 100px;
  width: 100%;
  background-color: #661a79;
  border-radius: 10px;
}

.earning_received {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px;
  height: 100px;
  width: 100%;
  background-color: #4b413d;
  border-radius: 10px;
}

.earning_pending{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px;
  height: 100px;
  width: 100%;
  background-color: #27a877;
  border-radius: 10px;
}


.earning_not_due {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px;
  height: 100px;
  width: 100%;
  background-color: #c39e28;
  border-radius: 10px;
}

.signup_total_user{ 
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px;
  height: 100px;
  width: 100%;
  background-color: #46bdb3;
  border-radius: 10px;
}

.host_total_user{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px;
  height: 100px;
  width: 100%;
  background-color: #5a71b3;
  border-radius: 10px;
}

.sponsor_total_user{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px;
  height: 100px;
  width: 100%;
  background-color: #8b7168;
  border-radius: 10px;
}

.dashboard-container {
  height: 650px;
  overflow-y: auto;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 4px;
}

.overview,
.earnings-breakdown,
.wallet-amount {
  margin-bottom: 20px;
}

.champ-text {
  color: #292929;
  font-size: 20px;
}

.champ-p {
  color: rgb(92, 92, 92);
  font-weight: 400;
  margin: 0px;
  font-size: 14px;
}

/* dashhboard css end */
.transctions-list-div {
  height: 500px;
}

.champ-list-main-div {
  height: 430px;
  overflow: auto;
  width: 100%;
}

.fix-width {
  width: 100%;
  height: 500px;
  overflow-y: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  overflow-x: auto;
  width: 100%;
  margin-bottom: 0;
  max-width: none;
}

.panel {
  padding: 15px;
}

.scroll-inner {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparentize(#ccc, 0.7);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: transparentize(#ccc, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

.champ-list-head {
  position: sticky;
  top: 0px;
  width: 100%;
  padding: 12px 30px 12px 30px;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
  background-color: #f43254;
  color: white;
}

/* new table  */
.ag-header.ag-header-allow-overflow .ag-header-row {
  overflow: visible;
  background-color: #f43254;
  color: white;
}

.ag-header.ag-header-allow-overflow .ag-header-row:last-child {
  overflow: visible;
  /* background-color: #fff5f7; */
  background-color: #fadce1;
  border: solid 1px #ffb3c1;
}

.ag-header.ag-header-allow-overflow .ag-header-row .ag-icon{
  overflow: visible;
  color: white;
  
}

.ag-header.ag-header-allow-overflow .ag-header-row:last-child .ag-icon {
  display: block;
  color: rgb(241, 31, 31);
  font-weight: 600;
}

.ag-header.ag-header-allow-overflow .ag-header-row .ag-icon:hover{
  overflow: visible;
  color: white;
}

.ag-header.ag-header-allow-overflow .ag-header-row:last-child .ag-icon:hover {
  display: block;
  color: rgb(247, 9, 9);
}


.ag-theme-alpine .ag-ltr input[class^="ag-"]:not([type]),
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="text"],
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="number"],
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="tel"],
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="date"] {
  padding-left: var(--ag-grid-size);
  border: solid 2px #e03b597e;
}

.ag-theme-alpine .ag-row,
.ag-theme-alpine-dark .ag-row,
.ag-theme-alpine-auto-dark .ag-row {
  font-size: calc(var(--ag-font-size) + 1px);
  height: 50px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 #dce5d980;
}

.ag-row:focus{
  font-size: calc(var(--ag-font-size) + 1px);
  height: 50px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 #dce5d980;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
 .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), 
 .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
  .ag-ltr .ag-cell-range-single-cell, .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, 
  .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
   .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
   .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, 
   .ag-rtl .ag-cell-range-single-cell, .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: none;
  outline: none;
}

/*.ag-header-cell, .ag-header-group-cell {
  padding-left: var(--ag-cell-horizontal-padding);
  padding-right: var(--ag-cell-horizontal-padding);
width: 100px;
} */

.ag-ltr .ag-cell {
  border-right-width: 1px;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.ag-icon-filter:hover {
  display: block;
  color: white;
}

.ag-theme-alpine input[class^="ag-"][type="text"],
.ag-theme-alpine input[class^="ag-"][type="number"] {
  min-height: calc(var(--ag-grid-size) * 4);
  border-radius: var(--ag-border-radius);
  color: black;
  font-weight: 600;
}

.export-to-excel-btn {
  position: absolute;
  z-index: 30;
  top: 14px;
  right: 0px;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 6px;
  background-color: #fff5f7;
  color: #f43254;
  font-weight: 600;
  border: solid 1px #ffa9b9;
  border-radius: 4px;
  transition: all 0.5s;
}

.export-to-excel-btn:hover {
  background-color: #f43254;
  color: #fff5f7;
  border: solid 1px #ffa9b9;
  transition: all 0.5s;
}

.user-active-div {
  background-color: rgb(92 184 92);
  padding: 1px 6px;
  color: white;
  font-size: 12px;
  border: none;
  border-radius: 3px;
}

.user-inactive-div {
  background-color: rgb(217 83 79);
  padding: 1px 6px;
  color: white;
  font-size: 12px;
  border: none;
  border-radius: 3px;
}

.champ-events-heading {
  font-size: 30px;
  margin-bottom: 0px;
  font-weight: 600;
  padding: 0px;
  /* width: 250px;
  text-wrap:nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
}

.host-username-heading {
  font-size: 18px;
  /* width: 150px; */
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.username-heading {
  font-size: 18px;
}

.champ-dashboard-username{
  font-size: 16px;
}

.your-events-p{
  font-size: 13px;
  font-weight: 500;
  color: red;
  margin: 0px;
  padding: 0px;
}

.filter-section{
  position: absolute;
  z-index: 40;
  top: -50px;
  right: 0px;
}

.dashboard-signup-head-text{
 font-size:20px;
 font-weight:600;
}

.dashboard-signup-section{
  background-color: rgb(237, 245, 255);
  padding: 12px 10px;
  border-radius: 6px;
}

.basic-membership-section{
  background-color: #6e88c0;
  padding: 1px 6px;
  border-radius: 4px;
  color: white;
}

.premium-membership-section{
  background-color: #f5e664;
  padding: 1px 6px;
  border-radius: 4px;
  color: rgb(54, 54, 54);
}

.user-total-event{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px;
  height: 80  px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: rgba(238, 160, 160, 0.096) 0px 2px 8px 0px;
  border: solid 1px rgba(49, 7, 7, 0.151);
}

.discliamer-div{
  background-color: rgb(255, 239, 239);
  padding: 10px;
  border-radius: 6px;
}

.user-host-section{
  background-color: rgb(245, 245, 255);
  padding: 16px 20px;
  margin: 0px 12px;
  border-radius: 6px;
}