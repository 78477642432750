/* PopupForm.css */
@media only screen and (max-width: 720px) {
  .cancellation-refund-popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 350px;
    position: relative;
    max-height: 500px;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 720px) {
  .cancellation-refund-popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    position: relative;
    /* max-height: 400px; */
  }
}

.cancellation-refund-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
}



.cancellation-refund-close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 30px;
  cursor: pointer;
}

.cancellation-refund-textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

.cancellation-refund-button {
  display: block;
  /* width: 100%; */
  padding: 7px;
  font-size: 12px;
  background-color: #f43254;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0px 20px 0px 0px;
}

.cancellation-refund-button:hover {
  background-color: #f71d45;
}


.cancellation-refund-problem {
  font-size: 16px;
  font-weight: 500;
}

.cancellation-refund-radio-group {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* .cancellation-refund-radio-group label {
  display: flex;
  gap: 2px;
  margin: 0px 0px 10px 0px;
} */


.refund-table-customer td,
.refund-table-customer th {
  border: 1px solid #ddd;
  padding: 8px;
  display: flex;
  align-items: center;
}

.refund-table-customer tr:hover {
  background-color: #ddd;
}

.refund-table-customer td label {
  display: flex;
  gap: 2px;
  margin: 0px 0px 0px 0px;
}