/* src/AttendeeStats.css */

 @media only screen and (max-width: 720px) {
  .attendee-stats-guest {
        /* display: flex;
        justify-content: space-around;
        align-items: center; */
    }

    .guest-statistics {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .stat-guest-2 {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: space-around; */
        margin-top: 10px;
    }
 }

 @media only screen and (min-width: 720px) {
    .attendee-stats-guest {
          display: flex;
          justify-content: space-around;
          align-items: center;
    }

    .guest-statistics {
        display: flex;
        flex-direction: row;
    }

    .stat-guest-2 {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 150px;
    }
 }


.stat-guest {
    text-align: center;
    align-items: center;
    /* margin-right: 50px; */
}

.icon-guest {
    /* width: 110px; */
    height: 42px;
    /* margin: 0 auto 10px; */
    background-size: contain;
    background-repeat: no-repeat;

}

.text-guest {
    color: green
}

.count-guest {
    display: block;
    font-size: 18px;
    color: green;
}

.percentage-guest {
    display: block;
    font-size: 18px;
    color: green;
}

.label-guest {
    display: block;
    font-size: 14px;
    color: gray;
}







/*  */