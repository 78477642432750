.popup-container-host {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-dialog-host {
    width: 90%;
    max-width: 500px;
    margin: auto;
}

.modal-content-host {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header-host {
    padding: 10px 0px 10px 0px;
}

.modal-footer-host {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;
    padding: 10px 10px;
}

.modal-title-host {
    margin: 0;
    padding: 0;
    font-size: 1.25rem;
}

.btn-success-host,
.btn-danger-host {
    border: none;
    padding: 8px 15px;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
}

.btn-success-host {
    background-color: #28a745;
}

.btn-danger-host {
    background-color: #dc3545;
}
