@media only screen and (max-width: 572px) {
  .event-card-img {
    position: relative;
    width: 100%;
    height: 230px;
    border-radius: 4px;
    object-fit: cover;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }

  .events-heading {
    font-family: Poppins;
    font-size: 23px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
  }
}

@media only screen and (min-width: 572px) {
  .event-card-img {
    position: relative;
    width: 100%;
    height: 300px;
    border-radius: 8px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .events-heading {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
  }
}

.events {
  padding-top: 30px;
  padding-bottom: 50px;
}



.event-rectangle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  width: 100%;
  /* width: 75%; */
  height: auto;
  padding: 6px 3px 6px 3px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #f43254;
  color: white;
}

.event-grid-btn {
  background-color: white;
  border: solid 1px #f43254;
  padding: 13px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.event-grid-btn-actv {
  background-color: #f43254;
  color: white;
  padding: 13px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: solid 1px #f43254;
}

.event-list-btn {
  background-color: white;
  border: solid 1px #f43254;
  padding: 13px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.event-list-btn-actv {
  background-color: #f43254;
  color: white;
  border: solid 1px #f43254;
  padding: 13px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.event-card-heading {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: capitalize;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.event-card-text {
  height: 20px;
  margin: 0 0 0 8px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
}

.search-div {
  /* padding-left: 200px; */
  /* padding-right: 200px; */
}

.event-search-btn {
  background-color: #f43254;
  border: none;
  color: white;
  padding: 10px 30px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-top-right-radius: 8px;
  border-end-end-radius: 8px;
}

.search-event-input {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.listcard-div {
  height: 80px;
  width: 100%;
  padding: 10px 30px 10px 30px;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
  background-color: #fff;
}

.listcard-img {
  border-radius: 6px;
  width: 42px;
  height: 42px;
}

.listcard-head-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.listcard-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f3d56;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.listevent-editbtn {
  background-color: white;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f43254;
  padding: 0px;
  margin: 0px;
}

.listevent-publishbtn {
  background-color: #f43254;
  padding: 6px 12px;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  margin: 0px;
  border-radius: 4px;
}


/* filter section dropdown */

.filterList-div {
  position: absolute;
  width: 400px;
  height: 100vh;
  background-color: #fff;
  padding: 4px;
  z-index: 1;
  box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
  padding: 14px 25px;
  border-radius: 14px;
}

.fltrCheckboc {
  width: 20px;
  height: 20px;
}

/* event Pagination */
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

/* .pagination li {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #f43254;
  color: #f43254;
  border-radius: 5px;
  cursor: pointer;
font-weight:300;
}

.pagination li.active {
  background-color: #f43254;
  color: #fff;
  border: 1px solid #f43254;
  font-weight: 600;

} */

.previousLinkClassName,
.nextLinkClassName {
  color: #f43254;
}

@media only screen and (max-width:1200px) {
  .filter-sidebar {
    position: absolute;
    z-index: 40;
    height: 100vh;
    width: 100%;
    /* padding: 0px 10px; */
    background-color: #f4f8ff ;
    border: solid 1px rgb(235, 235, 235);
    /* box-shadow: 0 4px 8px 0 rgba(131, 131, 131, 0.2); */
    overflow: auto;
  }
}

@media only screen and (min-width:1200px) {
  .filter-sidebar {
    background-color: #f4f8ff ;
    padding: 0px 6px;
    position: relative;
    z-index: 40;
    /* height: 100vh; */
    height: auto;
    /* padding: 0px 10px; */
    /* border: solid 1px rgb(235, 235, 235); */
    /* box-shadow: 0 4px 8px 0 rgba(131, 131, 131, 0.2); */
    /* overflow: auto; */
  }

  .filter-drop-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}



.filter-drp-btn {
  width: 100%;
  background-color: white;
  border: none;
  color: black;
  text-align: left;
}

/* sort */
.sortby-text {
  font-size: 13px;
}

.sort-clear-div {
  display: flex;
  align-items: center;
  background-color: #f43254;
  width: fit-content;
  text-wrap: nowrap;
  padding: 4px 10px;
  border-radius: 4px;
  /* border: 1px solid black; */
}

.sort-select-text {
  text-transform: capitalize;
  color: white;
  font-size: 12px;
  text-wrap: nowrap;
}

.sort-select-bold-text {
  color: white;
  font-size: 12px;
  text-wrap: nowrap;
  font-weight: 600;
}

.sort-clear-btn {
  background-color: #f43254;
  color: white;
  border: none;
  padding-left: 4px;
  padding: 0px;
}

@media only screen and (max-width: 768px) {
  .sort-list-div {
    /* width: 450px; */
    overflow-x: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .sort-list-div {
    width: 450px;
    overflow-x: auto;
  }
}

@media only screen and (min-width: 1200px) {
  .sort-list-div {
    width: auto;
    overflow-x: auto;
  }
}

.sort-btn {
  background-color: #fff5f7;
  padding: 6px 12px;
  border: solid 1px #f43254;
  border-radius: 6px;
  color: #f43254;
  font-weight: 500;
  text-wrap: nowrap;
  font-size: 11px;
}

.sort-btn:focus {
  background-color: #f43254;
  color: #fff5f7;
}

.sort-btn:focus {
  background-color: #f43254;
  color: #fff5f7;
}

.active-sort-btn {
  background-color: #f43254;
  color: #fff5f7;
  padding: 6px 12px;
  border: solid 1px #f43254;
  border-radius: 6px;
  font-weight: 500;
}

.filter-btn {
  background-color: white;
  width: 100%;
  border: none;
  padding: 12px 8px;
  /* background-color: #e0f5ff; */
  /* border: solid 1px #e9969e8c; */
  border-radius: 4px;

}

.filter-heading {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #333;
  text-align: start;
}

.active-filter-heading{
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #f43254;
}

.filter-content-div {
  padding: 12px 10px;
  background-color: white;
}

.filter-div{
  background-color: white;
}


@media only screen and (max-width: 1600px) {
  .clear-filter-button {
    /* margin: 12px; */
    background-color: #f43254;
    color: white;
    border: none;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    padding: 6px 12px;
  }

  .filter-heading-text {
    font-size: 14px;
    color: black;
    font-weight: 600;
  }
}

@media only screen and (min-width: 1600px) {
  .clear-filter-button {
    /* margin: 12px; */
    background-color: #f43254;
    color: white;
    border: none;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    padding: 6px 15px;
  }

  .filter-heading-text {
    font-size: 18px;
    color: black;
    font-weight: 600;
  }

  /*FilterSidebar.css */
  .event-filter-sidebar {
    height: auto;
    background-color: white;
    /* overflow: auto; */
    transition: all 0.5 ease;
  }

  .event-filter-sidebar.open {
    transform: translateX(0);
    /* height: 100vh; */
    transition: all 0.5 ease;
  }
}

.range-submit-btn {
  background-color: #f43254;
  color: white;
  border: none;
  font-weight: 500;
  border-radius: 4px;
  padding: 6px 10px;
}

.range-content {
  padding: 12px 15px;
}



@media (max-width: 1200px) {
  .event-filter-sidebar {
    position: absolute;
    width: 300px;
    display: block;
    transform: translateX(-300px);
    height: 0px;
  }

  .event-filter-sidebar.open {
    transform: translateX(0);
    z-index: 20;
    /* height: 100vh; */
  }

  .side-filterbar-button {
    color: #f43254;
    background-color: #fff5f7;
    font-weight: 600;
    border: solid 1px #f43254;
    text-wrap: nowrap;
    padding: 4px 10px;
    border-radius: 4px;
  }

  .event-in_person-div {
    position: absolute;
    z-index: 10;
    background-color: rgb(238, 98, 43);
    color: #fff;
    top: 10px;
    left: 10px;
    padding: 2px 8px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
  }
}

@media (min-width: 767px) and (max-width: 1600px) {
  .event-in_person-div {
    position: absolute;
    z-index: 10;
    background-color: rgb(238, 98, 43);
    color: #fff;
    top: 40px;
    left: 12px;
    padding: 2px 8px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 10px;
  }
}

@media (min-width: 1600px) {
  .event-in_person-div {
    position: absolute;
    z-index: 20;
    background-color: rgb(238, 98, 43);
    color: #fff;
    top: 12px;
    left: 12px;
    padding: 2px 8px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
  }
}

.past-event-head-div {
  position: absolute;
  bottom: 50px;
  left: 10px;
  color: #f43254;
  background-color: #fff5f7c9;
  padding: 3px 6px;
  z-index: 20;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
}

@media only screen and (max-width: 768px) {
  .event-online-div {
    position: absolute;
    z-index: 10;
    background-color: rgb(57, 184, 40);
    color: #fff;
    top: 10px;
    left: 10px;
    padding: 2px 8px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
  }

  .event-type-div {
    position: absolute;
    z-index: 20;
    background-color: rgb(40, 141, 184);
    color: #fff;
    bottom: 36px;
    left: 10px;
    padding: 2px 8px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
    word-wrap: break-word;
  }

  .eventDetail-type-div {
    position: absolute;
    z-index: 20;
    background-color: rgb(40, 141, 184);
    color: #fff;
    bottom: 10px;
    left: 10px;
    padding: 2px 8px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
  }

  .home-event-status-div {
    position: absolute;
    z-index: 10;
    background-color: rgb(255, 84, 32);
    color: #fff;
    top: 35px;
    left: 10px;
    padding: 2px 8px;
    border-radius: 3px;
    font-family: Poppins;
    font-size: 10px;
  }

  .event-live-status-div {
    position: absolute;
    z-index: 10;
    background-color: rgb(233, 25, 25);
    color: #fff;
    top: 35px;
    left: 10px;
    padding: 2px 6px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1600px) {
  .event-online-div {
    position: absolute;
    z-index: 10;
    background-color: rgb(57, 184, 40);
    color: #fff;
    top: 12px;
    left: 12px;
    padding: 2px 5px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
  }

  .event-type-div {
    position: absolute;
    z-index: 20;
    background-color: rgb(40, 141, 184);
    color: #fff;
    bottom: 46px;
    left: 12px;
    padding: 2px 8px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
  }

  .eventDetail-type-div {
    position: absolute;
    z-index: 10;
    background-color: rgb(40, 141, 184);
    color: #fff;
    bottom: 12px;
    left: 12px;
    padding: 2px 8px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
  }

  .home-event-status-div {
    position: absolute;
    z-index: 10;
    background-color: rgb(255, 84, 32);
    color: #fff;
    top: 12px;
    right: 12px;
    padding: 2px 5px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
  }

  .event-live-status-div {
    position: absolute;
    z-index: 20;
    background-color: rgb(233, 25, 25);
    color: #fff;
    top: 12px;
    right: 12px;
    padding: 2px 6px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
  }
}

@media only screen and (min-width: 1600px) {
  .event-online-div {
    position: absolute;
    z-index: 20;
    background-color: rgb(57, 184, 40);
    color: #fff;
    top: 12px;
    left: 12px;
    padding: 2px 8px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
  }

  .event-type-div {
    position: absolute;
    z-index: 20;
    background-color: rgb(40, 141, 184);
    color: #fff;
    bottom: 46px;
    left: 12px;
    padding: 2px 8px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
  }

  .eventDetail-type-div {
    position: absolute;
    z-index: 20;
    background-color: rgb(40, 141, 184);
    color: #fff;
    bottom: 12px;
    left: 12px;
    padding: 2px 8px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
  }

  .home-event-status-div {
    position: absolute;
    z-index: 20;
    background-color: rgb(255, 84, 32);
    color: #fff;
    top: 12px;
    right: 12px;
    padding: 2px 8px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
  }

  .event-live-status-div {
    position: absolute;
    z-index: 20;
    background-color: rgb(233, 25, 25);
    color: #fff;
    top: 12px;
    right: 12px;
    padding: 2px 8px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
  }
}

.hastag-main-div {
  padding: 6px 20px;
  height: auto;
  /* max-height: 200px;
  overflow-y: auto; */
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.btn-clear {
  font-size: 13px;
  color: rgb(66, 66, 66);
  white-space: nowrap;
  background-color: #f43254;
  margin-bottom: 0px;
  padding: 4px 6px;
  border: none;
  color: white;
  border-radius: 4px;
  font-weight: 500;
}

.filter-section-input {
  width: 100%;
  padding: 8px 10px;
  border: solid 1px rgb(172, 172, 172);
  font-size: 13px;
}

.filter-section-input:focus {
  border: solid 1px #f5ced6;
  outline: none;
}

.filter-search-input {
  padding: 8px 10px;
  border: solid 1px rgb(172, 172, 172);
  font-size: 13px;
}

.filter-search-input:focus {
  border: solid 1px rgb(129, 129, 129);
  outline: none;
}

.archived-btn {
  background-color: #ffeff2;
  color: #f43254;
  padding: 14px 20px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  border: solid 1px #f43254;
  transition: all 0.5s;
}

.archived-btn:hover {
  background-color: #ffdbe1;
}

.active-archived-btn {
  background-color: #f43254;
  color: white;
  padding: 15px 20px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
}

.active-archived-btn:hover {
  background-color: #ee254a;
  color: white;
}

.filter-clear-btn{
  background-color: transparent;
  border: none;
  font-size: 12px;
  color: rgb(102, 102, 102);
}

.filter-clear-btn:hover{
  color: rgb(211, 31, 31);
}

.active-lebel-text{
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 400;
}

.filter-date-input{
  width: 120px;
  padding: 8px 4px;
  font-size: 12px;
}

.filter-date-label{
  font-size: 13px;
  padding: 0px;
  margin: 0px;
}

.event-search-input {
  width: 100%;
  /* height: 51px; */
  padding: 15px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  transition: 0.5s;
  background-color: white;
  border-radius: 1.5rem;
}

.event-search-input:active{
  border: #f43254;
}

.event-search-input:focus{
  outline: #f43254;
  border:solid 1px #f43256bb;
}

.event-search-button {
  position: absolute;
  right: 5px;
  transition: all 0.3s ease-in-out;
  /* box-shadow: 0px 10px 20px rgba(139, 139, 139, 0.2); */
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color: #f43254;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: 500;
  border: 1px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 15px;
}

.icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}

.event-search-button:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.event-search-button:hover .icon {
  transform: translate(4px);
}

.event-search-button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.event-search-button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

.event-search-clear-button {
  position: absolute;
  right: 5px;
  transition: all 0.3s ease-in-out;
  /* box-shadow: 0px 10px 20px rgba(139, 139, 139, 0.2); */
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color: #645d5f;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: 500;
  border: 1px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 15px;
}

.event-search-clear-button:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.event-search-clear-button:hover .icon {
  transform: translate(4px);
}

.event-search-clear-button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.event-search-clear-button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}


@media (max-width: 1400px) {
  .filter-date-input {
    width: 100%; 
    max-width: 100px;
    padding: 7px 3px; 
    font-size: 11px; 
  }
}