.event-card-div {
  /* padding-top: 30px; */
  padding-bottom: 30px;
}

.your-events {
  padding-top: 30px;
  padding-bottom: 50px;
}

.your-events-heading {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}

.your-event-content {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.yourevent-content-div {
  height: 500px;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyevent-image {
  height: 200px;
}

.Looks-like-you-haven {
  margin-top: 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #888;
}

.select-myevent-tab {
  background-color: #f43254;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  color: white !important;
  padding: 10px 16px;
  border-radius: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  border-radius: 8px;
}

.myevent-tab {
  background-color: rgb(255, 255, 255);
  /* background-color: white; */
  border: none;
  font-family: Poppins;
  color: rgb(219, 53, 53) !important;
  font-size: 14px;
  padding: 10px 16px;
  border-radius: 8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  color: #b6b6b6;
}

.myevent-tab:hover {
  background-color: rgb(247, 231, 231);
}

.select-myevent-filter-tab {
  background-color: white;
  border: none;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  color: #333;
}

.myevent-filter-tab {
  background-color: white;
  border: none;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  color: #b6b6b6;
}

.tab-bottom-line {
  width: 45px;
  height: 6px;
  margin: 8px 0px 0 0px;
  background-color: #f43254;
}

/* My event  */




@media only screen and (max-width: 572px) {
  .myevent-card-img {
    position: relative;
    width: 100%;
    height: 240px;
    border-radius: 8px;
    object-fit: cover;
  }

  .myevent-past-img {
    filter: grayscale(100%);
    position: relative;
    width: 100%;
    height: 240px;
    border-radius: 8px;
    object-fit: cover;
  }

  .Create-new-Event {
    /* margin-top: 20px; */
    display: flex;
    justify-content: center;
    gap: 4px;
    padding: 14px 14px;
    border-radius: 8px;
    background-color: #f43254;
    color: white;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
  }

  .Create-new-Event:hover {
    color: white;
    background-color: #f43254;
  }
}

@media only screen and (min-width: 572px) {
  .myevent-card-img {
    position: relative;
    width: 284px;
    height: 300px;
    border-radius: 8px;
    object-fit: cover;
  }

  .myevent-past-img {
    filter: grayscale(100%);
    position: relative;
    width: 100%;
    height: 300px;
    border-radius: 8px;
    object-fit: cover;
  }

  .Create-new-Event {
    /* margin-top: 20px; */
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 14px 20px;
    border-radius: 8px;
    background-color: #f43254;
    color: white;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
  }

  .Create-new-Event:hover {
    color: white;
    background-color: #f43254;
  }
}



.myevent-rectangle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  width: 100%;
  /* width: 75%; */
  height: auto;
  padding: 6px 4px 6px 4px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #f43254;
  color: white;
}

.list-head-div {
  width: 100%;
  padding: 12px 30px 12px 30px;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
  background-color: #f43254;
}

.edit-event-btn {
  background-color: white;
  color: black;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f43254;
}

@media (max-width: 720px) {
  .event-status-div {
    position: absolute;
    z-index: 10;
    left: 10px;
    top: 35px;
    width: auto;
    height: auto;
    padding: 2px 6px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }
}


@media (min-width: 720px) and (max-width: 1600px) {
  .event-status-div {
    position: absolute;
    z-index: 10;
    left: 12px;
    top: 12px;
    width: auto;
    height: auto;
    padding: 2px 6px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }
}

@media (min-width: 1600px) {
  .event-status-div {
    position: absolute;
    z-index: 10;
    right: 12px;
    top: 12px;
    width: auto;
    height: auto;
    padding: 2px 6px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }
}



.event-status-approved {
  background-color: rgb(0, 76, 121);
  color: #ffffff;
}

.event-status-pastevent {
  background-color: rgb(159, 128, 173);
  color: #ffffff;
}

.event-status-published {
  background-color: rgb(11, 133, 0);
  color: #ffffff;
}

.event-status-pending {
  background-color: #4dcfe6;
  color: #fcfafa;
}

.event-status-underreview {
  background-color: #8a6e00;
  color: #ffffff;
}

.event-status-rejected {
  background-color: #f32525;
  color: #fcfcfc;
}

.event-status-archived {
  background-color: #2249b4;
  color: #fcfcfc;
}

.no-data-msg {
  padding: 30px 0px;
  text-align: center;
  font-size: 20px;
  color: rgb(82, 82, 82);
  font-weight: 600;
}

/* Pagination.css */

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  display: inline-block;
  margin: 0 5px;
}

.pagination a {
  color: #f43254 !important;
  text-decoration: none;
  padding: 6px 12px;
  border: 1px solid rgba(244, 50, 84, 0.12);
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-weight: 400;
}

.pagination a:hover {
  background-color: #fff5f7 !important;
  color: #f43254 !important;
}

.pagination .selected a {
  background-color: #f43254 !important;
  color: #fff !important;
}

.pagination .disabled a {
  pointer-events: none;
  background-color: #eee !important;
  color: #aaa !important;
  border: 1px solid #aaa;
}

/* ImageWithEditButton.css */
.delete-button {
  position: absolute;
  z-index: 30;
  top: 25px;
  left: 80px;
  transform: translate(-50%, -50%);
  background-color: #f43256;
  border-radius: 4px;
  color: #fff;
  border: none;
  padding: 4px 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  /* display: none; */
}

.delete-button:hover {
  background-color: #d12444;
  color: #fff;
}

.edit-button {
  position: absolute;
  z-index: 30;
  top: 25px;
  left: 30px;
  transform: translate(-50%, -50%);
  background-color: #f43256;
  border-radius: 4px;
  color: #fff;
  border: none;
  padding: 4px 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  /* display: none; */
}

.edit-button:hover {
  background-color: #d12444;
  color: #fff;
}

.past-delete-button {
  position: absolute;
  cursor: default;
  z-index: 30;
  top: 25px;
  left: 80px;
  transform: translate(-50%, -50%);
  background-color: rgb(167, 167, 167);
  border-radius: 4px;
  color: #272525;
  border: none;
  padding: 4px 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  /* display: none; */
}

.past-edit-button {
  position: absolute;
  cursor: default;
  z-index: 30;
  top: 25px;
  left: 30px;
  transform: translate(-50%, -50%);
  background-color: rgb(167, 167, 167);
  border-radius: 4px;
  color: #272525;
  border: none;
  padding: 4px 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  /* display: none; */
}


.image-container:hover .edit-button {
  display: block;
}

.user-title-text {
  color: #272525;
}

/* .publishevent-mdl{
  width: 50%;
  height: auto;
  padding: 20px;
  background-color: white !important;
  border-radius: 8px;
} */

.publishevent-content {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}


.myevent-unpublish-btn {
  position: absolute;
  left: 8px;
  bottom: 45px;
  font-size: 12px;
  z-index: 30;
  background-color: #f43256;
  color: white;
  font-weight: 500;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
}

.myevent-unpublish-btn:hover {
  background-color: #d11a3c;
}

.myevent-markas-btn {
  position: absolute;
  right: 8px;
  bottom: 45px;
  font-size: 12px;
  z-index: 30;
  background-color: #f43256;
  color: white;
  font-weight: 500;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
}

.myevent-markas-btn:hover {
  background-color: #d11a3c;
}

.myevent-markas-disable-btn {
  position: absolute;
  right: 8px;
  bottom: 45px;
  font-size: 12px;
  z-index: 30;
  background-color: #696969;
  color: white;
  font-weight: 500;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
}

.myevent-markas-disable-btn:hover {
  background-color: #585858;
}

.myevent-Modify-btn {
  position: absolute;
  left: 100px;
  bottom: 45px;
  font-size: 12px;
  z-index: 30;
  background-color: #f43256;
  color: white;
  font-weight: 500;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
}

.myevent-Modify-btn:hover {
  background-color: #d11a3c;
}

.sponsored-btn {
  background-color: #ffeaee;
  color: #f43256;
  border: none;
  font-size: 13px;
  padding: 6px 10px;
  margin: 0px;
  transition: all 0.4s;
}

.sponsored-btn:hover {
  background-color: #fadfe4;
}

.sponsored-btn:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.sponsored-btn:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.active-sponsored-btn {
  background-color: #f43256;
  color: #fff5f7;
  border: none;
  font-size: 13px;
  padding: 6px 10px;
  margin: 0px;
}

.active-sponsored-btn:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.active-sponsored-btn:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.myevent-side-filter-button{
  position: absolute;
  z-index: 90;
  width: fit-content;
  border: none;
  background-color: #ffeef2;
  color: #f43254;
  padding: 4px 16px;
  border-radius: 4px;
  font-weight: 500;
  left: 0px;
  border: solid 1px #f43254; 
}


.side-filter-button {
  position: absolute;
  z-index: 90;
  width: fit-content;
  border: none;
  background-color: #ffeef2;
  color: #f43254;
  padding: 4px 16px;
  border-radius: 4px;
  font-weight: 500;
  left: 0px;
  border: solid 1px #f43254;
}

.active-side-filter-button {
  position: absolute;
  z-index: 90;
  width: fit-content;
  border: none;
  background-color: #f43254;
  color: white;
  padding: 4px 16px;
  border-radius: 4px;
  font-weight: 500;
  left: 0px;
  border: solid 1px #f43254;
}

.markasForm-mdl {
  width: 750px;
  height: auto;
  padding: 20px;
  background-color: white !important;
  border-radius: 8px;
}

.sponsor-content-div {
  border: solid 1px rgb(185, 185, 185);
  border-radius: 3px;
}

.tooltip_style {
  position: relative;
  z-index: 1000;
  width: 400px !important;
  border-radius: 10px !important;
  background-color: #f43256 !important;
  /* Ensuring solid white background */
  color: #ffffff !important;
  border: solid 1px rgb(219, 219, 219);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  /* Adding shadow for elevation */
}

.markus-upload-docs {
  cursor: pointer;
  height: 100px;
}

.markas-pdf-div {
  width: 110px;
  height: 140px;
  padding: 25px 25px;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e9e9e9;
  background-color: #fff;
}

.markas-pdf-img {
  height: 65px;
}

.marksas-card {
  width: 100%;
  padding: 16px 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #e9e9e9;
  background-color: #fff;
  margin-top: 10px;
  overflow: hidden;
  max-height: 60px;
  transition: max-height 0.5s ease-in-out;
  border-radius: 8px;
}

.marksas-card.open {
  max-height: 475px;
  overflow-y: auto;
  /* Set a value based on your content height */
}

.markus-mdl-head {
  font-size: 16px;
  font-weight: 700;
}

/* calendar */
.date-price-calendar {
  font-family: Arial, sans-serif;
}

.react-calendar {
  border: none;
  font-size: 14px;
}

.react-calendar__tile {
  height: 50px;
  text-align: center;
}

.tile-content {
  margin-top: 10px;
  font-size: 12px;
  color: #555;
}

.price {
  display: block;
  color: #007bff;
  font-weight: bold;
}

.react-calendar__tile--active {
  background: #005db9;
  color: white;
  border-radius: 6px;
}

/* .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
  border-radius: 6px;
} */

.selected-date {
  background-color: #f43254;
  color: white;
  border-radius: 6px;
}

.selected-date-info {
  margin-top: 20px;
  text-align: center;
}

.calendar-amount-black {
  color: black;
}

.calendar-amount-green {
  color: green;
}

.date-price-calendar {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 18px;
}

/* calendar 2 */

.react-datepicker {
  width: 100%;
  font-family: 'Arial', sans-serif;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Calendar header */
.react-datepicker__header {
  background-color: #f43254;
  color: white;
  padding-top: 10px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.944rem;
}

/* Calendar navigation buttons */
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  top: 15px;
  width: 0;
  height: 0;
  border-style: solid;
}

.react-datepicker__navigation--previous {
  border-width: 7px 10px 7px 0;
  border-color: transparent white transparent transparent;
  left: 10px;
}

.react-datepicker__navigation--next {
  border-width: 7px 0 7px 10px;
  border-color: transparent transparent transparent white;
  right: 10px;
}

/* Weekday names */
.react-datepicker__day-names {
  display: flex;
  justify-content: space-around;
  background-color: #f43254;
  padding: 10px 0;
  border-bottom: 1px solid #f43254;
}

.react-datepicker__day-name {
  color: white;
  font-weight: bold;
}

@media only screen and (max-width: 576px){
  .react-datepicker__month-container{
    width: 300px;
    overflow-x: auto;
  }
}


/* Day cells */
.react-datepicker__day {
  width: 50px;
  margin: 5px;
  padding: 5px;
  /* border-radius: 50%; */
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

/* Today’s date */
/* .react-datepicker__day--today {
  font-weight: bold;
  border: 1px solid #f43254;
  border-radius: 6px;
  background-color: #ffffff !important;
  color: #333 !important;
} */

/* Selected date */
/* .react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #f43254;
  color: white;
} */

/* Hover effect on days */
.react-datepicker__day:hover {
  background-color: #ffe1e7;
  color: black;
}

/* Disabled days */
.react-datepicker__day--disabled {
  color: #ccc;
  cursor: not-allowed;
}

/* Month dropdown */
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background-color: #f43254;
  color: white;
}

/* Selected month/year */
.react-datepicker__month-dropdown .react-datepicker__month-option--selected,
.react-datepicker__year-dropdown .react-datepicker__year-option--selected {
  background-color: #f43254;
}

/* Hover effect on month/year dropdown options */
.react-datepicker__month-dropdown .react-datepicker__month-option:hover,
.react-datepicker__year-dropdown .react-datepicker__year-option:hover {
  background-color: #f43254;
}

/* .CreateEventExpressAndAdvancedMdl{
  width: 50%;
  height: auto;
  padding: 20px 20px 30px 20px;
  background-color: white !important;
  border-radius: 8px;
}

.CreateEventExpressAndAdvanced-p{
  font-size: 13px;
  color:rgb(58, 58, 58);
}

.CreateEventExpressAndAdvanced-content{
  height: auto;
}

.CreateEventExpressAndAdvanced-p-div{
  height:380px;
  max-height:380px;
  padding-right: 4px;
  overflow-y: auto;
}

.CreateEventExpressAndAdvanced-btn{
  position:absolute;
  bottom: 10px;
  background-color: #f43254;
  border: none;
  color: white;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 4px;
  transition: all 0.3s;
}

.CreateEventExpressAndAdvanced-btn:hover{
  background-color:#c90e30;
  transition: all 0.3s;
}

.ExpressAndAdvanced-head{
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.ExpressAndAdvanced-div{
  border: solid 1px rgba(156, 156, 156, 0.438);
  padding: 10px 15px 60px 15px;
  border-radius: 10px;
}

.express-icon{
  position: absolute;
  top: 40px;
  left: 12px;
  z-index: 20;
  height: 35px;
} */

/* Express event csss startt---------------------------------------------------------------------------------- */

.CreateEventExpressAndAdvancedMdl {
  width: 50%;
  height: auto;
  padding: 20px;
  background-color: white !important;
  border-radius: 8px;
  max-width: 100%;
  margin: auto;
}

.CreateEventExpressAndAdvanced-p {
  font-size: 13px;
  color: rgb(58, 58, 58);
}

.CreateEventExpressAndAdvanced-content {
  height: auto;
}

.CreateEventExpressAndAdvanced-p-div {
  height: 380px;
  max-height: 380px;
  padding-right: 4px;
  overflow-y: auto;
}

.CreateEventExpressAndAdvanced-btn {
  position: absolute;
  bottom: 10px;
  background-color: #f43254;
  border: none;
  color: white;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 4px;
  transition: all 0.3s;
}

.CreateEventExpressAndAdvanced-btn:hover {
  background-color: #c90e30;
  transition: all 0.3s;
}

.ExpressAndAdvanced-head {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.ExpressAndAdvanced-div {
  border: solid 1px rgba(156, 156, 156, 0.438);
  padding: 10px 15px 60px 15px;
  border-radius: 10px;
}

.express-icon {
  position: absolute;
  top: 40px;
  left: 12px;
  z-index:10;
  height: 35px;
}

/* Responsive design */
@media (max-width: 768px) {
  .CreateEventExpressAndAdvancedMdl {
    /* width: 90%;
    padding: 15px;
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden; */
    width: 90%;
    padding: 15px;
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }

  .CreateEventExpressAndAdvanced-p {
    font-size: 12px;
  }

  .CreateEventExpressAndAdvanced-p-div {
    height: auto;
    max-height: 300px;
  }

  .CreateEventExpressAndAdvanced-btn {
    padding: 8px 16px;
  }
}

@media (max-width: 576px) {
  .CreateEventExpressAndAdvancedMdl {
    width: 100%;
    padding: 10px 15px;
  }

  .ExpressAndAdvanced-head {
    font-size: 18px;
  }

  .CreateEventExpressAndAdvanced-btn {
    padding: 6px 12px;
  }
}

/* Express event csss endddddd-------------------------------------------------------------------------------------------- */



.publishevent-mdl {
  width: 100%;
  /* Default for mobile */
  height: auto;
  padding: 20px;
  background-color: white !important;
  border-radius: 8px;
}

/* For larger mobile devices or tablets in portrait mode */
@media only screen and (min-width: 600px) {
  .publishevent-mdl {
    width: 80%;
    /* Slightly larger width */
  }
}

/* For tablets in landscape mode and small laptops */
@media only screen and (min-width: 768px) {
  .publishevent-mdl {
    width: 70%;
    /* Increase width for tablets and small laptops */
  }
}

/* For laptops and desktops */
@media only screen and (min-width: 1024px) {
  .publishevent-mdl {
    width: 60%;
    /* More space on larger screens */
  }
}

/* For larger desktops or widescreen monitors */
@media only screen and (min-width: 1440px) {
  .publishevent-mdl {
    width: 50%;
    /* Maintain a moderate width for big screens */
  }
}

.interest-close-btn{
  display: flex;
  align-items: center; 
  background-color: transparent;
  border: none;
  color: white;
  padding: 0px;
  margin: 0px;
  transition: all 0.5s;
}

.interest-close-btn:hover{
  color: rgb(253, 231, 231);
}