.Message {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f3d56;

}

.Date-Time {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f3d56;
}

.tradeShow {
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #333;
    display: flex;
    align-items: center;
}

.tradeDate-time {
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #333;
}

@media (min-width: 200px) and (max-width: 992px) {
    .msg-datetime-value {
        height: auto;
        margin: 20px 0px 10px;
        border-radius: 5px;
        box-shadow: 1px 2px 5px 1px rgba(244, 50, 84, 0.09);
        display: flex;
        flex-direction: column;
        gap: 10px;
        cursor: pointer;
        padding: 6px 15px;
    }

    .msg-datetime-value-ac {
        height: auto;
        margin: 20px 0px 10px;
        padding: 0px 10px 0px 10px;
        border-radius: 5px;
        box-shadow: 1px 2px 5px 1px rgba(244, 50, 84, 0.09);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .msg-date-time-bar {
        height: 50px;
        padding: 16px 16px 15px 20px;
        border-radius: 4px;
        box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
        background-color: #fff;
        display: flex;
        justify-content: space-between;
    }

    .msg-date-time-bar-ac {
        height: 50px;
        padding: 16px 16px 15px 20px;
        border-radius: 4px;
        box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
        background-color: #fff;
        display: flex;
        justify-content: space-between;
    }

    .msg-datetime-header {
        height: 600px;
        overflow-y: scroll;
    }

    .all-notification-head {
        font-family: Poppins;
        font-size: 25px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333;
    }
}

@media only screen and (min-width: 992px) {
    .msg-datetime-value {
        height: 65px;
        margin: 20px 0px 10px;
        padding: 0px 10px 0px 10px;
        border-radius: 5px;
        box-shadow: 1px 2px 5px 1px rgba(244, 50, 84, 0.09);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .msg-datetime-value-ac {
        height: auto;
        margin: 20px 0px 10px;
        padding: 0px 10px 0px 10px;
        border-radius: 5px;
        box-shadow: 1px 2px 5px 1px rgba(244, 50, 84, 0.09);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .msg-date-time-bar {
        height: 50px;
        padding: 0px 74px 0px 18px;
        border-radius: 4px;
        box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .msg-date-time-bar-ac {
        height: 50px;
        border-radius: 4px;
        padding: 0px 10px 0px 10px;
        box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .msg-datetime-header {
        height: 600px;
        overflow-y: scroll;
    }

    .all-notification-head {
        font-family: Poppins;
        font-size: 30px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333;
    }
}