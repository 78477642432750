.custom-bullet {
  background-color: #ccc;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin: 0 5px;
  opacity: 0.5;
  transition: opacity 0.3s, background-color 0.3s;
}

.custom-bullet-active {
  background-color: #f43254;
  opacity: 1;
}

.swiper-pagination {
  text-align: center;
  margin-top: 10px;
}


/* loader logo css main loader start-----------------*/
.loaderr-containerr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(95, 92, 92, 0.8);
  z-index: 9999;
}

.loaderr-contentt {
  display: flex;    
  flex-direction: column;
  align-items: center;
}

.loaderr-logoo {
  width: 48px;
  /* Adjust size as needed */
  height: 35px;
  margin-bottom: 20px;
  animation: spin 1s ease-in-out infinite
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}
/* loader logo css main loader end-----------------*/