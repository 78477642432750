.profile-title {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333
}

.userprofile-img {
    border-radius: 50%;
    height: 200px;
    object-fit: cover;
    width: 200px;
}

.upload-img-icon {
    position: absolute;
    bottom: 0;
    right: 0px;
    cursor: pointer;
}

.profile-name-title {
    font-size: 22px;
    font-weight: 500;
    font-style: normal;
    color: #333;
}

.profile-name-title span {
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #818181;
}

.profile-editbtn {
    background-color: white;
    border: none;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #f43254;
}

.profile-mail-text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #333333;
}

.userinfo-head-text {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #818181;
}

.userinfo-text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #333;
    /* text-overflow: ellipsis; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.userinfo-twoline-text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #333;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* My Interests */
.Interests-title {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
}

.content-button-div {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.hashtag-div {
    padding: 5px 16px;
    border-radius: 4px;
    border: solid 1px rgba(244, 50, 84, 0.12);
    background-color: #fff5f7;
}

.active-hashtag-div {
    padding: 5px 16px;
    border-radius: 4px;
    border: solid 1px rgba(244, 50, 84, 0.12);
    background-color: #f43254;
}



.hashtag-text {
    font-size: 13px;
    font-weight: 600;
    width: auto;
    white-space: nowrap;
    text-align: left;
    color: #f43254;
}

.active-hashtag-text {
    font-size: 13px;
    font-weight: 600;
    width: auto;
    white-space: nowrap;
    text-align: left;
    /*font-style: normal; */
    color: #fff5f7;
}

.intrest-modal-content {
    width: 100%;
    height: 500px;
    padding: 20px 10px;
}



.interest-modal-dialog {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 700px;
}

.interest-modal-content {
    padding: 20px;
    width: 100%;
    height: auto;
}

.modal-data {
    width: 100%;
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-close-btn {
    position: absolute;
    right: 4px;
    border: none;
    background-color: transparent;

}

/* Statistic -------------------------------------- */

.statistic-line-div {
    height: 1px;
    background-color: white;
    /* border:solid 1px white; */
    width: 35%;
}

.statistic-heading {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.statistic-content-heading {
    font-family: Poppins;
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 3.04px;
    text-align: center;
}

.head-color-1 {
    color: #f43254;
}

.head-color-2 {
    color: #06acee;
}

.head-color-3 {
    color: #efa506;
}

.head-color-4 {
    color: #ef4306;
}

.head-color-5 {
    color: #8dc63f;
}


.statistic-content-p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

/* statics end ------------------------------ */



@media only screen and (max-width: 992px) {
    .s-main-div {
        height: 500px;
    }

    .Statistic-img-div {
        padding: 30px 20px;
        width: 100%;
        border-radius: 8px;
        height: 500px;
        background-image: url("../images/bitmap.png");
        background-size: 500px;
        /* object-fit: contain; */
        background-repeat: no-repeat;
    }

    .userprofile-img {
        border-radius: 50%;
        height: auto;
        object-fit: cover;
    }
}

@media only screen and (min-width: 992px) {
    .s-main-div {
        height: 700px;
    }

    .Statistic-img-div {
        padding: 30px 20px;
        width: 100%;
        border-radius: 8px;
        height: 700px;
        background-image: url("../images/bitmap.png");
        background-size: 700px;
        /* object-fit: contain; */
        background-repeat: no-repeat;
    }
}


.verify-otp {
    position: absolute;
    right: 4px;
    border: none;
    background-color: #f43254;
    color: white;
    padding: 4px 8px;
}

.edit-email-btn {
    border: none;
    background-color: initial;
}

.edit-email-heading {
    font-size: 20px;
    font-weight: 600;
}

.mdl-cancel-btn {
    border: none;
    background-color: initial;
    font-weight: 500;
    font-size: 14px;
}


.SocialMediaProfileHead {
    text-align: center
}

.socialMediaCancelbtn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end
}

.lang-div {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
    max-height: 45px;
}

.lang-text {}

.referral-code-text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #cc0025;
}

.avatar-select-btn {
    border: none;
    background-color: transparent;
}

.profile-shield-btn {
    position: absolute;
    top: 120px;
    /* right: 0px; */
    left: 170px;
    padding: 8px;
    border: none;
    background-color: #f71941;
    border-radius: 20px;
}

@media (max-width: 768px) {
    .profile-shield-btn {
        position: absolute;
        top: 120px;
        /* right: 300px; */
        padding: 6px;
        border: none;
        background-color: #f71941;
        border-radius: 20px;
    }
}

.shield-section {
    box-shadow: rgba(67, 67, 107, 0.171) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: white;
    border-radius: 4px;
    padding: 15px 20px;
    margin-top: 30px;
}

.shield-heading {
    /* color: rgb(68, 68, 68); */
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
}

.profile-avatar-div {
    /* position: sticky; */
    top: 60px;
    /* height: 500px; */
}

.aadhar-example-img {
    height: auto;
    max-height: 300px;
    width: 100%;
}

.aboutme-select-input {
    padding: 6px 10px;
    border: solid 1px rgb(184, 184, 184);
    border-radius: 4px;
    font-size: 14px;
    outline: none;
}